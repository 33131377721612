import useQuery from "hooks/useQuery";
import _ from "lodash";

export const useGetType = () => {
  return useQuery({
    url: `/category/COMMON/TYPE_OF_DIMENSION/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetContainerTypeDropDown = ({ dataOwner, type }) => {
  return useQuery({
    url:
      type === "R_PACKAGE"
        ? `/volumn-dimension/r-package/container-type/${dataOwner}`
        : `/volumn-dimension/container-type/${dataOwner}/${type}`,
    method: "GET",
    key: [type],
    enabled: !_.isEmpty(type),
    select: data => data.result,
  });
};

export const useGetImpCdDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/volumn-dimension/importer/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgTypeDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/volumn-dimension/r-package-type/${dataOwner}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetImpCdTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/volumn-dimension/importer/plant/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgCategoryTableDropDown = () => {
  return useQuery({
    url: `/category/TMATH/R_PKG_CATEGORY/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner, rPkgOwnerCompAbbr, categoryCd }) => {
  return useQuery({
    url: `/volumn-dimension/r-package-type/${dataOwner}/${rPkgOwnerCompAbbr}/${categoryCd}`,
    method: "GET",
    enabled: categoryCd !== "",
    // key: [categoryCd],
    select: data => data.result,
  });
};

export const useGetparentRpkgTypeTableDropDown = ({ dataOwner, parentRPkgTypeTable }) => {
  return useQuery({
    url: `/volumn-dimension/r-package-type/ignore/${dataOwner}/${dataOwner}/${parentRPkgTypeTable}`,
    method: "GET",
    key: [parentRPkgTypeTable],
    enabled: !_.isEmpty(parentRPkgTypeTable),
    select: data => data.result,
  });
};
