import { userProfile } from "constant";

const { API_REPROCESS } = require("shared/constants/api-name/common/reprocess");

export const DEFAULT_REPROCESS_DOWNLOAD = {
  [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner,
  [API_REPROCESS.BUSINESS_NAME]: "",
  [API_REPROCESS.PLANT]: "",
  [API_REPROCESS.BATCH_ROUND]: "",
  [API_REPROCESS.FILE]: {},
};

export const DEFAULT_REPROCESS_UPLOAD = {
  [API_REPROCESS.DATA_OWNER]: userProfile.dataOwner,
  [API_REPROCESS.BUSINESS_NAME]: "",
  [API_REPROCESS.BATCH_ROUND]: "",
  [API_REPROCESS.FILE]: {},
};
