import React, { Fragment, useEffect, useMemo, useState } from "react";
// Components
import { Grid, Box } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputButton, PopupDialog } from "components/UI";
import ButtonCriterial from "./components/ButtonCriterial";
import GraphSection from "./components/GraphSection";
import TableSection from "./components/TableSection";
import ButtonGraph from "./components/ButtonGraph";
import CriterialSection from "./components/CriterialSection";
//UTIL
import { isEmpty } from "lodash";
import { findObject, responseErrors, responseSuccessArray } from "utils";
import { validationSearchForm } from "utils/validation";
// Hook
import sleep from "shared/hooks/Sleep";
import useColWarehouseAllocate from "./hooks/useColWarehouseAllocate";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import useButtonMode from "./hooks/useButtonMode";
//Service
import {
  useWareHouseSummaryGetRPkgOwnerQuery,
  useWareHouseSummaryGetRPkgTypeQuery,
  useWareHouseSummaryGetWareHouseQuery,
  useWareHouseSummarySearchTableMutation,
  useWareHouseSummarySearchGraphMutation,
  useWarehouseConfirmAllocateMutation,
} from "shared/services/inventory";
//Type
import { MessageType, ModeAction } from "state/enum";

//!mock
import { formatRowUpdated, formatRowWareHouseSummary } from "./hooks/format";
import { DEFAULT_WAREHOUSE_SUMMARY } from "./constants/constant";
import useColumnTanStack from "./hooks/useColumnTanStack";

export default function RPackageWarehouseAllocationSummaryScreen() {
  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();
  // Form
  const [form, setForm] = useState(DEFAULT_WAREHOUSE_SUMMARY);
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState("Initial");
  const [onSimulate, setOnSimulate] = useState(false);
  //Button State
  const [viewBtn, setViewBtn] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [simulateBtn, setSimulateBtn] = useState(false);
  const [cancelBtn, setCancelBtn] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);

  // Table
  const [rows, setRows] = useState([]);
  const [rPkg, setRPkg] = useState([]);
  const [graphData, setGraph] = useState([]);
  const [defaultGraphData, setDefaultGraph] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const getWarehouse = wareHouseId => {
    if (isEmpty(wareHouseId)) return "TMATH:ALL";
    const warehouse = wareHouseList?.find(ele => ele.plantId === wareHouseId);
    return `${warehouse.companyAbbr}:${warehouse.impExpCd}`;
  };

  const [openGraph, setOpenGraph] = useState(false);
  // API
  const { data: rPackageOwnerList } = useWareHouseSummaryGetRPkgOwnerQuery();
  const { data: wareHouseList } = useWareHouseSummaryGetWareHouseQuery(form?.dataOwner);
  const { data: rPackageTypeList } = useWareHouseSummaryGetRPkgTypeQuery({
    dataOwner: form?.dataOwner,
    warehouse: !form?.wareHouseId ? "" : getWarehouse(form?.wareHouseId),
  });
  const searchTable = useWareHouseSummarySearchTableMutation();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const confirmAllocate = useWarehouseConfirmAllocateMutation();
  const getGraph = async () => {
    try {
      const body = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        location: getWarehouse(form?.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      const response = await searchGraph(body);
      // if (!response?.result?.length) {
      //   setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
      //   return false;
      // }
      return response;
    } catch (error) {
      if (error?.statusCode === 500) {
        return setMsgError([error?.message]);
      }
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return false;
    }
  };
  const getSearch = async body => {
    try {
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchTable(body);
      const { data, rPkg } = formatRowWareHouseSummary(searchData?.result);
      // await sleep(2000); // waiting for 2 second
      const searchGraph = await getGraph(body);
      stopLoading();
      return { searchData, data, rPkg, searchGraph };
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleAllocated = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const allocateConfirm = await confirmDialogCtx.success({ type: "INVENTORY_CONFIRM_ALLOCATED" });
      if (!allocateConfirm) {
        return;
      }
      // let tempRowModesModel = rowModesModel;

      // const rowSelect = rows.map(val => {
      //   tempRowModesModel = {
      //     ...tempRowModesModel,
      //     [val?.rowNumber]: { mode: GridRowModes.View },
      //   };
      // });

      // rowSelect && setRowModesModel(tempRowModesModel);

      // TODO validate forecastDt if don't have data show error and which have error
      const updatedRow = formatRowUpdated(rowSelectionModel, getWarehouse);
      const updateData = {
        dataOwner: form?.dataOwner,
        rPackageOwner: form?.rPackageOwnerId,
        rPackageType: form?.rPackageTypeId,
        wareHouseId: form?.wareHouseId,
        rows: updatedRow,
      };
      const response = await confirmAllocate(updateData);
      console.log(response);
      const { error, info } = responseSuccessArray(response);
      if (!info.length) {
        setMsgAlert(info);
      }
      if (!error.length) {
        setMsgError(error);
      }
      window.scrollTo(0, 0);
      setMode(ModeAction.VIEW);
      // Search after updated
      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        warehouse: getWarehouse(form?.wareHouseId),
      };
      const { data, rPkg } = await getSearch(bodySearch);
      setRows(data);
      setRPkg(rPkg);
      // setGraph(searchRes?.result?.graphData);
      setOnSearch(true);
      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  const handleCellChange = (newValue, params) => {
    let tempRowSelectModel = rowSelectionModel;
    const row = params.row;
    const field = params.field;
    if (!newValue && field === "plantId") {
      params.api.setEditCellValue({ id: row.rowNumber, field: "allocateDt", value: "" });
      setRowSelectionModel(prev => [...prev, row?.rowNumber]);
      return;
    }
    // add row to array
    let isUpdateRow = false;

    tempRowSelectModel = rowSelectionModel?.map((item, index) => {
      if (item?.rowNumber === row?.rowNumber) {
        isUpdateRow = true;
        return row?.rowNumber;
      }
      return item;
    });

    if (isUpdateRow) {
      setRowSelectionModel(tempRowSelectModel);
    }
    setRowSelectionModel(prev => [...prev, row?.rowNumber]);
    return true;
  };
  const columns = useMemo(() => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useColumnTanStack({ rPkg, wareHouseList, mode, rowSelectionModel });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rPkg, wareHouseList, mode]);

  // const columns = useMemo(() => {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   return useColWarehouseAllocate({
  //     rPkg,
  //     wareHouseList,
  //     mode,
  //     handleCellChange,
  //     rowSelectionModel,
  //     setRowSelectionModel,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rPkg, wareHouseList, mode]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      stateButton: { setSearchBtn, setClearBtn, setEditBtn, setSimulateBtn, setCancelBtn, setConfirmBtn, setViewBtn },
    });
  }, [mode]);
  // API
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN93051 :R-Package Warehouse Allocation Summary Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <CriterialSection
          mode={mode}
          form={form}
          setForm={setForm}
          setMsgError={setMsgError}
          dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
        />
        <ButtonCriterial
          form={form}
          setForm={setForm}
          rows={rows}
          setRows={setRows}
          rPkg={rPkg}
          setRPkg={setRPkg}
          graphData={graphData}
          setGraph={setGraph}
          setOpenGraph={setOpenGraph}
          setDefaultGraph={setDefaultGraph}
          mode={mode}
          setMode={setMode}
          onSearch={onSearch}
          setOnSearch={setOnSearch}
          getWarehouse={getWarehouse}
          getGraph={getGraph}
          setOnSimulate={setOnSimulate}
          stateBtn={{ searchBtn, clearBtn, editBtn, viewBtn }}
          loading={{ startLoading, stopLoading }}
          // pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
          setMsg={{ setMsgAlert, setMsgError }}
          rowSelection={{ setRowModesModel, setRowSelectionModel }}
        />
      </Box>

      {onSearch && (
        <Box sx={{ padding: "0 1rem 0 2rem" }}>
          <Grid container spacing={2}>
            {/* <Grid
              item
              xs={12}
              sx={{
                borderRadius: 2,
                border: "1px solid #C4C4C4",
              }}>
              <GraphSection setMsg={{ setMsgAlert, setMsgError }} graphData={graphData} />
            </Grid> */}
            <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end", justifyContent: "right" }}>
              <ButtonGraph
                form={form}
                getSearch={getSearch}
                rPkg={rPkg}
                graphData={graphData}
                setGraph={setGraph}
                defaultGraphData={defaultGraphData}
                setOpenGraph={setOpenGraph}
                rows={rows}
                setRows={setRows}
                setMode={setMode}
                setRPkg={setRPkg}
                setOnSearch={setOnSearch}
                setOnSimulate={setOnSimulate}
                getWarehouse={getWarehouse}
                stateBtn={{ simulateBtn, cancelBtn }}
                setMsg={{ setMsgAlert, setMsgError }}
                dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
                // pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
                rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
              />
            </Grid>
            <Grid item xs={12}>
              <TableSection
                mode={mode}
                setMode={setMode}
                rows={rows}
                rPkg={rPkg}
                // graphData={graphData}
                setGraph={setGraph}
                defaultGraphData={defaultGraphData}
                onSimulate={onSimulate}
                setOnSimulate={setOnSimulate}
                columns={columns}
                setMsg={{ setMsgAlert, setMsgError, msgError }}
                dataList={{ rPackageOwnerList, rPackageTypeList, wareHouseList }}
                rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end", justifyContent: "right" }}>
              <InputButton
                id="button-allocate"
                value="Confirm Allocation"
                disabled={confirmBtn}
                onClick={handleAllocated}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {/* Popup Graph */}
      <PopupDialog
        id="container"
        maxWidth="lg"
        onFooter={true}
        onOpen={openGraph}
        content={
          <GraphSection setOpenGraph={setOpenGraph} setMsg={{ setMsgAlert, setMsgError }} graphData={graphData} />
        }
      />
    </Fragment>
  );
}
