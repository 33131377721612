import { userProfile } from "constant";
import { API_EXCEL } from "shared/constants";
const DEFAULT_EXCEL_SCREEN = {
  [API_EXCEL.TABLE_NAME]: "",
  [API_EXCEL.BOOK_MARKS]: "",
  [API_EXCEL.SAVE_BOOK_MARK]: false,
  [API_EXCEL.REPORT_NAME]: "",
  [API_EXCEL.REPORT_TITLE]: "",
  [API_EXCEL.START_ROW]: "",
  [API_EXCEL.START_COLUMN]: "",
  [API_EXCEL.BIG_DATA]: true,
  [API_EXCEL.DATA_OWNER]: userProfile.dataOwner,
  [API_EXCEL.NAME]: userProfile.userId,
  [API_EXCEL.EMAIL]: userProfile.mail,
  [API_EXCEL.ROLE_ID]: userProfile.roleId,
};

const LOGICAL_OPR = [
  { cd: "AND", value: "AND" },
  { cd: "OR", value: "OR" },
  { cd: "LIKE", value: "LIKE" },
];

export { DEFAULT_EXCEL_SCREEN, LOGICAL_OPR };
