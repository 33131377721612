import React, { Fragment } from "react";
import { DataGridTable, TanStackTable } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
import { findObject, messageDisplay, responseErrors, responseSuccessArray } from "utils";
import { MSG_TYPE, ModeAction } from "state/enum";
import { formatRowUpdated, simulateGraph, simulateGraphModel } from "../hooks/format";
//Service
import { useWarehouseConfirmAllocateMutation } from "shared/services/inventory";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    columns,
    graphData,
    setGraph,
    form,
    rPkg,
    defaultGraphData,
    onSimulate,
    getWarehouse,
    setMsg: { setMsgAlert, setMsgError, msgError },
    dataList: { rPackageOwnerList, rPackageTypeList, wareHouseList },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  } = props;
  const confirmAllocate = useWarehouseConfirmAllocateMutation();

  const handleSimulate = async row => {
    try {
      let msg;
      let error = [];
      let isValid = true;
      //! validate required field
      if (!row?.plantId && row?.allocateDt) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN93011AERR",
          msg: [`Row${row?.rowNumber}`, `Container ${row?.containerNo}`, "Allocation Date and Warehouse"],
        });
        isValid = false;
        error.push(msg);
      }
      if (!isValid) {
        setMsgError(prev => [...prev, error]);
        window.scroll(0, 0);
        return;
      }

      const newGraph = simulateGraphModel(row, defaultGraphData, rPkg);
      console.log("newGraph", newGraph);
      setGraph(newGraph);
      // if (rowSelectionModel?.length > 0) {
      //   rowSelectionModel?.forEach(item => {
      //     if (item?.plantId && !item?.allocateDt) {
      //       msg = messageDisplay({
      //         type: MSG_TYPE.ERROR,
      //         code: "MDN93011AERR",
      //         msg: [`Row${item?.rowNumber}`, `Container ${item?.containerNo}`, "Allocation Date and Warehouse"],
      //       });
      //       isValid = false;
      //       error.push(msg);
      //     }
      //   });
      //   if (!isValid) {
      //     setMsgError(error);
      //     window.scroll(0, 0);
      //     return;
      //   }
      // }
      if (rowSelectionModel?.length === rows?.length) {
        const simulateData = simulateGraph(rowSelectionModel, defaultGraphData, rPkg);
        setGraph(simulateData);
        let tempRowModesModel = rowModesModel;
        const rowSelect = rowSelectionModel?.map(val => {
          return (tempRowModesModel = {
            ...tempRowModesModel,
            [val.rowNumber]: { mode: GridRowModes.Edit },
          });
        });
        if (rowSelect) {
          setRowModesModel(tempRowModesModel);
        }
      }
      return;
    } catch (error) {
      console.error(error);
      // const errors = responseErrors(error);
      // setMsgError([`${errors}`]);
      window.scroll(0, 0);
      return;
    }
  };
  const handleAllocated = async row => {
    try {
      // TODO validate forecastDt if don't have data show error and which have error
      const updatedRow = formatRowUpdated(rowSelectionModel, getWarehouse);
      const updateData = {
        dataOwner: form?.dataOwner,
        rPackageOwner: form?.rPackageOwnerId,
        rPackageType: form?.rPackageTypeId,
        wareHouseId: form?.wareHouseId,
        rows: updatedRow,
      };
      const response = await confirmAllocate(updateData);
      const msg = responseSuccessArray(response);
      setMsgAlert(msg);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const processRowUpdate = async row => {
    try {
      // setMsgAlert([]);
      // setMsgError([]);
      if (onSimulate) {
        await handleSimulate(row);
      } else {
        console.log(row);
        setRowModesModel({});
        setRowSelectionModel([]);
        setMode(ModeAction.VIEW);
      }
      return true;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Fragment>
      <TanStackTable
        id="table-bar"
        rows={rows}
        columns={columns}
        // processRowUpdate={processRowUpdate}
        rowSelectionKey={rowModesModel}
        setRowSelectionKey={setRowModesModel}
        rowSelection={rowSelectionModel}
        setRowSelect={setRowSelectionModel}
        columnVisibility={{ wareHouseName: false, whCompanyAbbrId: false, whCompanyAbbr: false }}
        tableHeight="700px"
        enableRowSelection={false}
        enableMultiRowSelection={true}
        onPagination={false}
      />

      {/* <DataGridTable
        id="table-bar"
        mode={mode}
        setMode={setMode}
        tableHeight="500px"
        rows={rows}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error => {}}
        // onProcessRowUpdateError={error => console.error(`children:${error.message}, severity: ${error}`)}
        rowSelection={{
          rowModesModel: rowModesModel,
          setRowModesModel: setRowModesModel,
          // rowSelectionModel: rowSelectionModel,
          // setRowSelectionModel: setRowSelectionModel,
        }}
        column={{
          columns: columns,
          columnVisibilityModel: { wareHouseName: false, whCompanyAbbrId: false, whCompanyAbbr: false },
        }}
        pagination={{}}
        onCellClick={false}
        onCellDoubleClick={false}
        checkboxSelection={false}
        isMultipleSelection={true}
        onPagination={false}
      /> */}
    </Fragment>
  );
}
