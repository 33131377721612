import React from "react";
import { Box } from "@mui/material";
import { DataGridTable, SubmitBar } from "components/UI";
//Hooks
import { useLoadingContext } from "shared/hooks/LoadingContext";
//Utils
import { findObject, messageDisplay, responseErrors, responseSuccess } from "utils";
import { formatContainerForUpdateData, transformData } from "../hooks/transformData";
import { validationSearchForm } from "utils/validation";
//Service
import {
  useShippingInfoCreateMutation,
  useShippingInfoUpdateMutation,
  useShippingInfoSearchMutation,
} from "shared/services/invoice";
//Type
import { MessageType, ModeAction, MSG_TYPE } from "state/enum";
import { PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import { getLocalDate } from "utils/init-config-date";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setOnSearch,
    refreshRowsTable,
    formContainer,
    dataList: { exporterList, importerList, consigneeList, brokerList },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const searchShippingInfo = useShippingInfoSearchMutation();
  const createShippingInfo = useShippingInfoCreateMutation();
  const updateShippingInfo = useShippingInfoUpdateMutation();
  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        expCd: form?.exporterCd,
        impCompanyId: form?.importerId,
        loadingPort: form?.loadingPort,
        dischargingPort: form?.dischargingPort,
        etd: form?.etd,
        orderType: form?.orderTypeId,
        vessel: form?.vesselName,
        carfamilyCode: form?.carFamilyCode,
        [PAGINATION.PAGE_NUMBER]: value,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      startLoading();
      const searchData = await searchShippingInfo(body);
      stopLoading();
      const data = transformData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleCreate = async newRow => {
    try {
      let msg;
      console.log(newRow);
      let body = {
        expCd: newRow?.exporterCode,
        impCompanyId: newRow?.impCompanyAbbrId,
        impCompanyAbbr: newRow?.impCompanyAbbrId
          ? findObject({
              data: importerList,
              value: newRow?.impCompanyAbbrId,
              property: "companyId",
              field: "companyAbbr",
            })
          : "",
        countryOfOrigin: newRow?.exporterCode
          ? findObject({ data: exporterList, value: newRow?.exporterCode, property: "impExpCd", field: "countryCd" })
          : "",
        loadingPort: newRow?.loadingPort,
        dischargingPort: newRow?.dischargingPort,
        etd: newRow?.etd,
        vessel: newRow?.vesselName,
        consignee: newRow?.consigneeId
          ? findObject({ data: consigneeList, value: newRow?.consigneeId, property: "cd", field: "value" })
          : "",
        broker: newRow.broker,
        brokerAbbr: newRow.broker
          ? findObject({ data: brokerList, value: newRow?.broker, property: "cd", field: "value" })
          : "",
        userId: userProfile?.userId,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "expCd",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
          {
            field: "impCompanyId",
            type: MessageType.EMPTY,
            key: ["Importer Company"],
          },
          {
            field: "etd",
            type: MessageType.EMPTY,
            key: ["ETD", "DD/MM/YYYY"],
          },
          {
            field: "loadingPort",
            type: MessageType.EMPTY,
            key: ["Loading Port"],
          },
          {
            field: "dischargingPort",
            type: MessageType.EMPTY,
            key: ["Discharging Port"],
          },
          {
            field: "countryOfOrigin",
            type: MessageType.EMPTY,
            key: ["Country of Origin"],
          },
          {
            field: "vessel",
            type: MessageType.EMPTY,
            key: ["Vessel Name"],
          },
          {
            field: "broker",
            type: MessageType.EMPTY,
            key: ["Broker"],
          },
          {
            field: "etd",
            type: MessageType.DATE_FORMAT_50,
            key: ["ETD", "DD/MM/YYYY"],
          },
          {
            field: "etd",
            type: MessageType.DATE_EQUAL_LATER_CURRENT,
            key: ["ETD", "Current Date", "YYYY-MM-DD"],
          },
        ],
      });
      setMsgError(errors);
      let stopProcess = true;
      const pattern = /^[0-9a-zA-Z.\-\s\/]*$/;
      if (body.vessel.trim().length > 0) {
        if (!pattern.test(body.vessel)) {
          msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0015AERR", msg: ["Vessel Name"] });
          setMsgError(prev => [...prev, msg]);
          stopProcess = false;
        }
      }
      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return false;
      }
      body.etd = !newRow?.etd ? "" : getLocalDate(newRow?.etd, "DD/MM/YYYY").format("YYYY-MM-DD");

      startLoading();
      const response = await createShippingInfo(body);
      stopLoading();
      msg = responseSuccess(response);
      setMsgAlert([msg]);
      // setMsgAlert(["MDN90015AINF: Data is added successfully."]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };
  const handleUpdate = async row => {
    try {
      let msg;
      const container = formatContainerForUpdateData(formContainer?.rows);
      const body = {
        userId: userProfile.userId,
        email: userProfile?.mail,
        shippingData: {
          expCd: row?.exporterCode,
          impCd: row?.importerCode,
          impCompanyId: row?.impCompanyAbbrId,
          impCompanyAbbr: row?.impCompanyAbbr,
          etd: row?.etd,
          csnCompanyId: row?.consigneeId,
          csnCompanyAbbr: row?.consigneeId
            ? findObject({ data: consigneeList, value: row?.consigneeId, property: "cd", field: "value" })
            : "",
          loadingPort: row?.loadingPort,
          dischargingPort: row?.dischargingPort,
          vessel: row?.vesselName,
          brokerCompanyId: row?.broker
            ? findObject({ data: brokerList, value: row?.broker, property: "value", field: "cd" })
            : "",
          brokerCompanyAbbr: row?.broker,
          shippingInfoHId: row?.shippingInfoHId,
          orderTypeCd: row?.orderTypeCd,
          carFamilyCd: row?.carFamilyCode,
        },
        container: container, // if empty choose delete all existing container
      };

      const { isSuccess, errors } = validationSearchForm({
        data: body?.shippingData,
        rule: [
          {
            field: "loadingPort",
            type: MessageType.EMPTY,
            key: ["Loading Port"],
          },
          {
            field: "dischargingPort",
            type: MessageType.EMPTY,
            key: ["Discharging Port"],
          },
          {
            field: "vessel",
            type: MessageType.EMPTY,
            key: ["Vessel Name"],
          },
          {
            field: "brokerCompanyId",
            type: MessageType.EMPTY,
            key: ["Broker"],
          },
        ],
      });
      setMsgError(errors);
      let stopProcess = true;
      const pattern = /^[0-9a-zA-Z.\-\s\/]*$/;
      if (body?.shippingData?.vessel.trim().length > 0) {
        if (!pattern.test(body?.shippingData?.vessel)) {
          msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0015AERR", msg: ["Vessel Name"] });
          setMsgError(prev => [...prev, msg]);
          stopProcess = false;
        }
      }
      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return false;
      }

      startLoading();
      const response = await updateShippingInfo(body);
      stopLoading();
      if ([200, 201].includes(response?.statusCode) && !!response?.result?.length) {
        msg = response?.result.map(ele => `${ele?.code}: ${ele?.message}\n`);
      }
      setMsgAlert(msg);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };
  const processRowUpdate = async newRow => {
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      // startLoading();
      if (newRow.isNew) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowSelectionModel([]);
        setRowModesModel({});
        setRows([]);
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);

        const doneRefresh = await refreshRowsTable();
        if (!doneRefresh) return;
      }
      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}>
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        onCellClick={false}
        onCellDoubleClick={false}
        checkboxSelection={true}
        processRowUpdate={processRowUpdate}
        handleChangePagination={handleChangePagination}
        column={{
          columns,
          columnVisibilityModel: {
            [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_H_ID]: false,
            [API_INVOICE_SHIPPING_INFO.VANNING_INFO_H_ID]: false,
            [API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY]: false,
            [API_INVOICE_SHIPPING_INFO.CONSIGNEE]: false,
          },
        }}
        rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
        pagination={{ pagination, pageNumber, handleChangePagination }}
      />
      <SubmitBar
        setOnSearch={setOnSearch}
        mode={mode}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
      />
    </Box>
  );
}
