const DEFAULT_EXPORT_INVOICE_INFO = {
  importerId: "",
  vesselName: "",
  invoiceNo: "",
  etdFrom: "",
  etdTo: "",
  reportFormatCd: "",
  totalsM3Flag: "",
};

const DEFAULT_STATE_WDN95011 = {
  invNo: "",
  invType: "",
  etdDt: "",
  vesselName: "",
  impCompanyId: "",
  expCd: "",
  parkingMonth: "",
  vanningPlant: "",
  expBrokerCompanyId: "",

  expCustomStockDt: "",
  nonComInvFlag: "",
  broker: "",
  currencyCd: "",
  expEntryNo: "",
};

const DEFAULT_STATE_WDN95012 = {
  etdDt: "",
  containerNo: "",
  companyId: "",
  // dataOwner: "TMATH",
  // etdDt: "12/07/2023",
  // companyId: "CMP_1208_000011",
  // containerNo: "BSIU9693500",
  // userId: "test",
  // loginUserCompanyAbbr: "TMATH",
  // pageNumber: 1,
  // rowsPerPage: 10,
};

export { DEFAULT_EXPORT_INVOICE_INFO, DEFAULT_STATE_WDN95011, DEFAULT_STATE_WDN95012 };
