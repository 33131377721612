import { useMutateData, useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_INVENTORY;
// /category/other-material-stock-management/status
const OtherMaterialStockManagementApiService = () => ({
  useOtherMaterialStockManagementGetStatusQuery: () => {
    const data = useCustomQuery({
      key: "status",
      url: `${BaseServicePath}/category/other-material-stock-management/status`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useOtherMaterialStockManagementUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["status"]],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/other-material-stock-management/status/update`,
        method: "POST",
        data: body,
      });
    };
  },
});
export const { useOtherMaterialStockManagementGetStatusQuery, useOtherMaterialStockManagementUpdateMutation } =
  OtherMaterialStockManagementApiService();
