import React from "react";
import { ModeAction } from "state/enum";

export const useButtonMode = props => {
  const {
    mode,
    stateButton: { setSearchBtn, setClearBtn, setEditBtn, setAddBtn, setCopyBtn, setDeleteBtn },
  } = props;

  switch (mode) {
    case ModeAction.VIEW:
      setSearchBtn(false);
      setClearBtn(false);

      setAddBtn(false);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;

    case ModeAction.ADD:
      setSearchBtn(true);
      setClearBtn(true);

      setAddBtn(true);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;

    case ModeAction.EDIT:
      setSearchBtn(true);
      setClearBtn(true);

      setAddBtn(true);
      setCopyBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      break;
    case ModeAction.SELECTED:
      setSearchBtn(false);
      setClearBtn(false);

      setAddBtn(false);
      setCopyBtn(false);
      setEditBtn(false);
      setDeleteBtn(false);
      break;

    default:
      break;
  }
};
