import { API_WAREHOUSE_SUMMARY } from "shared/constants";
import { getLocalDate } from "utils/init-config-date";

const formatRowWareHouseSummary = dataRaw => {
  try {
    let data = [];
    let rPkg = [];
    if (!dataRaw?.length) {
      console.log("data not found");
      return;
    }
    dataRaw.forEach((val, index) => {
      const row = {
        no: index + 1,
        [API_WAREHOUSE_SUMMARY.ROW_NUMBER]: val.rowNumber,
        [API_WAREHOUSE_SUMMARY.SHIPMENT_CODE]: val.shipmentCd,
        [API_WAREHOUSE_SUMMARY.CONTAINER_NO]: val.containerNo,
        [API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS]: val.allocateStatus,
        [API_WAREHOUSE_SUMMARY.READY_FOR_DATE]: val.readyForDt,
        whCompanyAbbrId: val.whCompanyAbbrId,
        whCompanyAbbr: val.whCompanyAbbr,
        [API_WAREHOUSE_SUMMARY.WARE_HOUSE_NAME]: val.whImpExpCd,
        [API_WAREHOUSE_SUMMARY.PLANT_ID]: val.whPlantId,
        [API_WAREHOUSE_SUMMARY.ALLOCATE_DATE]: val.allocateDt,
      };
      val.rPkg.forEach(item => {
        row[item.key] = parseInt(item.qty);
        if (!rPkg.includes(item.key)) return rPkg.push(item.key);
      });
      data.push(row);
    });
    return { data, rPkg };
  } catch (error) {
    console.error(error);
  }
};
const formatRowUpdated = (rowSelectionModel, getWarehouse) => {
  if (!rowSelectionModel.length) return [];

  const data = rowSelectionModel?.map(item => ({
    [API_WAREHOUSE_SUMMARY.SHIPMENT_CODE]: !item?.shipmentCd ? "" : item?.shipmentCd,
    [API_WAREHOUSE_SUMMARY.CONTAINER_NO]: !item?.containerNo ? "" : item?.containerNo,
    [API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS]: !item?.allocateStatus ? "" : item?.allocateStatus,
    [API_WAREHOUSE_SUMMARY.WARE_HOUSE_NAME]: !item?.plantId ? "" : getWarehouse(item?.plantId),
    [API_WAREHOUSE_SUMMARY.PLANT_ID]: !item?.plantId ? "" : item?.plantId,
    [API_WAREHOUSE_SUMMARY.ALLOCATE_DATE]: !item?.allocateDt
      ? ""
      : getLocalDate(item?.allocateDt, "DD/MM/YYYY").format("YYYY-MM-DD"),
  }));

  return data;
};

const simulateGraph = (editData, graphData, rPkg) => {
  try {
    let tempGraphData = [...graphData];
    tempGraphData?.forEach(wh => {
      let tempWh = wh;
      editData.forEach(row => {
        // find warehouse
        if (row.plantId !== tempWh.plantId) {
          return tempWh;
        }
        // find r-package
        tempWh.rPkgTypeList.forEach((type, index) => {
          //!mock check later
          if (type.rPkgType !== rPkg[index]) {
            return;
          }
          // find forecast date match with allocated date
          type.dateQtyList.forEach(date => {
            if (date.forecastDt !== getLocalDate(row.allocateDt, "DD/MM/YYYY").format("DD/MM")) {
              return;
            }
            console.log(date.newEndingBalance, row[rPkg[index]]);
            date.newEndingBalance = parseInt(date.newEndingBalance) + parseInt(row[rPkg[index]]);
            return;
          });
        });
      });
    });
    return tempGraphData;
  } catch (error) {
    console.error(error);
  }
};
const simulateGraphModel = (row, graphData, rPkg) => {
  try {
    const tempGraphData = graphData?.map(wh => {
      let tempWh = { ...wh };
      // find warehouse
      if (row.plantId !== tempWh.plantId) {
        return tempWh;
      }
      // find r-package
      tempWh.rPkgTypeList.forEach((type, index) => {
        if (type?.rPkgType !== rPkg[index]) {
          return;
        }
        // find forecast date match with allocated date
        type.dateQtyList.forEach(date => {
          if (date.forecastDt !== getLocalDate(row.allocateDt, "DD/MM/YYYY").format("DD/MM")) {
            return;
          }

          let newEndingBalance = parseInt(date.newEndingBalance) + parseInt(row[rPkg[index]]);
          date.newEndingBalance = newEndingBalance;
          // date.newEndingBalance = newEndingBalance.toString();
          return;
        });
      });
      return tempWh;
    });
    // console.log("defaultGraphData", graphData);
    return tempGraphData;
  } catch (error) {
    console.error(error);
  }
};

export { formatRowWareHouseSummary, formatRowUpdated, simulateGraph, simulateGraphModel };
