import React from "react";
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { ModeAction } from "state/enum";
import { findObject } from "utils";
export default function CriterialSection(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { ImporterCompanyData },
  } = props;

  const handleChangeImporterCompany = (e) => {
    setForm((prev) => ({
      ...prev,
      companyId: e.target.value,
    }));
  };

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>ETD (From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input_etd_from"
            className="input_etd_from"
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                etdDt: e,
              }))
            }
            value={form?.etdDt}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Container No. : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input_vessel_name"
            fullWidth={true}
            maxLength={20}
            value={form?.containerNo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                containerNo: e.target.value,
              }))
            }
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Importer Company : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="input-importer-company"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.companyId}
            onChange={handleChangeImporterCompany}
            menu={ImporterCompanyData?.map((val) => ({
              key: val?.companyId,
              value: val?.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={7} />
      </Grid>
    </Grid>
  );
}
