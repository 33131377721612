import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;

const ContainerGroupApiService = () => ({
  useContainerGroupGetApmcQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/container-group/apmc/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetApmcPlantQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/container-group/apmc/plant/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetContainerTypeQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/container-group/container-type/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
    });
    return data;
  },
  useContainerGroupGetContainerTypeModalQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/container-group/container-type/${dataOwner}/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
    });
    return data;
  },
  useContainerGroupGetImpExpCdQuery: ({ dataOwner, type }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/gentan-i/imp-exp/plant/${type}/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetRPackageTypeQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      key: ["r-package-type", dataOwner],
      url: `${BaseServicePath}/category/container-group/r-package/${dataOwner}/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupGetWarehouseQuery: ({ dataOwner }) => {
    const data = useCustomQuery({
      key: ["ware-house", dataOwner],
      url: `${BaseServicePath}/category/container-group/warehouse/${dataOwner}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useContainerGroupSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/container-group/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useContainerGroupCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      console.log(body);
      return await mutate({
        url: `${BaseServicePath}/container-group/create`,
        method: "POST",
        data: body,
      });
    };
  },
  useContainerGroupUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["ware-house"], ["r-package-type"]],
    });
    return async (body, containerGroupId) => {
      return await mutate({
        url: `${BaseServicePath}/container-group/edit/${containerGroupId}`,
        method: "PATCH",
        data: body,
      });
    };
  },
  useContainerGroupDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["ware-house"], ["r-package-type"]],
    });
    return async (containerGroupId, body) => {
      return await mutate({
        url: `${BaseServicePath}/container-group/delete/${containerGroupId}`,
        method: "DELETE",
        data: body,
      });
    };
  },
  useContainerGroupDownloadExcelMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/container-group/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}`);
          },
        }
      );
    };
  },
});
export const {
  useContainerGroupGetApmcQuery,
  useContainerGroupGetApmcPlantQuery,
  useContainerGroupGetContainerTypeQuery,
  useContainerGroupGetContainerTypeModalQuery,
  useContainerGroupGetImpExpCdQuery,
  useContainerGroupGetRPackageTypeQuery,
  useContainerGroupGetWarehouseQuery,
  useContainerGroupCreateMutation,
  useContainerGroupSearchMutation,
  useContainerGroupUpdateMutation,
  useContainerGroupDeleteMutation,
  useContainerGroupDownloadExcelMutation,
} = ContainerGroupApiService();
