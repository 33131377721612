import React, { Fragment, useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage, InputDropDown } from "components/UI";
import { ModeAction } from "state/enum";
import ContainerTable from "./partials/ContainerTable";
import RpackageTable from "./partials/RpackageTable";
// import { useConfirmDialogContext } from "context/confirmDialogContext";
import {
  useGetContainerTypeDropDown,
  useGetImpCdDropDown,
  useGetRpkgTypeDropDown,
  useGetType,
} from "service/volumeDimension";
import { userProfile } from "constant";

const VolumeDimensionScreen = () => {
  // const confirmDialogCtx = useConfirmDialogContext();

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [mode, setMode] = useState("view");
  const [onSearch, setOnSearch] = useState(false);

  const [searchForm, setSearchForm] = useState({
    dataOwner: userProfile.dataOwner,
    dimensionType: "",
    refCompanyAbbr: "",
    refImpExpCd: "",
    rPkgType: "",
    containerType: "",
  });

  useEffect(() => {
    document.getElementById("select_dimensionType").focus();
  }, []);

  const { data: typeData } = useGetType({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: containerTypeData, refetch: refetchContainerTypeData } =
    useGetContainerTypeDropDown({
      type: searchForm.dimensionType === "C" ? "CONTAINER" : "R_PACKAGE",
      dataOwner: userProfile.dataOwner, // TODO: get dataOwner from profile
      rPkgOwnerCompAbbr: userProfile.packageOwner,
    });

  const { data: importerData, refetch: refetchImporterData  } = useGetImpCdDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  const { data: rpkgTypeData, refetch: refetchRpkgTypeData } = useGetRpkgTypeDropDown({
    dataOwner: userProfile.dataOwner,
    rPkgOwnerCompAbbr: userProfile.packageOwner,
  });

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN91060 : Volume Dimension Master Maintenance Screen" />

        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />

        <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
          <Grid item xs={2}>
            <strong>* Type of dimension : </strong>
          </Grid>
          <Grid item xs={3}>
            <InputDropDown
              id="select_dimensionType"
              required
              value={searchForm.dimensionType}
              onChange={(e) => {
                setOnSearch(false);
                setMsgAlert([]);
                setMsgError([]);

                setSearchForm((old) => ({
                  dataOwner: userProfile.dataOwner,
                  refCompanyAbbr: "",
                  refImpExpCd: "",
                  rPkgType: "",
                  containerType: "",
                  dimensionType: e.target.value,
                }));
                refetchContainerTypeData()
              }}
              memu={typeData?.map((val) => ({
                key: val.cd,
                value: val.value,
              }))}
              disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
              placeholder="<Select>"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={7}></Grid>

          {searchForm.dimensionType === "C" && (
            <>
              <Grid item xs={2} sx={{ mt: 2 }}>
                <strong>Container Type : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <InputDropDown
                  id="select_containerTypeId"
                  value={searchForm.containerType}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      containerType: e.target.value,
                    }))
                  }
                  memu={containerTypeData?.map((val) => ({
                    key: val.containerType,
                    value: val.containerType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={5}></Grid>
            </>
          )}

          {searchForm.dimensionType === "R" && (
            <>
              <Grid item xs={2} sx={{ mt: 2 }}>
                <strong>Importer : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <InputDropDown
                  id="select_refImpExpCd"
                  value={searchForm.refImpExpCd}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      refImpExpCd: e.target.value,
                    }))
                  }
                  memu={importerData?.map((val) => ({
                    key: val.refImpExpCd,
                    value: val.importer,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={2} sx={{ mt: 2 }}>
                <strong>Container Type : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <InputDropDown
                  id="select_containerTypeId"
                  value={searchForm.containerType}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      containerType: e.target.value,
                    }))
                  }
                  memu={containerTypeData?.map((val) => ({
                    key: val.containerType,
                    value: val.containerType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={2} sx={{ mt: 2 }}>
                <strong>R-Package Type : </strong>
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <InputDropDown
                  id="select_rPkgType"
                  value={searchForm.rPkgType}
                  onChange={(e) =>
                    setSearchForm((old) => ({
                      ...old,
                      rPkgType: e.target.value,
                    }))
                  }
                  memu={rpkgTypeData?.map((val) => ({
                    key: val.rPkgType,
                    value: val.rPkgType,
                  }))}
                  disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
                  placeholder="<All>"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
        </Grid>
      </Box>
      {searchForm.dimensionType === "C" && (
        <ContainerTable
          setMsgAlert={setMsgAlert}
          setMsgError={setMsgError}
          setOnSearch={setOnSearch}
          searchForm={searchForm}
          onSearch={onSearch}
          setSearchForm={setSearchForm}
          setMode={setMode}
          mode={mode}
          refetchContainerTypeData={refetchContainerTypeData}
        />
      )}
      {searchForm.dimensionType === "R" && (
        <RpackageTable
          setMsgAlert={setMsgAlert}
          setMsgError={setMsgError}
          setOnSearch={setOnSearch}
          searchForm={searchForm}
          onSearch={onSearch}
          setSearchForm={setSearchForm}
          setMode={setMode}
          mode={mode}
          refetchImporterData={refetchImporterData}
          refetchContainerTypeData={refetchContainerTypeData}
          refetchRpkgTypeData={refetchRpkgTypeData}
        />
      )}
    </Fragment>
  );
};

export default VolumeDimensionScreen;
