import { useMutateData, useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_INVOICE;

const RPackageApiService = () => ({
  useRPackageSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/package-detail`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useRPackageGroupCodeGetMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async rPkgType => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/get-group-cd-by-package-type`,
        data: { rPkgType: rPkgType },
        method: "POST",
      });
    };
  },
  useRPackageGetRPkgOwnerQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/shipping-information-maintenance/get-package-owner`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
      // invalidateKeys: ["rPkg-type"],
    });
    return data;
  },
  useRPackageGetRPkgTypeQuery: rPkgOwnerCompId => {
    const data = useCustomQuery({
      key: ["rPkg-type", rPkgOwnerCompId],
      url: `${BaseServicePath}/shipping-information-maintenance/get-package-type`,
      method: "POST",
      body: { rPkgOwnerCompId: rPkgOwnerCompId },
      enabled: !!rPkgOwnerCompId,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useRPackageSearchMutation,
  useRPackageGetRPkgOwnerQuery,
  useRPackageGetRPkgTypeQuery,
  useRPackageGroupCodeGetMutation,
} = RPackageApiService();
