import { DataGridTable } from "components/UI";
import React, { useMemo } from "react";
import ColWDN97053 from "../hooks/ColWDN97053";

export default function TablePrint({ mode, setMode, rows, rowSelectionModel, setRowSelectionModel }) {
  const columns = useMemo(() => ColWDN97053(), []);
  return (
    <DataGridTable
      id="table-print-delivery"
      mode={mode}
      tableHeight="355px"
      rowHeight={35}
      setMode={setMode}
      rows={rows}
      column={{ columns: columns }}
      onPagination={false}
      pagination={{}}
      rowSelection={{ rowSelectionModel: rowSelectionModel, setRowSelectionModel: setRowSelectionModel }}
      disableCheckBoxAll={false}
      onCellClick={true}
      onCellDoubleClick={false}
      checkboxSelection={true}
      isMultipleSelection={true}
    />
  );
}
