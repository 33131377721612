export const API_INVOICE_SHIPPING_INFO = {
  ROW_NUMBER: "rowNumber",
  EXPORTER_CODE: "exporterCode",
  EXP_CODE: "expCd",
  EXPORTER_COUNTRY: "expCountry",
  EXPORTER_COUNTRY_CODE: "expCountryCd",
  IMPORTER_CODE: "importerCode",
  IMP_CODE: "impCd",
  IMPORTER_COMPANY: "impCompanyAbbr",
  IMPORTER_COMPANY_ID: "impCompanyAbbrId",
  ETD_DATE: "etd",
  CONSIGNEE: "consignee",
  CONSIGNEE_ID: "consigneeId",
  LOADING_PORT: "loadingPort",
  DISCHARGING_PORT: "dischargingPort",
  VESSEL_NAME: "vesselName",
  BROKER: "broker",
  BROKER_ID: "brokerId",
  CAR_FAMILY_CODE: "carFamilyCode",
  INV_STATUS: "invoiceStatus",
  CONTAINER_DETAIL: "containerDetail",
  ORDER_TYPE: "orderType",
  ORDER_TYPE_CODE: "orderTypeCd",
  VANNING_STATUS: "vanningStatus",
  AIR_SHIPMENT: "airShipment",
  SHIPPING_INFO_H_ID: "shippingInfoHId",
  VANNING_INFO_H_ID: "vanningInfoHId",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
};
