export const API_SHIPMENT = {
  //Search
  DATA_OWNER: "dataOwner",
  COMPANY: "company",
  OPERATION: "operation",
  OPERATION_ID: "operationId",
  FORECAST_MONTH: "forecastMonth",
  STATUS: "status",
  ETD_FORM: "etdFrom",
  ETD_TO: "etdTo",
  USER_ID: "userId",
  //mapping
  ETA_FORM: "etaFrom",
  ETA_TO: "etaTo",
  //importer
  COMPANY_ABBR: "companyAbbr",
  EXPORTER: "exporter",
  IMPORTER: "importer",
  EXPORTER_ID: "exporterId",
  IMPORTER_ID: "importerId",
  COMPANY_ID: "companyId",
  IMP_EXP_CD: "impExpCd",
  R_RKG_OWNER: "rPkgOwner",
  R_RKG_OWNER_ID: "rPkgOwnerId",
  R_RKG_OWNER_COMP_ABBR: "rPkgOwnerCompAbbr",
  R_RKG_OWNER_COMP_ID: "rPkgOwnerCompId",

  // Result Search
  ROW_NUMBER: "rowNumber",
  SHIPMENT_SUMMARY_H_ID: "shipmentSummaryHId",
  FORECAST_PERIOD: "forecastPeriod",
  VANNING_MONTH: "vanningMonth",
  EXPORTER_CODE: "exporterCd",
  IMPORTER_CODE: "importerCd",
  SHIPMENT_CODE: "shipmentCd",
  EXP_COMPANY_ID: "exporterCompanyId",
  IMP_COMPANY_ID: "importerCompanyId",
  PLAN_VAN_FROM: "planVanningFrom",
  PLAN_VAN_TO: "planVanningTo",
  ACTUAL_VAN_FROM: "actlVanningFrom",
  ACTUAL_VAN_TO: "actlVanningTo",
  INVOICE_NO: "invoiceNo",
  TRANSPORT_LT_ID: "transportLtId",
  ETD_DATE: "etdDt",
  ETA_DATE: "etaDt",
  PLAN_ETD_DATE: "planEtdDt",
  PLAN_ETA_DATE: "planEtaDt",
  ACTUAL_ETD_DATE: "actlEtdDt",
  ACTUAL_ETA_DATE: "actlEtaDt",
  FILE: "file",
  FILE_NAME: "fileName",
  ORG_ACTL_FILE_NAME: "orgActlFileName",
  ORG_NCV_FILE_NAME: "orgNcvFileName",
  NCV_FILE_NAME: "ncvFileName",
  NCV_FILE_UPLOAD: "ncvFileUpload",
  ORG_BL_FILE_NAME: "orgBlFileName",
  BL_FILE_NAME: "blFileName",
  BL_FILE_UPLOAD: "blFileUpload",
  SHIPMENT_STS: "shipmentSts",
  CANCEL_FLAG: "cancelFlag",
  UPDATE_DATE: "updateDt",
  CARRIER: "carrier",
  ETD_SORT: "etdSort",
  PLAN_ACTL: "planActual",
  PLAN: "plan",
  ACTUAL: "actual",
  SHIPMENT_STATUS: "shipmentSts",
  SHIPMENT_STATUS_ID: "shipmentStsId",
  SHIPMENT_SUM_UPDATE_DATE: "updateDt",
  SHIPMENT_CANCEL_FLAG: "cancelFlag",
  MANUAL_UPLOAD_INV_FLAG: "manualUploadInvFlag",
  UPDATE_BY: "updateBy",
  CREATE_BY: "createBy",
  FUNCTION_ID: "functionId",
};
