import { useMutation as useCustomMutation } from "@tanstack/react-query";
import axios from "configs/axiosConfig";
import apiClient from "utils/api-client";

const httpMethods = {
  GET: apiClient.get,
  POST: apiClient.post,
  PUT: apiClient.put,
  PATCH: apiClient.patch,
  DELETE: apiClient.delete,
};

const useMutation = ({
  url,
  method = "POST",
  key,
  enabled = false,
  overRideUrl = false,
}) => {
  return useCustomMutation({
    mutationFn: async (postData) => {
      if (method === "DELETE") {
        if (overRideUrl) {
          const { data } = await axios.delete(url, {
            data: postData,
          });
          return data;
        }

        const { data } = await httpMethods[method](url, {
          data: postData,
        });
        return data;
      }

      if (method === "GET") {
        if (overRideUrl) {
          const { data } = await axios.get(url, {
            params: postData,
          });
          return data;
        }
        const { data } = await apiClient.get(url, {
          params: postData,
        });
        return data;
      }

      if (method === "POST") {
        if (overRideUrl) {
          const { data } = await axios.post(url, postData);
          return data;
        }
      }

      const { data } = await httpMethods[method](url, postData);
      return data;
    },
    onSuccess: (data) => {
      console.log("Mutation successful:", data);
    },
    onError: (error) => {
      console.log("Mutation error:", error);
      throw error;
    },
    mutationKey: key,
    enabled: enabled,
  });
};

export default useMutation;
