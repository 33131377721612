export const BUTTON_VALUE = {
  SEARCH: "Search",
  CLEAR: "Clear",
  ADD: "Add",
  EDIT: "Edit",
  DELETE: "Delete",
  SAVE: "Save",
  CANCEL: "Cancel",
  DOWNLOAD: "Download",
  ALL_EXPORT: "All Exporter",
  UPLOAD: "Upload",
  RE_PROCESS: "Reprocess",
  IGNORE: " Ignore",
  EXECUTE: " Execute",
  BACK: "Back",
  COPY: "Copy",
  DETAIL: "Detail",
  SEND: "Send",
  VIEW: "View",
  GENERATE_REPORTS: "Generate Report",
  CREATE_INVOICE: "Create Invoice",
  CANCEL_INVOICE: "Cancel Invoice",
  CANCEL_VANNING_RESULT: "Cancel Vanning Result",
};
