import { useMutateData } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_INVOICE;

const ContainerApiService = () => ({
  useContainerSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/container-detail`,
        data: searchData,
        method: "POST",
      });
    };
  },
  useContainerGetActualRPkgMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async vanningData => {
      return await mutate({
        url: `${BaseServicePath}/shipping-information-maintenance/get-actual-package`,
        data: vanningData,
        method: "POST",
      });
    };
  },
});

export const { useContainerSearchMutation, useContainerGetActualRPkgMutation } = ContainerApiService();
