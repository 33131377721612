import React, { useRef } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import "dayjs/locale/th";
import { styled } from "@mui/material";
import { getLocalDate } from "utils/init-config-date";

const StyledDatePicker = styled(DatePicker)(({ theme, error }) => ({
  ".MuiInputBase-input": {
    padding: "5.5px 14px",
  },
  "& .MuiInputBase-root": {
    "& fieldset": {
      border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium} !important`,
    },
    "&.Mui-focused fieldset": {
      border: `1.5px solid black !important`,
    },
  },
}));

const InputDatePicker = ({
  id,
  onChange,
  format = "DD/MM/YYYY",
  value,
  disabled = false,
  required,
  fullWidth,
  autoFocus = false,
  ...props
}) => {
  const handleChange = date => {
    onChange(date);
  };

  const formattedValue = getLocalDate(value, "DD/MM/YYYY").format("YYYY-MM-DD");
  // const formattedValue = dayjs(value, "DD/MM/YYYY").format("YYYY-MM-DD");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
      <StyledDatePicker
        {...props}
        className={id}
        data-testid={id}
        id={id}
        sx={{
          background: required ? "#ffe1e4" : "#FFF",
          width: fullWidth && "100%",
        }}
        value={getLocalDate(formattedValue)}
        inputFormat="DD/MM/YYYY"
        onChange={handleChange}
        onViewChange={e => console.log(e)}
        disabled={disabled}
        format={format}
        autoFocus={autoFocus}
        required={required}
      />
    </LocalizationProvider>
  );
};

export default InputDatePicker;
