export const responseSuccess = (response = null) => {
  if (!response) return;

  const code = response?.result?.code;
  const message = response?.result?.message;

  return `${code}: ${message}\n`;
};
export const responseSuccessArray = (response = null) => {
  if (!response.length) return;
  let error = [];

  let info = [];
  response.forEach(msg => {
    if ([200, ""].includes(msg?.statusCode)) {
      info.push(msg?.message);
    } else {
      error.push(msg?.message);
    }
  });
  console.log(error, info);
  return { error, info };
};
