import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { userProfile } from "constant";
import React, { Fragment } from "react";
import { BUTTON_VALUE } from "shared/constants";
import { useUploadingScanningDataUpdateDataMutation } from "shared/services/main-server";
import { responseErrors } from "utils";

export default function ButtonSendingData({ form, setForm, setMsg: { setMsgError, setMsgAlert } }) {
  const uploadApi = useUploadingScanningDataUpdateDataMutation();
  const handleSend = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      const body = {
        impExpCode: !form?.locationId ? "all" : form?.locationId,
        dataType: !form?.dataTypeId ? "all" : form?.dataTypeId,
        userCompanyCd: userProfile.dataOwner,
        userId: userProfile.userId,
      };
      const response = await uploadApi(body);
      setMsgAlert([response?.message]);
      return;
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm({ locationId: "", dataTypeId: "" });
  };
  return (
    <Fragment>
      <Box sx={{ display: "flex", flexFlow: "row wrap", justifyContent: "flex-end", alignItems: "center" }}>
        <InputButton value={BUTTON_VALUE.SEND} onClick={handleSend} />
        <InputButton
          value={BUTTON_VALUE.CLEAR}
          onClick={handleClear}
          onKeyDown={e => {
            if (e.key === "Tab") {
              document.getElementById("dropdown-location").focus();
            }
          }}
        />
      </Box>
    </Fragment>
  );
}
