const COLUMN_SHIPPING_INFO = {
  NO: "No.",
  EXPORTER_CODE: "Exporter Code",
  IMPORTER_COMPANY: "Importer Company",
  IMPORTER_CODE: "Importer Code",
  ETD: "ETD (DD/MM/YYYY)",
  CONSIGNEE: "Consignee",
  LOADING_PORT: "Loading Port",
  DISCHARGING_PORT: "Discharging Port",
  VESSEL_NAME: "Vessel Name",
  BROKER: "Broker",
  ORDER_TYPE: "Order Type",
  CAR_FAMILY_CODE: "Car Family Code",
  VANNING_STATUS: "Status",
  CONTAINER_DETAIL: "Container Detail",

  // container sub
  SHIPPING_PLAN: "Shipping Plan",
  CONTAINER_NO: "Container No.",
  R_MODULE_QTY: "R-Module Qty",
  R_BOX_Dun_QTY: "R-Box/Dun Qty",
  R_PACKAGE_DETAIL: "R-Package Detail",
  ACTUAL_VANNING: "Actual Vanning",
  PACKING_MONTH: "Packing Month (MM/YYYY)",
  VANNING_PLANT: "Vanning Plant",
  STATUS: "Status",

  //r-package sub
  R_PACKAGE_OWNER: "R-Package Owner",
  R_PACKAGE_TYPE: "R-Package Type",
  PLAN_QTY: "Plan Qty",
};
export { COLUMN_SHIPPING_INFO };
