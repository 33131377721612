import { MSG_TYPE } from "../enum/messageType";

export const MSG_LIST = [
  //! ERROR
  //INVENTORY SHIPPING
  {
    key: MSG_TYPE.ERROR,
    code: "MDN93011AERR",
    message: "{0} {1}, {2} have to be inputted or not inputted concurrently.",
  },
  //REPROCESS
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD1048AERR",
    message: "Invalid excel file format. Please use valid excel template which you can download from screen.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0100AINF",
    message: "Upload Failed",
  },
  //SHIPMENT
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0074AERR",
    message: "Please input data",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0015AERR",
    message: "{0} must be alphanumeric",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0024AERR",
    message: "{0} must be less than {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN92024AERR",
    message: "Please finish Shipment No. {0} with upload actual data and NCV before process new shipment",
  },
  // #WDN95030
  {
    key: MSG_TYPE.ERROR,
    code: "MDN95040AERR",
    message: "Cannot {0} the selected record because the ETD Date is less than the current date.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0043AERR",
    message: "Invalid {0}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN95031AERR",
    message: "A single record of {0} must be selected to {1}",
  },
  // #WDN95031
  {
    key: MSG_TYPE.ERROR,
    code: "MDN90023AERR",
    message: "A record must be selected to execute Copy operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN93008AERR",
    message:
      "Record of Actual Vanning data without Shipping Plan must be selected to execute Copy Actual Data operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0031AERR",
    message: "{0} should not be empty",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0013AERR",
    message: `{0} file does not exist`,
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0018AERR",
    message: "{0} must be equal to or greater than {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0021AERR",
    message: "{0} must be greater than {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD1017AERR",
    message: "A single record must be selected to execute Edit operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD1018AERR",
    message: "A single record must be selected to execute Cancel operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0039AERR",
    message: "Duplication found for {0}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0051AERR",
    message: "Invalid length of {0}. The length can not be more than {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0059AERR",
    message: "No data found",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0067AERR",
    message: "Undefine Error : {0}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0019AERR",
    message: "{0} must be equal to or later than {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD7013BERR",
    message: "File {0} is not expected file type {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN92017AERR",
    message: "{0} for the {1} {2} not exist.",
  },
  //Master
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD1005AERR",
    message: "Cannot {0} ,because Effective Date To = {1} already passed",
  },
  // {
  //   key: MSG_TYPE.ERROR,
  //   code: "MSTD1005AERR",
  //   message: "Cannot [0] ,because Effective Date FROM = [1] already passed",
  // },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN90025AERR",
    message: "A record must be selected to execute Edit operation.",
  },
  // Excel Download
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0063AERR",
    message: "No data to be saved",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0114AERR",
    message: "Error in updated, because of concurrency check.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0115AERR",
    message: "Error in Delete because of concurrency check.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN90024AERR",
    message: "A record must be selected to execute Delete operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD1016AERR",
    message: "A single record must be selected to execute Delete operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDN92009AERR",
    message: "Forecast data of {0} {1} already generated.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0045AERR",
    message: "Invalid date format for {0}. The format must be {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0050AERR",
    message: "Invalid format for {0}. The format must be {1}",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0105XXXX",
    message: "At least one record must be selected to execute {Execute Batch} operation.",
  },
  // Reprocess
  {
    key: MSG_TYPE.ERROR,
    code: "MDL00021AERR",
    message: "A single record must be selected to execute {0} operation.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDL00023AERR",
    message: "At least one record must be selected to execute [Reprocess] operation",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MDL00024AERR",
    message: "t least one record must be selected to execute [Ignore] operation",
  },
  {
    //  EXCEL DOWNLOAD
    key: MSG_TYPE.ERROR,
    code: "MSTD1066AERR",
    message: "Table/View with more than 60000 records cannot be downloaded online. Please download using Big Data.",
  },
  {
    key: MSG_TYPE.ERROR,
    code: "MSTD0060AERR",
    message: "No data to be deleted",
  },
  // INFO
  {
    key: MSG_TYPE.INFO,
    code: "MDN92016AINF",
    message: "{0} process is started.",
  },
  {
    // Monthly
    key: MSG_TYPE.INFO,
    code: "MDN92015ACFM",
    message: "{0} process is started.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MDN920XXX",
    message: "{0} process is started.",
  },
  {
    //  On Demand Batch
    key: MSG_TYPE.INFO,
    code: "MDN92015A",
    message: "{0} process is started.",
  },
  {
    //  On Demand Batch
    key: MSG_TYPE.INFO,
    code: "MSTD0097AINF",
    message: "Posting data is completed successfully.",
  },

  {
    key: MSG_TYPE.INFO,
    code: "MDN90017AINF",
    message: "Data is deleted successfully",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MDN90042AINF",
    message: "Data is temporarily added.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MDN90043AINF",
    message: "Data is temporarily edited.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MDN90044AINF",
    message: "Data is temporarily deleted.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MSTD0090AINF",
    message: "Reprocess Success.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MSTD0091AINF",
    message: "Ignore Success.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MSTD0101AINF",
    message: "Upload Success.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MSTD0081AINF",
    message: "Excel File Generation is completed successfully.",
  },
  // inventory
  {
    key: MSG_TYPE.INFO,
    code: "MDN90016AINF",
    message: "Data is updated successfully.",
  },
  {
    key: MSG_TYPE.INFO,
    code: "MDN90034AINF",
    message: "Posting on demand batch is completed successfully.",
  },
  //bar code
  {
    key: MSG_TYPE.INFO,
    code: "MDN91008INF",
    message: "Barcode report is generated. Please save the file.",
  },
];
