import { useMutateData, useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_HANDHELD;

const UploadingScanningDataApiService = () => ({
  useUploadingScanningDataGetPlantServerQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-plant-server-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useUploadingScanningDataGetDataTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/main-server-screen/get-data-type-list`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },

  useUploadingScanningDataUpdateDataMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async uploadData => {
      return await mutate({
        url: `${BaseServicePath}/main-server-screen/sending-data-to-plant`,
        data: uploadData,
        method: "POST",
      });
    };
  },
});

export const {
  useUploadingScanningDataGetPlantServerQuery,
  useUploadingScanningDataGetDataTypeQuery,
  useUploadingScanningDataUpdateDataMutation,
} = UploadingScanningDataApiService();
