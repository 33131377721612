import { Box } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { REPORT_ROUTES_LIST } from "./constants/constants";

// components
import MonthlyForecastReport from "./components/Form/MonthlyForecastReport";
import DailyInventoryManagementReport from "./components/Form/DailyInventoryManagementReport";
import DeliveryPlanActualReport from "./components/Form/DeliveryPlanActualReport";
import StockInOutReport from "./components/Form/StockInOutReport";
import RpackageCustomsBalanceReport from "./components/Form/RpackageCustomsBalanceReport";
import StockSummaryReport from "./components/Form/StockSummaryReport";
import AgingStockAndUsageRawDataReport from "./components/Form/AgingStockAndUsageRawDataReport";
import ReturningPunctualityReport from "./components/Form/ReturningPunctualityReport";
import TransactionRawDataReport from "./components/Form/TransactionRawDataReport";
import StockVisualizationReport from "./components/Form/StockVisualizationReport";
import AgingStockReport from "./components/Form/AgingStockReport";
import AverageLeadTimeReport from "./components/Form/AverageLeadTimeReport";
import RPackageCatalogReport from "./components/Form/RPackageCatalogReport";

const Report = () => {
  const location = useLocation();

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [msgServerError, setMsgServerError] = useState([]);

  const handleRenderComponentByRoute = () => {
    const uri = location.pathname;

    switch (uri) {
      case REPORT_ROUTES_LIST.MONTHLY:
        return (
          <MonthlyForecastReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.DAILY_INVENTORY:
        return (
          <DailyInventoryManagementReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.DELIVERY_PLAN:
        return (
          <DeliveryPlanActualReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.STOCK_IN_OUT:
        return (
          <StockInOutReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.R_PACKAGE_CUSTOMS:
        return (
          <RpackageCustomsBalanceReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.RETURNING:
        return (
          <ReturningPunctualityReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.STOCK_SUMMARY:
        return (
          <StockSummaryReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.AGING_STOCK_USAGE:
        return (
          <AgingStockAndUsageRawDataReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.TRANSACTION_RAW_DATA:
        return (
          <TransactionRawDataReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.R_PACKAGE_CATALOG:
        return (
          <RPackageCatalogReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.STOCK_VISUALIZATION:
        return (
          <StockVisualizationReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.AGING_STOCK:
        return (
          <AgingStockReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );
      case REPORT_ROUTES_LIST.AVERAGE_LEAD:
        return (
          <AverageLeadTimeReport
            setMsgError={setMsgError}
            setMsgAlert={setMsgAlert}
            setMsgServerErrorAlert={setMsgServerError}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    setMsgAlert([]);
    setMsgError([]);
  }, [location]);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN96010 : Common Excel Report Download Screen" />
        <AlertMessage message={msgServerError} />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>

      <Box sx={{ padding: "1rem" }}>{handleRenderComponentByRoute()}</Box>
    </Fragment>
  );
};

export default Report;
