import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import _ from "lodash";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetCountry = ({ dataOwner, countryCd }) => {
  return useQuery({
    url: `/path/country/${dataOwner}/${countryCd}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetCountryMaster = ({ dataOwner, category, subCategory }) => {
  return useQuery({
    url: `/category/country/company/${category}/${subCategory}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetRoutePointInTable = ({ dataOwner, countryCd }) => {
  return useQuery({
    url: `/path/route-point/${dataOwner}/${countryCd}`,
    method: "GET",
    enabled: countryCd !== "",
    select: data => data.result,
  });
};

export const useGetRoutePointForm = ({ dataOwner, countryCd }) => {
  return useQuery({
    url: `/path/route-point/${dataOwner}/${countryCd}/FROM_COUNTRY_CD`,
    method: "GET",
    enabled: countryCd !== "",
    select: data => data.result,
  });
};

export const useGetRoutePointTo = ({ dataOwner, countryCd }) => {
  return useQuery({
    url: `/path/route-point/${dataOwner}/${countryCd}/TO_COUNTRY_CD`,
    method: "GET",
    enabled: countryCd !== "",
    select: data => data.result,
  });
};

export const useSearchPathMaster = ({
  dataOwner,
  pathName,
  fromCountryCd,
  toCountryCd,
  fromRoutePointId,
  toRoutePointId,
  pageNumber,
  rowsPerPage,
}) => {
  return useMutation({
    url: "/path/search",
    method: "POST",
    data: {
      dataOwner,
      pathName,
      fromCountryCd,
      toCountryCd,
      fromRoutePointId,
      toRoutePointId,
      pageNumber,
      rowsPerPage,
    },
  });
};

export const useCreatePathMaster = body => {
  return useMutation({
    url: "/path/type/create",
    method: "POST",
    data: { ...body },
  });
};

export const useUpdatePathMaster = body => {
  return useMutation({
    url: "/path/type/edit",
    method: "POST",
    data: { ...body },
  });
};

export const useDeletePathMaster = pathId => {
  return useMutation({
    url: `/path/type/delete/${pathId}`,
    method: "DELETE",
    key: [pathId],
    enabled: !_.isEmpty(pathId),
  });
};

export const useDownloadPathMasterExcel = ({ pathId }) => {
  return useMutation({
    url: `/path/download/excel`,
    method: "POST",
    data: { pathId },
  });
};
export const usePathMasterDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/path/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
