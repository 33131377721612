import React, { Fragment } from "react";
// Components
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";

// Constant
import { API_NAME_ON_DEMAND_EXCEL } from "shared/constants";
import { ModeAction } from "state/enum";
export default function FilterBar(props) {
  const { form, setForm, reportStatusList, mode } = props;

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mt: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Request Date: </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="select_request_date"
            required
            fullWidth={true}
            autoFocus={true}
            value={form?.requestDate}
            onChange={date => {
              setForm(prev => ({
                ...prev,
                [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: date,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Report Status: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select_report_status"
            value={form.reportStatus}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: e.target.value,
              }));
            }}
            menu={reportStatusList?.map(val => ({
              key: val?.statusId,
              value: val?.status,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={2}>
          <strong>Report Name: </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input_report_name"
            fullWidth
            value={form.reportName}
            maxLength={100}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: e.target.value,
              }));
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
