export const transformDataWDN97053 = dataList => {
  const data = dataList.map((item, index) => ({
    no: index + 1,
    rowNumber: item?.rowNumber,
    actualDlvDt: item?.deliveryDate,
    dlvInstrShtNo: item?.deliveryInstructionSheetNo,
    deliveryType: item?.deliveryType,
    sender: item?.sender,
    receiver: item?.destination,
    shippingInfo: item?.shippingInformation,
    containerNo: item?.containerNo,
    packSts: item?.packStatus,
  }));
  return data;
};
