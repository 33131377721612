import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { DatePickerField, InputButton, InputDropDown, InputText } from "components/UI";
import { userProfile } from "constant";
import {
  useGetDownloadAverageLocationGroup,
  useGetDownloadAverageRpackageOwner,
  useGetDownloadAverageRpackageType,
  useGetDownloadCarFamilyCode,
  useGetDownloadCarFamilyType,
  useGetDownloadCompanyCode,
  useGetDownloadExporter,
  useGetDownloadImporter,
  useGetDownloadLocationType,
  useGetDownloadReceiver,
  useGetDownloadSender,
  useGetDownloadSopExporter,
  useGetDownloadSopImporter,
  useGetDownloadSopRpackage,
  useGetDownloadSubseries,
  useGetDownloadTransportCategory,
  useGetDownloadTransportMode,
  useGetDownloadYear,
  // download excel
  useDownloadWorkingCalendarMutation,
  useDownloadTransportLeadTimeMutation,
  useDownloadCustomStockLeadTimeMutation,
  useDownloadSopMutation,
  useDownloadKatashikiMutation,
  useDownloadLeadTimeMutation,
  useDownloadAveLeadTimeGroupMutation,
} from "service/downloadAndUpload";

import { findObject, responseDownloadFileErrors } from "utils";

export default function DownloadMaster({
  searchForm,
  setSearchForm,
  downloadAndUploadMasterData,
  setMsgAlert,
  setMsgError,
}) {
  const downloadWorkingCalendar = useDownloadWorkingCalendarMutation();
  const downloadTransportLeadTime = useDownloadTransportLeadTimeMutation();
  const downloadCustomStockLeadTime = useDownloadCustomStockLeadTimeMutation();
  const downloadSop = useDownloadSopMutation();
  const downloadKatashiki = useDownloadKatashikiMutation();
  const downloadLeadTime = useDownloadLeadTimeMutation();
  const downloadAveLeadTimeGroup = useDownloadAveLeadTimeGroupMutation();

  // working calendar
  const { data: downloadYearData } = useGetDownloadYear({
    dataOwner: userProfile.dataOwner,
  });
  const { data: downloadCompanyCodeData } = useGetDownloadCompanyCode({
    dataOwner: userProfile.dataOwner,
    year: searchForm.year,
  });

  const { data: downloadTransportCategoryData } = useGetDownloadTransportCategory({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadTransportModeData } = useGetDownloadTransportMode({
    dataOwner: userProfile.dataOwner,
    transportCd: searchForm.transportCd,
  });

  const { data: downloadSenderData } = useGetDownloadSender({
    dataOwner: userProfile.dataOwner,
    transportCd: searchForm.transportCd,
    transportMd: searchForm.transportMd,
  });

  const { data: downloadReceiverData } = useGetDownloadReceiver({
    dataOwner: userProfile.dataOwner,
    transportCd: searchForm.transportCd,
    transportMd: searchForm.transportMd,
    senderCompId: searchForm.senderCompId,
  });

  const { data: downloadExporterData } = useGetDownloadExporter({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadImporterData } = useGetDownloadImporter({
    dataOwner: userProfile.dataOwner,
    expCompId: searchForm.expCompId,
  });

  const { data: downloadSopExporterData } = useGetDownloadSopExporter({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadSopImporterData } = useGetDownloadSopImporter({
    dataOwner: userProfile.dataOwner,
    expCompId: searchForm.expCompId,
  });

  const { data: downloadSopRpackageData } = useGetDownloadSopRpackage({
    dataOwner: userProfile.dataOwner,
    expCompId: searchForm.expCompId,
    impCompId: searchForm.impCompId,
  });
  const { data: downloadCarFamilyData } = useGetDownloadCarFamilyType({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadCarFamilyCodeData } = useGetDownloadCarFamilyCode({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadSubseriesData } = useGetDownloadSubseries({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadLocationTypeData } = useGetDownloadLocationType();

  const { data: downloadAverageRpackageOwnerData } = useGetDownloadAverageRpackageOwner({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadAverageRpackageTypeData } = useGetDownloadAverageRpackageType({
    dataOwner: userProfile.dataOwner,
  });

  const { data: downloadAverageLocationGroupData } = useGetDownloadAverageLocationGroup({
    dataOwner: userProfile.dataOwner,
    locationTypeCd: searchForm.locationTypeCd,
  });

  // handle change download dropdown
  const handleChangeDownload = e => {
    setMsgAlert([]);
    setMsgError([]);

    setSearchForm(old => ({
      ...old,
      // reset upload
      upload: "",
      dataTableSearch: "",
      // download
      download: e.target.value,
      year: "",
      companyId: "",
      transportCd: "",
      transportMd: "",
      senderCompId: "",
      receiverCompId: "",
      effMonthFrom: "",
      effMonthTo: "",
      expCompId: "",
      impCompId: "",
      effDtFrom: "",
      effDtTo: "",
      rPkgTypeId: "",
      carFamilyTypeCd: "",
      carFamilyCd: "",
      subSeries: "",
      locationTypeCd: "",
      locationTypeDesc: "",
      rPkgOwnerCompAbbr: "",
      locationGroupId: "",
    }));
  };

  // render form download
  const renderFormDownload = () => {
    // console.log("searchForm.download", searchForm.download);
    switch (searchForm.download) {
      case "BDN910M0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={2}>
              <strong>R-Package Owner : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.rPkgOwnerCompAbbr}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    rPkgOwnerCompAbbr: e.target.value,
                    rPkgTypeId: "",
                  }))
                }
                memu={downloadAverageRpackageOwnerData?.map(val => ({
                  key: val.rPkgOwnerCompAbbr,
                  value: val.rPkgOwnerCompAbbr,
                }))}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>R-Package Type : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.rPkgTypeId}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    rPkgTypeId: e.target.value,
                  }))
                }
                memu={downloadAverageRpackageTypeData?.map(val => ({
                  key: val.rPkgType,
                  value: val.rPkgType,
                }))}
                disabled={searchForm.rPkgOwnerCompAbbr === ""}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Location Type : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.locationTypeCd}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    locationTypeCd: e.target.value,
                    locationGroupId: "",
                  }))
                }
                memu={downloadLocationTypeData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                defaultValue=""
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Location Group : </strong>
            </Grid>
            {searchForm.locationTypeCd === "P" ? (
              <Grid item xs={4}>
                <InputDropDown
                  value={searchForm?.locationGroupId}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      locationGroupId: e.target.value,
                    }))
                  }
                  memu={downloadAverageLocationGroupData?.map(val => ({
                    key: val.locationGroupId,
                    value: val.locationGroup,
                  }))}
                  defaultValue=""
                  disabled={searchForm.locationTypeCd === ""}
                  placeholder="<All>"
                />
              </Grid>
            ) : (
              <Grid item xs={4}>
                <InputDropDown
                  value={searchForm?.locationGroupId}
                  onChange={e =>
                    setSearchForm(old => ({
                      ...old,
                      locationGroupId: e.target.value,
                    }))
                  }
                  memu={downloadAverageLocationGroupData?.map(val => ({
                    key: val.locationGroup,
                    value: val.locationGroup,
                  }))}
                  defaultValue=""
                  disabled={searchForm.locationTypeCd === ""}
                  placeholder="<All>"
                />
              </Grid>
            )}
          </Grid>
        );

      case "BDN910G0":
        return (
          <Grid container spacing={2} marginTop={2} alignItems="center">
            <Grid item xs={3}>
              <strong>Exporter : </strong>
            </Grid>
            <Grid item xs={3}>
              <InputDropDown
                id="select_expCompId"
                value={searchForm.expCompId}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    expCompId: e.target.value,
                    impCompId: "",
                  }))
                }
                memu={downloadExporterData?.map(val => ({
                  key: val.expCompId,
                  value: val.expCompAbbr,
                }))}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={3}>
              <strong>Importer : </strong>
            </Grid>
            <Grid item xs={3}>
              <InputDropDown
                id="select_impCompId"
                value={searchForm.impCompId}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    impCompId: searchForm.expCompId === "" ? "" : e.target.value,
                  }))
                }
                memu={downloadImporterData?.map(val => ({
                  key: val.impCompId,
                  value: val.impCompAbbr,
                }))}
                disabled={searchForm.expCompId === ""}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={3}>
              <strong>*Effective Date (From) : </strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                fullWidth={true}
                value={searchForm.effDtFrom}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effDtFrom: e,
                  }))
                }
              />
            </Grid>
            <Grid item xs={3}>
              <strong>Effective Date (To) : </strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                fullWidth={true}
                value={searchForm.effDtTo}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effDtTo: e,
                  }))
                }
              />
            </Grid>
          </Grid>
        );

      case "BDN910I0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={3}>
              <strong>Car Family Type : </strong>
            </Grid>
            <Grid item xs={3}>
              <InputDropDown
                placeholder="<All>"
                value={searchForm.carFamilyTypeCd}
                memu={downloadCarFamilyData?.map(val => ({
                  key: val.carFamilyTypeCd,
                  value: val.carFamilyType,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    carFamilyTypeCd: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={3}>
              <strong>Car Family Code : </strong>
            </Grid>
            <Grid item xs={3}>
              <InputDropDown
                placeholder="<All>"
                value={searchForm.carFamilyCd}
                memu={downloadCarFamilyCodeData?.map(val => ({
                  key: val.carFamilyCd,
                  value: val.carFamilyCd,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    carFamilyCd: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={3}>
              <strong>Sub Series : </strong>
            </Grid>
            <Grid item xs={3}>
              <InputDropDown
                placeholder="<All>"
                value={searchForm.subSeries}
                memu={downloadSubseriesData?.map(val => ({
                  key: val.subSeries,
                  value: val.subSeries,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    subSeries: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <strong>*Effective Date (From) : </strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                fullWidth={true}
                value={searchForm.effDtFrom}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effDtFrom: e,
                  }))
                }
              />
            </Grid>
            <Grid item xs={3}>
              <strong>Effective Date (To) : </strong>
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                fullWidth={true}
                value={searchForm.effDtTo}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effDtTo: e,
                  }))
                }
              />
            </Grid>
          </Grid>
        );

      case "BDN910L0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={2}>
              <strong>*Location Type : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                required
                value={searchForm.locationTypeCd}
                memu={downloadLocationTypeData?.map(val => ({
                  key: val.cd,
                  value: val.value,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    locationTypeCd: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Location Desc : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputText
                fullWidth
                value={searchForm.locationTypeDesc}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    locationTypeDesc: e.target.value,
                  }))
                }
                regularExp=""
              />
            </Grid>
          </Grid>
        );

      case "BDN910H0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={2}>
              <strong>Exporter : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.expCompId}
                memu={downloadSopExporterData?.map(val => ({
                  key: val.expCompId,
                  value: val.expCompAbbr,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    expCompId: e.target.value,
                    impCompId: "",
                    rPkgTypeId: "",
                  }))
                }
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Importer : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.impCompId}
                memu={downloadSopImporterData?.map(val => ({
                  key: val.impCompId,
                  value: val.impCompAbbr,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    impCompId: searchForm.expCompId === "" ? "" : e.target.value,
                    rPkgTypeId: "",
                  }))
                }
                disabled={searchForm.expCompId === ""}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>R-Package Type : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.rPkgTypeId}
                memu={downloadSopRpackageData?.map(val => ({
                  key: val.rPkgTypeId,
                  value: val.rPkgType,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    rPkgTypeId: searchForm.impCompId === "" ? "" : e.target.value,
                  }))
                }
                disabled={searchForm.impCompId === ""}
                placeholder="<All>"
              />
            </Grid>
          </Grid>
        );

      case "BDN910F0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={2}>
              <strong>*Transportation Category : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                required
                value={searchForm.transportCd}
                memu={downloadTransportCategoryData?.map(val => ({
                  key: val.transportCd,
                  value: val.transportCategoryName,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    transportCd: e.target.value,
                    transportMd: "",
                    senderCompId: "",
                    receiverCompId: "",
                  }))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <strong>*Transportation Mode : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                required
                value={searchForm.transportMd}
                memu={downloadTransportModeData?.map(val => ({
                  key: val.transportMd,
                  value: val.transportModeName,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    transportMd: searchForm.transportCd === "" ? "" : e.target.value,
                    senderCompId: "",
                    receiverCompId: "",
                  }))
                }
                disabled={searchForm.transportCd === ""}
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Sender : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.senderCompId}
                memu={downloadSenderData?.map(val => ({
                  key: val.senderCompId,
                  value: val.senderCompAbbr,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    senderCompId: searchForm.transportMd === "" ? "" : e.target.value,
                    receiverCompId: "",
                  }))
                }
                disabled={searchForm.transportMd === ""}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Receiver : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.receiverCompId}
                memu={downloadReceiverData?.map(val => ({
                  key: val.receiverCompId,
                  value: val.receiverCompAbbr,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    receiverCompId: searchForm.senderCompId === "" ? "" : e.target.value,
                  }))
                }
                disabled={searchForm.senderCompId === ""}
                placeholder="<All>"
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Effective Month (From) : </strong>
            </Grid>
            <Grid item xs={4}>
              <DatePickerField
                views={["year", "month"]}
                format="MM/YYYY"
                mask="MM/YYYY"
                fullWidth={true}
                value={searchForm.effMonthFrom}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effMonthFrom: e,
                  }))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Effective Month (To) : </strong>
            </Grid>
            <Grid item xs={4}>
              <DatePickerField
                views={["year", "month"]}
                format="MM/YYYY"
                mask="MM/YYYY"
                fullWidth
                value={searchForm.effMonthTo}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    effMonthTo: e,
                  }))
                }
              />
            </Grid>
          </Grid>
        );

      case "BDN910E0":
        return (
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={2}>
              <strong>*Year : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                required
                value={searchForm.year}
                memu={downloadYearData?.map(val => ({
                  key: val.year,
                  value: val.year,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    year: e.target.value,
                    companyId: "",
                  }))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <strong>Company Code : </strong>
            </Grid>
            <Grid item xs={4}>
              <InputDropDown
                value={searchForm.companyId}
                memu={downloadCompanyCodeData?.map(val => ({
                  key: val.companyId,
                  value: val.companyAbbr,
                }))}
                onChange={e =>
                  setSearchForm(old => ({
                    ...old,
                    companyId: searchForm.year === "" ? "" : e.target.value,
                  }))
                }
                disabled={searchForm.year === ""}
                placeholder="<All>"
              />
            </Grid>
          </Grid>
        );

      default:
        break;
    }
    return null;
  };

  // handle download click
  const handleDownloadClick = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      let option = {};

      switch (searchForm.download) {
        case "BDN910E0":
          option = {
            dataOwner: userProfile.dataOwner,
            year: searchForm.year,
            companyId: searchForm.companyId,
          };
          // filename = "Working_Calendar_Master";
          await downloadWorkingCalendar(option);
          return true;

        case "BDN910F0":
          option = {
            dataOwner: userProfile.dataOwner,
            transportCd: searchForm.transportCd,
            transportMd: searchForm.transportMd,
            senderCompId: searchForm.senderCompId,
            receiverCompId: searchForm.receiverCompId,
            effMonthFrom: searchForm.effMonthFrom,
            effMonthTo: searchForm.effMonthTo,
            // effMonthFrom: formatDate(searchForm.effMonthFrom, "MM/YYYY"),
            // effMonthTo: formatDate(searchForm.effMonthTo, "MM/YYYY"),
          };
          await downloadTransportLeadTime(option);
          return true;

        case "BDN910G0":
          option = {
            dataOwner: userProfile.dataOwner,
            expCompId: searchForm.expCompId,
            impCompId: searchForm.impCompId,
            effDtFrom: searchForm.effDtFrom,
            effDtTo: searchForm.effDtTo,
          };
          await downloadCustomStockLeadTime(option);
          return true;

        case "BDN910H0":
          option = {
            dataOwner: userProfile.dataOwner,
            expCompId: searchForm.expCompId,
            impCompId: searchForm.impCompId,
            rPkgTypeId: searchForm.rPkgTypeId,
          };
          await downloadSop(option);
          return true;

        case "BDN910I0":
          option = {
            dataOwner: userProfile.dataOwner,
            carFamilyTypeCd: searchForm.carFamilyTypeCd,
            carFamilyCd: searchForm.carFamilyCd,
            subSeries: searchForm.subSeries,
            effDtFrom: searchForm.effDtFrom,
            effDtTo: searchForm.effDtTo,
          };
          await downloadKatashiki(option);
          return true;

        case "BDN910L0":
          option = {
            dataOwner: userProfile.dataOwner,
            locationTypeCd: searchForm.locationTypeCd,
            locationTypeDesc: searchForm.locationTypeDesc,
          };
          await downloadLeadTime(option);
          return true;

        case "BDN910M0":
          option = {
            dataOwner: userProfile.dataOwner,
            rPkgOwnerCompAbbr: searchForm.rPkgOwnerCompAbbr,
            rPkgType: !searchForm.rPkgTypeId
              ? ""
              : findObject({
                  data: downloadSopRpackageData,
                  value: searchForm.rPkgTypeId,
                  property: "rPkgTypeId",
                  field: "rPkgType",
                }),
            locationTypeCd: searchForm.locationTypeCd,
            locationGroup: !searchForm.locationGroupId
              ? ""
              : searchForm.locationTypeCd === "P"
                ? findObject({
                    data: downloadAverageLocationGroupData,
                    value: searchForm.locationGroupId,
                    property: "locationGroupId",
                    field: "locationGroup",
                  })
                : findObject({
                    data: downloadAverageLocationGroupData,
                    value: searchForm.locationGroupId,
                    property: "locationGroup",
                    field: "locationGroup",
                  }),
          };
          await downloadAveLeadTimeGroup(option);
          return true;

        default:
          return;
        // break;
      }
    } catch (error) {
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };

  // handle clear click
  const handleClearDownloadClick = () => {
    setMsgAlert([]);
    setMsgError([]);
    setSearchForm(old => ({
      ...old,
      download: "",
      year: "",
      companyId: "",
      transportCd: "",
      transportMd: "",
      senderCompId: "",
      receiverCompId: "",
      effMonthFrom: "",
      effMonthTo: "",
      expCompId: "",
      impCompId: "",
      effDtFrom: "",
      effDtTo: "",
      rPkgTypeId: "",
      carFamilyTypeCd: "",
      carFamilyCd: "",
      subSeries: "",
      locationTypeCd: "",
      locationTypeDesc: "",
      rPkgOwnerCompAbbr: "",
      locationGroupId: "",
    }));
  };
  const downloadMasterList = useMemo(() => {
    if (downloadAndUploadMasterData) {
      downloadAndUploadMasterData?.sort((a, b) => {
        return a?.value.toLowerCase().localeCompare(b?.value.toLowerCase());
      });
      return downloadAndUploadMasterData;
    }
    return downloadAndUploadMasterData;
  }, [downloadAndUploadMasterData]);
  return (
    <Grid container spacing={0} sx={{ mt: 4, alignItems: "center" }}>
      <Grid item xs={2}>
        <strong>*Download Master : </strong>
      </Grid>
      <Grid item xs={4}>
        <InputDropDown
          id="select_download"
          required
          value={searchForm.download}
          onChange={e => handleChangeDownload(e)}
          memu={downloadMasterList?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
        />
      </Grid>

      {renderFormDownload()}

      {searchForm.download !== "" && (
        <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop={5}>
          <InputButton value="Download" onClick={handleDownloadClick} />
          <InputButton value="Clear" onClick={handleClearDownloadClick} />
        </Grid>
      )}
    </Grid>
  );
}
