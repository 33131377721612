import React from "react";
import { Grid } from "@mui/material";
import { Typography } from "components/UI";

export default function DetailSection({ onRPkgScreen, form }) {
  return (
    <React.Fragment>
      {/* Line 1 */}
      <Grid container spacing={2} sx={{ mb: 2, justifyContent: "space-between" }}>
        <Grid item xs={3}>
          <strong>Exporter Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.exporterCd} sx={{ color: "#000000" }} />
        </Grid>
        <Grid item xs={3}>
          <strong>Importer Company :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.importerComp} sx={{ color: "#000000" }} />
        </Grid>
        {/* Line 2 */}
        <Grid item xs={3}>
          <strong>ETD (DD/MM/YYYY) :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.etdDt} sx={{ color: "#000000" }} />
        </Grid>
        <Grid item xs={3}>
          <strong>Consignee :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.consignee} sx={{ color: "#000000" }} />
        </Grid>
        {/* Line 3 */}
        <Grid item xs={3}>
          <strong>Loading Port :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.loadingPort} sx={{ color: "#000000" }} />
        </Grid>
        <Grid item xs={3}>
          <strong>Discharging Port :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.dischargingPort} sx={{ color: "#000000" }} />
        </Grid>
        {/* Line 4 */}
        <Grid item xs={3}>
          <strong>Broker :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.broker} sx={{ color: "#000000" }} />
        </Grid>
        <Grid item xs={3}>
          <strong>Vessel :</strong>
        </Grid>
        <Grid item xs={3}>
          <Typography content={form?.vesselName} sx={{ color: "#000000" }} />
        </Grid>
        {/* Line 5 */}
        {onRPkgScreen && (
          <>
            <Grid item xs={3}>
              <strong>Container No. :</strong>
            </Grid>
            <Grid item xs={3}>
              <Typography content={form?.containerNo} sx={{ color: "#000000" }} />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}
