import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, Typography } from "components/UI";

//ENUM
import { ModeAction } from "state/enum";
import { API_MONTHLY } from "shared/constants/api-name/forecast/monthly";
import { userProfile } from "constant";
export default function HeaderSection({
  setMode,
  setRows,
  setOnSearch,
  setOnOpen,
  searchForm,
  setSearchForm,
  setRowSelectionModel,
  setPagination,
  setPageNumber,
  forecastTypeList,
  mode,
  setMsg: { setMsgError, setMsgAlert },
}) {
  const handleChange = e => {
    setMsgError([]);
    setMsgAlert([]);
    setSearchForm({
      [API_MONTHLY.FORECAST_TYPE_CD]: e.target.value,
      [API_MONTHLY.FORECAST_MONTH]: "",
      [API_MONTHLY.MODE_CD]: "",
      [API_MONTHLY.DATA_TYPE_CD]: "",
      [API_MONTHLY.UPLOAD_STATUS_CD]: "",
      [API_MONTHLY.UPLOAD_COMPANY_ABBR]: "",
      [API_MONTHLY.FILE_UPLOAD_ID]: "",
      [API_MONTHLY.DATA_OWNER]: userProfile.dataOwner,
      [API_MONTHLY.USER_ID]: userProfile.userId,
      [API_MONTHLY.ORG_FILE_NAME]: "",
      file: {},
    });

    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setOnOpen(false);
    setRowSelectionModel([]);
    setRows([]);
    setPagination({});
    setPageNumber(1);
  };
  return (
    <Fragment>
      <Grid container spacing={0.5} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <Typography
            id="label-forecast-type"
            content="Forecast Type:"
            sx={{
              color: "#000",
              fontFamily: "Kanit",
              fontWeight: "bold",
              mr: 2,
            }}
            noWrap={false}
          />
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="select-forecast-type"
            required
            value={searchForm.forecastTypeCd}
            onChange={handleChange}
            menu={forecastTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            autoFocus={true}
            defaultValue=""
            placeholder="<Select>"
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
