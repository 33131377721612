import { isUndefined } from "lodash";
import { API_EXCEL } from "shared/constants";

export const formatCol = rowSelectionModel => {
  if (!rowSelectionModel.length) return [];

  const data = rowSelectionModel?.map(item => ({
    [API_EXCEL.ROW_NUMBER]: isUndefined(item?.rowNumber) ? "" : item?.rowNumber,
    [API_EXCEL.FIELD]: isUndefined(item?.field) ? "" : item?.field,
    [API_EXCEL.DISPLAY_NAME]: isUndefined(item?.displayName) ? "" : item?.displayName,
    [API_EXCEL.ORDER_DISPLAY]: isUndefined(item?.ordDisp) ? "" : item?.ordDisp,
    [API_EXCEL.PK]: isUndefined(item?.pk) ? "" : item?.pk,
    [API_EXCEL.DATA_TYPE]: isUndefined(item?.dataType) ? "" : item?.dataType,
    [API_EXCEL.CRITERIA]: isUndefined(item?.criteria) ? "" : item?.criteria,
    [API_EXCEL.LOGICAL_OPERATION]: isUndefined(item?.logOpr) ? "" : item?.logOpr,
    [API_EXCEL.DISPLAY_OPTION]: item?.dispOpt ? "1" : "0",
    [API_EXCEL.SORT]: isUndefined(item?.[API_EXCEL.SORT]) ? "" : item?.[API_EXCEL.SORT],
  }));

  return data;
};
export const formatColForCreateBookmark = (rows, rowSelectionModel) => {
  let data;
  if (!rowSelectionModel.length) {
    data = rows?.map(item => ({
      [API_EXCEL.ROW_NUMBER]: isUndefined(item?.rowNumber) ? "" : item?.rowNumber,
      [API_EXCEL.FIELD]: isUndefined(item?.field) ? "" : item?.field,
      [API_EXCEL.DISPLAY_NAME]: isUndefined(item?.displayName) ? "" : item?.displayName,
      [API_EXCEL.ORDER_DISPLAY]: isUndefined(item?.ordDisp) ? "" : item?.ordDisp,
      [API_EXCEL.PK]: isUndefined(item?.pk) ? "" : item?.pk,
      [API_EXCEL.DATA_TYPE]: isUndefined(item?.dataType) ? "" : item?.dataType,
      [API_EXCEL.CRITERIA]: isUndefined(item?.criteria) ? "" : item?.criteria,
      [API_EXCEL.LOGICAL_OPERATION]: isUndefined(item?.logOpr) ? "" : item?.logOpr,
      [API_EXCEL.DISPLAY_OPTION]: item?.dispOpt ? "1" : "0",
      [API_EXCEL.SORT]: isUndefined(item?.[API_EXCEL.SORT]) ? "" : item?.[API_EXCEL.SORT],
    }));
    return data;
  } else {
    let temp;
    data = [];
    rows.forEach(item => {
      temp = rowSelectionModel.find(selected => selected.rowNumber === item.rowNumber);
      if (temp) {
        temp = {
          [API_EXCEL.ROW_NUMBER]: isUndefined(temp?.rowNumber) ? "" : temp?.rowNumber,
          [API_EXCEL.FIELD]: isUndefined(temp?.field) ? "" : temp?.field,
          [API_EXCEL.DISPLAY_NAME]: isUndefined(temp?.displayName) ? "" : temp?.displayName,
          [API_EXCEL.ORDER_DISPLAY]: isUndefined(temp?.ordDisp) ? "" : temp?.ordDisp,
          [API_EXCEL.PK]: isUndefined(temp?.pk) ? "" : temp?.pk,
          [API_EXCEL.DATA_TYPE]: isUndefined(temp?.dataType) ? "" : temp?.dataType,
          [API_EXCEL.CRITERIA]: isUndefined(temp?.criteria) ? "" : temp?.criteria,
          [API_EXCEL.LOGICAL_OPERATION]: isUndefined(temp?.logOpr) ? "" : temp?.logOpr,
          [API_EXCEL.DISPLAY_OPTION]: temp?.dispOpt ? "1" : "0",
          [API_EXCEL.SORT]: isUndefined(temp?.[API_EXCEL.SORT]) ? "" : temp?.[API_EXCEL.SORT],
        };
      } else {
        temp = {
          [API_EXCEL.ROW_NUMBER]: isUndefined(item?.rowNumber) ? "" : item?.rowNumber,
          [API_EXCEL.FIELD]: isUndefined(item?.field) ? "" : item?.field,
          [API_EXCEL.DISPLAY_NAME]: isUndefined(item?.displayName) ? "" : item?.displayName,
          [API_EXCEL.ORDER_DISPLAY]: isUndefined(item?.ordDisp) ? "" : item?.ordDisp,
          [API_EXCEL.PK]: isUndefined(item?.pk) ? "" : item?.pk,
          [API_EXCEL.DATA_TYPE]: isUndefined(item?.dataType) ? "" : item?.dataType,
          [API_EXCEL.CRITERIA]: isUndefined(item?.criteria) ? "" : item?.criteria,
          [API_EXCEL.LOGICAL_OPERATION]: isUndefined(item?.logOpr) ? "" : item?.logOpr,
          [API_EXCEL.DISPLAY_OPTION]: item?.dispOpt ? "1" : "0",
          [API_EXCEL.SORT]: isUndefined(item?.[API_EXCEL.SORT]) ? "" : item?.[API_EXCEL.SORT],
        };
      }
      data.push(temp);
    });

    return data;
  }
};
