export const API_MONTHLY = {
  ROW_NUMBER: "rowNumber",
  FORECAST_TYPE: "forecastType",
  FORECAST_TYPE_CD: "forecastTypeCd",
  FORECAST_MONTH: "forecastMonth",
  DATA_TYPE: "dataType",
  DATA_TYPE_CD: "dataTypeCd",
  ORG_FILE_NAME: "orgFileName",
  UPLOAD_STATUS: "uploadStatus",
  UPLOAD_STATUS_CD: "uploadStatusCd",
  UPLOAD_DATE: "uploadDt",
  UPLOAD_BY: "uploadBy",
  USE_STATUS: "useSts",
  USE_DATE: "useDt",
  USE_BY: "useBy",
  FILE_UPLOAD_ID: "fileUploadId",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  FILE_NAME: "fileName",
  FILE: "file",
  API_ID: "aplId",
  MODE_CD: "modeCd",
  MODE: "mode",
  DATA_OWNER: "dataOwner",
  COMPANY_ABBR: "companyAbbr",
  UPLOAD_COMPANY_ABBR: "uploadCompanyAbbr",
  CREATE_BY: "createBy",
  MODULE_ID: "moduleId",
  FUNCTION_ID: "functionId",
  D_HODTCRE: "dHODTCRE",
  USER_ID: "userId",
};
