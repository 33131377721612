import { isEmpty, isNull } from "lodash";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import { findObject } from "utils";

const transformData = dataList => {
  const data =
    dataList.map((item, index) => ({
      no: index + 1,
      [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
      // [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: item?.rowNumber,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_CODE]: item?.expCd,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_COUNTRY]: item?.expCountryDesc,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_COUNTRY_CODE]: item?.expCountryCd,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_CODE]: item?.impCd,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY]: item?.impCompanyAbbr,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY_ID]: item?.impCompanyId,
      [API_INVOICE_SHIPPING_INFO.ETD_DATE]: item?.etd,
      [API_INVOICE_SHIPPING_INFO.CONSIGNEE]: item?.consigneeExpImpCd,
      [API_INVOICE_SHIPPING_INFO.CONSIGNEE_ID]: item?.consigneeCompanyId,
      [API_INVOICE_SHIPPING_INFO.LOADING_PORT]: item?.loadingPortDesc,
      [API_INVOICE_SHIPPING_INFO.DISCHARGING_PORT]: item?.dischargingPortDesc,
      [API_INVOICE_SHIPPING_INFO.VESSEL_NAME]: item?.vesselName,
      [API_INVOICE_SHIPPING_INFO.BROKER]: item?.brokerCompanyAbbr,
      [API_INVOICE_SHIPPING_INFO.BROKER_ID]: item?.brokerCompanyId,
      [API_INVOICE_SHIPPING_INFO.CAR_FAMILY_CODE]: item?.carFamilyCd,
      [API_INVOICE_SHIPPING_INFO.ORDER_TYPE]: item?.orderType,
      [API_INVOICE_SHIPPING_INFO.ORDER_TYPE_CODE]: item?.orderTypeCd,
      [API_INVOICE_SHIPPING_INFO.VANNING_STATUS]: item?.vanningStatus,
      [API_INVOICE_SHIPPING_INFO.AIR_SHIPMENT]: item?.airShipment,
      [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_H_ID]: item?.shippingInfoHId,
      [API_INVOICE_SHIPPING_INFO.UPDATE_DATE]: item?.updateDt,
      [API_INVOICE_SHIPPING_INFO.UPDATE_BY]: item?.updateBy,
    })) ?? [];
  return data;
};

const transformDataContainer = (containerList, orderTypeList) => {
  const data =
    containerList.map((item, index) => ({
      no: index + 1,
      [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
      planContainerNo: item?.planContainerNo,
      planRModuleQty: item?.planRModuleQty,
      planRBoxDunQty: item?.planRBoxDunQty,
      planRPkgDetail: "P",
      actualContainerNo: item?.actualContainerNo,
      actualRModuleQty: item?.actualRModuleQty,
      actualRBoxDunQty: item?.actualRBoxDunQty,
      renbanNo: item?.renbanNo,
      actualRPkgDetail: "A",
      actualPackingMonth: item?.packingMonth,
      actualVanningPLant: item?.vanningPlant,
      actualOrderType: !item?.orderType
        ? ""
        : findObject({ data: orderTypeList, value: item?.orderType, property: "cd", field: "value" }),
      status: item?.status,
      shippingInfoDContId: item?.shippingInfoDContId,
      vanningInfoHId: item?.vanningInfoHId,
      sessionId: item?.sesionnId,
    })) ?? [];
  return data;
};

const transformDataRPkg = (dataList, rPackageOwnerList) => {
  try {
    const data =
      dataList.map((item, index) => ({
        no: index + 1,
        [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
        rPkgOwner: item?.rPkgOwnerCompAbbr,
        rPkgOwnerId: findObject({
          data: rPackageOwnerList,
          value: item?.rPkgOwnerCompAbbr,
          property: "rPkgOwnerCompAbbr",
          field: "rPkgOwnerCompId",
        }),
        rPkgTypeId: item?.rPkgTypeId,
        rPkgType: item?.rPkgType,
        planQty: item?.qty,
        actQty: item?.actQty,
        shippingInfoDContId: item?.shppingInfoDContId,
        shippingInfoDPkgId: item?.shppingInfoDPkgId,
      })) ?? [];
    return data;
  } catch (error) {
    console.error(error);
  }
};
const formatRPkgForUpdateDate = rPackageList => {
  if (!rPackageList?.length) {
    return [];
  }

  const rPackage = rPackageList?.map(rPkg => ({
    rPkgOwnerCompAbbr: rPkg?.rPkgOwner,
    rPkgType: rPkg?.rPkgType,
    rPkgTypeId: rPkg?.rPkgTypeId,
    qty: rPkg?.planQty,
    actQty: rPkg?.planQty,
    shppingInfoDContId: rPkg?.shippingInfoDContId,
    shppingInfoDPkgId: rPkg?.shippingInfoDPkgId,
  }));
  return rPackage;
};
const formatContainerForUpdateData = containerList => {
  try {
    if (!containerList?.length) {
      return [];
    }
    let tempContainer;

    const newCont = containerList.filter(container => !isEmpty(container?.planContainerNo));
    const data = newCont.map((item, index) => {
      tempContainer = {
        shippingInfoDContId: item?.shippingInfoDContId, //option
        vanningInfoHId: item?.vanningInfoHId, //option
        planContainerNo: item?.planContainerNo,
        planRModuleQty: item?.planRModuleQty,
        planRBoxDunQty: item?.planRBoxDunQty,
        actualContainerNo: item?.actualContainerNo,
        actualRModuleQty: item?.actualRModuleQty,
        actualRBoxDunQty: item?.actualRBoxDunQty,
        renbanNo: item?.renbanNo, //option
        packingMonth: item?.actualPackingMonth, //option
        vanningPlant: item?.actualVanningPLant, //option
        orderType: item?.actualOrderType, //option
        status: item?.status,
        rPackage: formatRPkgForUpdateDate(item?.rPackage),
      };
      return tempContainer;
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
const transformContdUpdateTemp = containerList => {
  try {
    if (!containerList?.length) {
      return [];
    }
    let tempContainer;

    const newCont = containerList.filter(container => !isEmpty(container?.planContainerNo));
    const data = newCont.map((item, index) => {
      tempContainer = {
        sesionnId: "",
        shippingInfoDContId: item?.shippingInfoDContId, //option
        vanningInfoHId: item?.vanningInfoHId, //option
        planContainerNo: item?.planContainerNo,
        planRModuleQty: item?.planRModuleQty,
        planRBoxDunQty: item?.planRBoxDunQty,
        actualContainerNo: item?.actualContainerNo,
        actualRModuleQty: item?.actualRModuleQty,
        actualRBoxDunQty: item?.actualRBoxDunQty,
        renbanNo: item?.renbanNo, //option
        packingMonth: item?.actualPackingMonth, //option
        vanningPlant: item?.actualVanningPLant, //option
        orderType: item?.actualOrderType, //option
        status: item?.status,
      };
      return tempContainer;
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
const transformRPKgUpdateTemp = rPackageList => {
  if (!rPackageList?.length) {
    return [];
  }

  const rPackage = rPackageList?.map(rPkg => ({
    rPkgOwnerCompAbbr: rPkg?.rPkgOwner,
    rPkgType: rPkg?.rPkgType,
    rPkgTypeId: rPkg?.rPkgTypeId,
    qty: rPkg?.planQty,
    actQty: rPkg?.planQty,
    shppingInfoDContId: rPkg?.shippingInfoDContId,
    shppingInfoDPkgId: rPkg?.shippingInfoDPkgId,
    groupCd: "",
  }));
  return rPackage;
};
export {
  transformData,
  transformDataContainer,
  transformDataRPkg,
  formatContainerForUpdateData,
  transformContdUpdateTemp,
  transformRPKgUpdateTemp,
  // formatRPkgForUpdateDate,
};
