import React from "react";
import { LineChart } from "components/UI";
export default function WareHouseGraph(props) {
  const { subtitle, title, dataSet } = props;
  const data = React.useMemo(
    () => ({
      datasets: [
        {
          label: "Max",
          data: dataSet,
          borderColor: "#d32f2f",
          backgroundColor: "#d32f2f",
          parsing: {
            yAxisKey: "max",
            xAxisKey: "forecastDt",
          },
        },
        {
          label: "Min",
          data: dataSet,
          borderColor: "#43a047",
          backgroundColor: "#43a047",
          parsing: {
            yAxisKey: "min",
            xAxisKey: "forecastDt",
          },
        },
        {
          label: "Balance",
          data: dataSet,
          borderColor: "#000000",
          backgroundColor: "#000000",
          parsing: {
            yAxisKey: "endingBalance",
            xAxisKey: "forecastDt",
          },
        },
        {
          label: "Updated",
          data: dataSet,
          borderColor: "#2196f3",
          backgroundColor: "#2196f3",
          parsing: {
            yAxisKey: "newEndingBalance",
            xAxisKey: "forecastDt",
          },
        },
      ],
    }),
    [dataSet]
  );

  const config = React.useMemo(
    () => ({
      plugins: {
        animation: false,
        //Serial Name
        legend: {
          display: false,
        },
        // Title Name
        title: {
          display: true,
          text: title,
          align: "start",
          position: "top",
        },
        //SubTitle Name
        subtitle: {
          display: true,
          text: subtitle,
          align: "center",
          font: {
            size: 12,
            weight: "bolder",
          },
          padding: {
            bottom: 10,
          },
        },
      },
      options: {
        interaction: {
          // Overrides the global setting
          mode: "index",
        },
      },
    }),
    [subtitle, title]
  );
  return <LineChart id={`graph-line-${title}-${subtitle}`} options={config} data={data} />;
}
