const TEMPLATE_NAME_UPLOAD = {
  R_PKG_RCV: "R-Package Receiving Template",
  R_PKG_STS_CHG: "R-Package Status Change Template",
  R_PKG_DLV: "R-Package Delivery Template",
  CONT_STATUS: "Container Status Update Template",
};
const FILE_NAME_UPLOAD = {
  R_PKG_RCV: "R-Package Receiving Template.xlsx",
  R_PKG_STS_CHG: "R-Package Status Change Template.xlsx",
  R_PKG_DLV: "R-Package Delivery Template.xlsx",
  CONT_STATUS: "Container Status Update Template.xlsx",
};
export { TEMPLATE_NAME_UPLOAD, FILE_NAME_UPLOAD };
