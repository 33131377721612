import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return {hasError: true};
  }

  componentDidCatch() {
    // You can use your own error logging service here
  }

  render() {
    // Check if the error is thrown
    const {hasError} = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button type="button" onClick={() => this.setState({hasError: false})}>
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error
    const {children} = this.props;
    return children;
  }
}

export default ErrorBoundary;
