import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { ModeAction } from "state/enum";
export default function CriterialSection(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { exporterList, importerList, loadingPortList, dischargingPortList, orderTypeList, carFamilyCodeList },
  } = props;

  const handleChangeExporterCode = e => {
    setForm(prev => ({
      ...prev,
      exporterCd: e.target.value,
      // exporterId: e.target.value,
      // exporterCd: findObject({ data: exporterList, value: e.target.value, property: "companyId", field: "impExpCd" }),
      loadingPort: "",
    }));
  };
  const handleChangeImporterCompany = e => {
    setForm(prev => ({
      ...prev,
      importerId: e.target.value,
      dischargingPort: "",
    }));
  };
  useEffect(() => {
    document.getElementById("select-exporter-code").focus();
    return;
  }, []);
  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container item spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Exporter Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-exporter-code"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            autoFocus={true}
            value={form?.exporterCd}
            onChange={handleChangeExporterCode}
            menu={exporterList?.map(val => ({ key: val?.impExpCd, value: val?.impExpCd }))}
            // menu={exporterList?.map(val => ({ key: val?.companyId, value: val?.impExpCd }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Importer Company :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-importer-code"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            // value={form?.importerCd}
            value={form?.importerId}
            onChange={handleChangeImporterCompany}
            menu={importerList?.map(val => ({ key: val?.companyId, value: val?.companyAbbr }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container item spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Loading Port :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-loading-port"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.loadingPort}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                loadingPort: e.target.value,
              }));
            }}
            menu={loadingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Discharging Port :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-discharging-port"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.dischargingPort}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                dischargingPort: e.target.value,
              }));
            }}
            menu={dischargingPortList?.map(val => ({ key: val?.cd, value: val?.value }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container item spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>ETD :</strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="date-etd"
            fullWidth
            value={form?.etd}
            onChange={date => {
              setForm(prev => ({
                ...prev,
                etd: date,
              }));
            }}
            format={"DD/MM/YYYY"}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Vessel Name :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-vessel-name"
            fullWidth
            value={form?.vesselName}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                vesselName: e.target.value,
              }));
            }}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            maxLength={40}
            autoFocus={true}
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container item spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Order Type :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-order-type"
            defaultValue=""
            placeholder="<All>"
            value={form?.orderTypeId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                orderTypeId: e.target.value,
              }));
            }}
            menu={orderTypeList?.map(val => ({ key: val?.cd, value: val?.value }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Car Family Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-car-family-code"
            defaultValue=""
            placeholder="<All>"
            value={form?.carFamilyCode}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                carFamilyCode: e.target.value,
              }));
            }}
            menu={carFamilyCodeList?.map(val => ({ key: val?.cd, value: val?.value }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            autoFocus={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
