import { userProfile } from "constant";
import { API_SHIPMENT } from "shared/constants";

const OPERATION_VALUE = {
  GROUP: "Grouping",
  MAP: "Mapping",
};
//IMP_EXP_CD
const EXPORTER_VALUE = {
  SEVEN_22B: "722B",
  EIGHT_11B: "811B",
};

//COMPANY_ABBR
const IMPORTER_VALUE = {
  TMATH: "TMATH",
  TSAM: "TSAM",
};

const STATUS_SHIPMENT = {
  PLAN: "Plan",
  ACTUAL: "Actual",
  READY: "Ready",
  ALLOCATED: "Allocated",
  CONFIRMED: "Confirmed",
  RECEIVED: "Received",
};

const DEFAULT_FORM = {
  [API_SHIPMENT.DATA_OWNER]: userProfile.dataOwner,
  [API_SHIPMENT.COMPANY_ABBR]: userProfile.dataOwner,
  [API_SHIPMENT.USER_ID]: userProfile.userId,
  [API_SHIPMENT.OPERATION_ID]: "",
  [API_SHIPMENT.EXPORTER_ID]: "",
  [API_SHIPMENT.IMPORTER_ID]: "CMP_1208_000001",
  [API_SHIPMENT.R_RKG_OWNER_ID]: "",
  [API_SHIPMENT.FORECAST_MONTH]: "",
  [API_SHIPMENT.SHIPMENT_STATUS_ID]: "",
  [API_SHIPMENT.ETD_FORM]: "",
  [API_SHIPMENT.ETD_TO]: "",
  [API_SHIPMENT.ETA_FORM]: "",
  [API_SHIPMENT.ETA_TO]: "",
  [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
  [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
  [API_SHIPMENT.FILE_NAME]: {
    [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
    [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
    [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
  },
  [API_SHIPMENT.FILE]: {
    [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
    [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
    [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
  },
};
export { OPERATION_VALUE, EXPORTER_VALUE, IMPORTER_VALUE, STATUS_SHIPMENT, DEFAULT_FORM };
