import React from "react";
import { API_REPROCESS } from "shared/constants/api-name/common/reprocess";
import { COLUMN_REPROCESS } from "../constants/table.constant";
export default function useColumn({ rows, ...props }) {
  return [
    // Col 1
    {
      field: API_REPROCESS.ROW_NUMBER,
      headerName: COLUMN_REPROCESS.ROW_NUMBER,
      headerAlign: "center",
      align: "left",
      minWidth: 50,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 2
    {
      field: API_REPROCESS.BUSINESS_NAME,
      headerName: COLUMN_REPROCESS.BUSINESS_DATA,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 3
    {
      field: API_REPROCESS.PLANT,
      headerName: COLUMN_REPROCESS.PLANT,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 4
    {
      field: API_REPROCESS.BATCH_ROUND,
      headerName: COLUMN_REPROCESS.FILE_NAME,
      headerAlign: "center",
      align: "left",
      minWidth: 250,
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    // Col 5
    {
      field: API_REPROCESS.PROCESS_DATE,
      headerName: COLUMN_REPROCESS.PROCESS_DATE,
      headerAlign: "center",
      align: "left",
      minWidth: 200,
      flex: 0.1,
      sortable: false,
      editable: false,
    },
    // Col 5
    {
      field: API_REPROCESS.BARCODE_ID,
      editable: false,
    },
  ];
}
