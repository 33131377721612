import React, { Fragment } from "react";
import SingleLevel from "./SingleLevel";
import MultipleLevel from "./MultipleLevel";

export default function MenuItem({ item, depthStep, depth }) {
  const hasChildren = item => {
    const { subItems: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {hasChildren(item) ? (
        <MultipleLevel item={item} key={item.title} depth={depth} depthStep={depthStep} />
      ) : (
        <SingleLevel item={item} key={item.title} depthStep={depthStep} depth={depth} />
      )}
    </Fragment>
  );
}
