import { Box, styled } from "@mui/material";
import * as React from "react";
import Sidebar from "./SideBar/Sidebar";
import route from "components/Layout/SideBar/route.constant";
import { Footer, Navbar } from ".";
import { Outlet } from "react-router-dom";
import { MainContent } from "configs/auth";
const StyledBox = styled(Box)(() => ({
  marginTop: "4rem",
  marginLeft: "18rem",
  marginRight: "1rem",
  marginBottom: "10rem",
}));

const Main = ({ children }) => {
  return (
    <>
      <MainContent>
        <Navbar />
        <Sidebar items={route} />
        <StyledBox>
            {/* {children} */}
            <Outlet />
        </StyledBox>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <Footer />
        </Box>
      </MainContent>
    </>
  );
};

export default Main;
