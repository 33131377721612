import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { ModeAction } from "state/enum";
import {
  useExportInvoiceCategoryVanningPlantMutation,
  useExportInvoicePackingMonthMutation,
} from "shared/services/invoice";
import { userProfile } from "constant";

export default function CriterialSection(props) {
  const {
    mode,
    form,
    setForm,
    onOpen,
    dataList: {
      invoiceOrderTypeData,
      // ImpCompanyData,
      expData,
      // parkingMonthData,
      // vanningPlantData,
      brokerData,
      importerData,
    },
  } = props;

  let parkingMonthData = [];
  let vanningPlantData = [];

  const parkingMonthResult = useExportInvoicePackingMonthMutation();
  const vanningPlantResult = useExportInvoiceCategoryVanningPlantMutation();

  useEffect(() => {
    try {
      if (form?.etdDt && form?.impCompanyId && form?.expCd) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        parkingMonthData = parkingMonthResult({
          dataOwner: userProfile.dataOwner,
          impCompanyId: form?.impCompanyId,
          etdDt: form?.etdDt,
          expCd: form.expCd,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        vanningPlantData = vanningPlantResult({
          dataOwner: userProfile.dataOwner,
          impCompanyId: form?.impCompanyId,
          etdDt: form?.etdDt,
          expCd: form.expCd,
        });
      }

    } catch (error) {
      // console.log(error);
      parkingMonthData = [];
      vanningPlantData = [];
    }
  }, [form?.impCompanyId, form?.etdDt, form?.expCd]);

  const handleChangeInvoiceOrderType = (e) => {
    setForm((prev) => ({
      ...prev,
      invType: e.target.value,
    }));
  };

  const handleChangeImpCompanyId = (e) => {
    setForm((prev) => ({
      ...prev,
      impCompanyId: e.target.value,
    }));
  };

  const handleChangeExpCd = (e) => {
    setForm((prev) => ({
      ...prev,
      expCd: e.target.value,
    }));
  };

  const handleChangeParkingMonth = (e) => {
    setForm((prev) => ({
      ...prev,
      parkingMonth: e.target.value,
    }));
  };

  const handleChangeVanningPlant = (e) => {
    setForm((prev) => ({
      ...prev,
      vanningPlant: e.target.value,
    }));
  };

  const handleChangeBroker = (e) => {
    setForm((prev) => ({
      ...prev,
      expBrokerCompanyId: e.target.value,
    }));
  };

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>Invoice No. : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-invoice-no"
            fullWidth={true}
            maxLength={20}
            value={form?.invNo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                invNo: e.target.value,
              }))
            }
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Invoice Order Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-invoice-order-type"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.invType}
            onChange={handleChangeInvoiceOrderType}
            menu={invoiceOrderTypeData?.map((val) => ({
              key: val?.cd,
              value: val?.value,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*ETD : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-etd-dt"
            className="input-etd-dt"
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                etdDt: e,
              }))
            }
            value={form?.etdDt}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Vessel Name : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-vessel-name"
            fullWidth={true}
            maxLength={20}
            value={form?.vesselName}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={(e) =>
              setForm((old) => ({
                ...old,
                vesselName: e.target.value,
              }))
            }
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>*Importer Company :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-importer-company"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            value={form?.impCompanyId}
            onChange={handleChangeImpCompanyId}
            menu={importerData?.map((val) => ({
              key: val?.companyId,
              value: val?.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>*Exporter Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-exporter-company"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            value={form?.expCd}
            onChange={handleChangeExpCd}
            menu={expData?.map((val) => ({
              key: val?.exporterId,
              value: val?.exporterAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>Packing Month :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-packing-month"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.parkingMonth}
            onChange={handleChangeParkingMonth}
            menu={parkingMonthData?.map((val) => ({
              key: val?.parkingMonth,
              value: val?.parkingMonthLabel,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Vanning Plant :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-vanning-plant"
            fullWidth
            defaultValue=""
            placeholder="<All>"
            value={form?.vanningPlant}
            onChange={handleChangeVanningPlant}
            menu={vanningPlantData?.map((val) => ({
              key: val?.vanningPlant,
              value: val?.vanningPlant,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>*Broker :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-broker"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            value={form?.expBrokerCompanyId}
            onChange={handleChangeBroker}
            menu={brokerData?.map((val) => ({
              key: val?.companyId,
              value: val?.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={7} />
      </Grid>
    </Grid>
  );
}
