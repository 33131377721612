export const TEMPLATE_FILE_CODE = [
  //Monthly  Template file
  {
    cd: "BDN92080",
    value: "Delivery Plan Template",
    fileName: "Delivery_Plan_Template.xlsx",
  },
  //APMC Template file
  {
    cd: "BDN92090",
    value: "Production Plan",
    fileName: "Production_Plan_Blank_Template.xlsx",
  },
  {
    cd: "BDN920A0",
    value: "Packing Plan",
    fileName: "Packing_Plan_Blank_Template.xlsx",
  },
  {
    cd: "BDN920H0",
    value: "Return Shipping Plan",
    fileName: "Return_Shipping_Plan_Template.xlsx",
  },
  {
    cd: "BDN920J0",
    value: "Receiving Plan",
    fileName: "Receiving_Plan_Template.xlsx",
  },
  {
    cd: "BDN920I0",
    value: "Transferring Plan",
    fileName: "Transferring_Plan_Template.xlsx",
  },
  {
    cd: "BDN95050",
    value: "Actual Upload",
    fileName: "Import_Invoice_Excel_Template.xlsx",
  },
  {
    cd: "BDN92832",
    value: "R-Package Receiving Template",
    fileName: "R-Package Receiving Template.xlsx",
  },
  {
    cd: "BDN92834",
    value: "R-Package Delivery Template",
    fileName: "R-Package Delivery Template.xlsx",
  },
  {
    cd: "BDN92838",
    value: "R-Package Status Change Template",
    fileName: "R-Package Status Change Template.xlsx",
  },
  {
    cd: "BDN930L0",
    value: "Container Status Update Template",
    fileName: "Container Status Update Template.xlsx",
  },
];
