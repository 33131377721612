import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_STANDARD;

const SystemMasterApiService = () => ({
  // GET CATEGORY
  useSystemMasterCategoryQuery: () => {
    const data = useCustomQuery({
      key: "system-master-category",
      url: `${BaseServicePath}/category/system-master/category`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET SUB-CATEGORY
  useSystemMasterSubCategoryQuery: category => {
    const data = useCustomQuery({
      key: "system-master-sub-category",
      url: `${BaseServicePath}/category/system-master/sub-category/${category}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET STATUS
  useSystemMasterStatusQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/system-master/status`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET SEARCH
  useSystemMasterSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/system-master/search`,
        method: "POST",
        data: searchData,
      });
    };
  },
  //POST CREATE
  useSystemMasterCreateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["system-master-category"], ["system-master-sub-category"]],
    });
    return async createData => {
      return await mutate({
        url: `${BaseServicePath}/system-master/create`,
        data: createData,
        method: "POST",
      });
    };
  },
  //POST EDIT
  useSystemMasterUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["system-master-category"], ["system-master-sub-category"]],
    });
    return async (categoryId, updateData) => {
      return await mutate({
        url: `${BaseServicePath}/system-master/edit/${categoryId}`,
        data: updateData,
        method: "PATCH",
      });
    };
  },
  //DELETE SYSTEM MASTER DELETE
  useSystemMasterDeleteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [["system-master-category"], ["system-master-sub-category"]],
    });
    return async (categoryId, deleteData) => {
      return await mutate({
        url: `${BaseServicePath}/system-master/delete/${categoryId}`,
        data: deleteData,
        method: "DELETE",
      });
    };
  },
  //POST DOWNLOAD EXCEL TEMPLATE
  useSystemMasterDownloadTemplateMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/system-master/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}`);
          },
        }
      );
    };
  },
});

export const {
  useSystemMasterCategoryQuery,
  useSystemMasterSubCategoryQuery,
  useSystemMasterStatusQuery,
  useSystemMasterSearchMutation,
  useSystemMasterCreateMutation,
  useSystemMasterUpdateMutation,
  useSystemMasterDeleteMutation,
  useSystemMasterDownloadTemplateMutation,
} = SystemMasterApiService();
