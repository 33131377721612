import React from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
// util
import { responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// service
import { useUploadRPkgContUploadRPgMutation } from "shared/services/main-server";
import { MessageType } from "state/enum";
import { userProfile } from "constant";
import { BUTTON_VALUE } from "shared/constants";

export default function ButtonUploadRPkg({ form, setForm, setMsg: { setMsgError, setMsgAlert } }) {
  const uploadRPkg = useUploadRPkgContUploadRPgMutation();
  const handleUpload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const body = {
        userCompanyCd: userProfile.dataOwner,
        userId: userProfile.userId,
        file: form.file,
        fileName: form.fileName,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "fileName",
            type: MessageType.EMPTY,
            key: ["Excel File"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        setForm({ file: "", fileName: "" });
        window.scrollTo(0, 0);
        return;
      }
      const uploadData = new FormData();
      uploadData.append("file", body.file);
      uploadData.append("userId", body.userId);
      uploadData.append("userCompanyCd", body.userCompanyCd);
      const response = await uploadRPkg(uploadData);
      console.log(response);
      setForm({ file: "", fileName: "" });
      setMsgAlert([`${response?.message}`, `${response?.appId}`]);
      // setMsgAlert(["MDN90034AINF: Posting on demand batch is completed successfully."]);
      return true;
    } catch (error) {
      const msgError = responseErrors(error);
      setMsgError(msgError);
      return false;
    }
  };
  return (
    <Box>
      <InputButton id="button-upload-rPkg" value={BUTTON_VALUE.UPLOAD} onClick={handleUpload} />
    </Box>
  );
}
