import { Box, Grid } from "@mui/material";
import StyledCard from "../Layout/Card";
import React from "react";
import StyledTable from "./StyledTable";

function Dashboard() {
  return (
    <Box sx={{ display: "flex", rowGap: "2rem", flexDirection: "column" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard />
        </Grid>
      </Grid>
      <Grid container>
        <StyledTable />
      </Grid>
    </Box>
  );
}

export default Dashboard;
