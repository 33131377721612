import * as React from "react";
import Alert from "@mui/material/Alert";

const AlertMessage2 = ({
  type = "error",
  // isOpen = false,
  message = [],
}) => {
  if (!message || typeof message !== 'object' || !message.length) return null;

  return (
    <div>
      {message?.map((val, index) => (
        <Alert key={index} severity={type}>
          {val}
        </Alert>
      ))}
    </div>
  );
};

// AlertMessage.Error = function AlertError({ text, isOpen = false }) {
//   if (!isOpen) return null;
//   return <Alert severity="error">{text}</Alert>;
// };
// AlertMessage.Warning = function AlertWarning({ text, isOpen = false }) {
//   if (!isOpen) return null;
//   return <Alert severity="warning">{text}</Alert>;
// };
// AlertMessage.Info = function AlertInfo({ text, isOpen = false }) {
//   if (!isOpen) return null;
//   return <Alert severity="info">{text}</Alert>;
// };
// AlertMessage.Success = function AlertSuccess({ text, isOpen = false }) {
//   if (!isOpen) return null;
//   return <Alert severity="success">{text}</Alert>;
// };

export default AlertMessage2;
