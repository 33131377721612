import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_AGING_STOCK_USAGE } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnDemandMutation,
  useReportCommonGetLocationNameListQuery,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportGetAvailableRPackageStatusQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios, { HttpStatusCode } from "axios";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";

export default function AgingStockAndUsageRawDataReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_AGING_STOCK_USAGE);
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });
  const { data: locationFromToList, failureReason: locationFromToFailureReason } =
    useReportCommonGetLocationNameListQuery({
      dataOwner: userProfile.dataOwner,
    });
  const { data: currentStatusList, failureReason: currentStatusFailureReason } =
    useReportGetAvailableRPackageStatusQuery();

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    if (locationFromToFailureReason?.response?.status >= 404) {
      message.push(`Location From - To List: ${ResponseText[locationFromToFailureReason?.response?.status]}`);
    }
    if (currentStatusFailureReason?.response?.status >= 404) {
      message.push(`Current Status List: ${ResponseText[currentStatusFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    locationFromToFailureReason?.response?.status,
    currentStatusFailureReason?.response?.status,
    props,
  ]);

  const generateReport = useExcelDownloadOnDemandMutation();

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    props.setMsgError([]);
    props.setMsgAlert([]);
    setDisabledSubmitBtn(true);
    try {
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: "TMATH",
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        locationName: form?.currentLocation,
        currentStatus: form?.currentStatus,
        deadStockPeriodFrom: form?.deadStockPeriodFrom,
        deadStockPeriodTo: form?.deadStockPeriodTo,
        numberOfUsageFrom: form?.usageTimesFrom,
        numberOfUsageTo: form?.usageTimesTo,
      };
      const pathReport = "generate-dead-stock-usage-raw-data-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      props.setMsgAlert([response?.message]);
      setDisabledSubmitBtn(false);

      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseErrors(error);
        props.setMsgError(msgError);
      }
      stopLoading();
      setDisabledSubmitBtn(false);
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Aging Stock And Usage Raw Data Report" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val.rPkgType,
              value: val.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form.rPkgTypeOwner?.length}
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>Current Location : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-current-location"
            fullWidth
            value={form?.currentLocation}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val.cd,
              value: val.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                currentLocation: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Current Status : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-current-status"
            fullWidth
            value={form?.currentStatus}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={currentStatusList?.map(val => ({
              key: val.value,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                currentStatus: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>Dead Stock Period (days) From : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-dead-stock-period-from"
            fullWidth={true}
            maxLength={3}
            value={form?.deadStockPeriodFrom}
            // regularExp={/^0-9_*-]*$/}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  deadStockPeriodFrom: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Dead Stock Period (days) To : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-dead-stock-period-to"
            fullWidth={true}
            maxLength={3}
            value={form?.deadStockPeriodTo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  deadStockPeriodTo: e.target.value,
                }));
            }}
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Usage times From : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-usage-times-from"
            fullWidth={true}
            maxLength={3}
            value={form?.usageTimesFrom}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  usageTimesFrom: e.target.value,
                }));
            }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Usage times To : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-usage-times-to"
            fullWidth={true}
            maxLength={3}
            value={form?.usageTimesTo}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e => {
              let regex = /[0-9]+$/;
              if (regex.test(e.target.value) || e.target.value === "")
                setForm(old => ({
                  ...old,
                  usageTimesTo: e.target.value,
                }));
            }}
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_AGING_STOCK_USAGE}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
      />
    </Grid>
  );
}
