import { useMutateData, useCustomQuery } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportAverageLeadTimeApiService = () => ({
  useReportCommonGetReportBasisQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-report-basis`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetLocationTypeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-type`,
      method: "POST",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetLocationGroupQuery: ({ dataOwner, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-location-group-by-location-type`,
      method: "POST",
      enabled: !!locationType,
      select: data => data.result,
      keepPreviousData: true,
      body: {
        dataOwner: "TMATH",
        locationType: locationType,
      },
    });
    return data;
  },
  useReportCommonGetOperationQuery: ({ dataOwner, locationGroup, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-operation-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetRoutePointQuery: ({ dataOwner, locationGroup, operationDesc, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-route-point-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, operationDesc, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetRPackageStatusFromQuery: ({ dataOwner, locationGroup, operationDesc, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-r-package-status-from-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, operationDesc, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetRPackageStatusToQuery: ({ dataOwner, locationGroup, operationDesc, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-r-package-status-to-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, operationDesc, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetPackStatusFromQuery: ({ dataOwner, locationGroup, operationDesc, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-pack-status-from-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, operationDesc, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportCommonGetPackStatusToQuery: ({ dataOwner, locationGroup, operationDesc, locationType }) => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-pack-status-to-by-location-group`,
      method: "POST",
      body: { dataOwner, locationGroup, operationDesc, locationType },
      enabled: !!locationGroup, // location group from GetLocationByLocationType
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useReportCommonGetReportBasisQuery,
  useReportCommonGetLocationTypeQuery,
  useReportCommonGetLocationGroupQuery,
  useReportCommonGetOperationQuery,
  useReportCommonGetRoutePointQuery,
  useReportCommonGetRPackageStatusFromQuery,
  useReportCommonGetRPackageStatusToQuery,
  useReportCommonGetPackStatusFromQuery,
  useReportCommonGetPackStatusToQuery,
} = ReportAverageLeadTimeApiService();
