import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, callMsGraph } from "configs/auth";

export function Profile({ children }) {
  const authRequest = {
    ...loginRequest,
  };
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then(response => {
          console.log("response", response);
          setGraphData(response);
          // window.location.href = `https://myapplications.microsoft.com/@onetoyota.onmicrosoft.com`;
        })
        .catch(e => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              scopes: [process.env.REACT_APP_SCOPES],
              account: instance.getActiveAccount(),
            });
          }
        });
    }

    console.log("graphData", graphData);
    // if(graphData){
    //     window.location.href = 'https://myapplications.microsoft.com?tenant=f6cb9261-8a3a-4261-979f-50eeb15b4984&userid=${graphData?.id}';
    // }
  }, [inProgress, graphData, instance]);

  console.log("authRequest", authRequest);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      // errorComponent={ErrorComponent}
      // loadingComponent={Loading}
    >
      <Paper>{children}</Paper>;
    </MsalAuthenticationTemplate>
  );
}
