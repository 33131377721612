import apiClient from "./api-client";
import fileSaver from "file-saver";
//import {saveAs} from './utils/download-file'
// export const apiClientTypeBlob = async ({ url, option, filename = "dowloadedFile.xlsx" }) => {
//   try {
//     const response = await apiClient.post(
//       url,
//       { ...option },
//       { responseType: "blob" } //
//     );

//     if (response.status === 200 && response.data) {
//       const blob = new Blob([response.data]);

//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.style.display = "none";
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);

//       a.click();

//       window.URL.revokeObjectURL(url);
//     } else {
//       console.log("Surasith");
//       console.log(response.data);
//       console.error("Failed to download file");
//     }
//   } catch (error) {
//     // if (error.response && error.response.status === 404) {
//     //   console.error("File not found. HTTP status code:", error.response.status);
//     // } else {
//     //   console.error("Error downloading file:", error);
//     // }

//     console.warn("Surasith");
//     throw error;
//   }
// };

//response must include header and data
export const saveAs = response => {
  console.log("SaveAszzzz");
  console.log(response);

  if (!response?.data) {
    console.error("No Download file (response must include header and data)");
    return;
  }

  const { headers, data } = response;
  console.log(headers);
  console.log(data);
  console.log(headers["content-type"]);
  console.log(headers["content-disposition"]);
  if (!headers["content-disposition"]) {
    console.error("NOT INITIAL FILENAME PLEASE CONTACT DEV TEAM");
  }

  const fileName = headers["content-disposition"].match(/filename=(.+)/)?.at(1);
  const blob = new Blob([data], { type: `${headers["content-type"]}` });
  fileSaver.saveAs(blob, fileName);
};
