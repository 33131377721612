import { API_WAREHOUSE_SUMMARY } from "shared/constants";
import { TanStackDatePickerField, TanStackDropDown } from "components/UI";
import { ModeAction } from "state/enum";

const INDEX_OF_ALLOCATE_DATE = 7;
export default function useColumnTanStack({ mode, rPkg, wareHouseList, rowSelectionModel }) {
  try {
    const column = [
      // Col 1
      {
        header: "No.",
        accessorKey: "rowNumber",
        headerAlign: "center",
        align: "right",
        size: 20,
      },
      // Col 2
      {
        header: "Shipment Code",
        accessorKey: API_WAREHOUSE_SUMMARY.SHIPMENT_CODE,
        headerAlign: "center",
        align: "left",
        size: 100,
      },
      // Col 3
      {
        header: "Container No.",
        accessorKey: API_WAREHOUSE_SUMMARY.CONTAINER_NO,
        headerAlign: "center",
        align: "left",
        size: 100,
      },
      // Col 4
      {
        header: "Status",
        accessorKey: API_WAREHOUSE_SUMMARY.ALLOCATE_STATUS,
        headerAlign: "center",
        align: "center",
        size: 100,
      },
      // Col 5
      {
        header: "Ready For Date (DD/MM/YYYY)",
        accessorKey: "readyForDt",
        headerAlign: "center",
        align: "center",
        size: 100,
      },
      // Col 6
      {
        header: "W/H",
        accessorKey: "plantId",
        headerAlign: "center",
        align: "left",
        size: 200,
        cell: props => {
          if (mode === ModeAction.EDIT) {
            return (
              <TanStackDropDown
                value={props?.row?.original?.plantId}
                menu={wareHouseList?.map(val => ({
                  key: val.plantId,
                  value: `${val.companyAbbr}:${val.impExpCd}`,
                }))}
                defaultValue=""
                placeholder="-- NONE --"
                // onChange={e => handleDisable(props, e.target.value)}
                {...props}
              />
            );
          }
          return props?.row?.original.wareHouseName;
        },
      },
      // Col 7
      {
        header: "Allocation Date (DD/MM/YYYY)",
        accessorKey: "allocateDt",
        headerAlign: "center",
        align: "center",
        size: 250,
        cell: props => {
          const row = props.row.original;
          if (mode === ModeAction.EDIT) {
            // console.log(rowSelectionModel);
            return (
              <TanStackDatePickerField
                value={row?.allocateDt}
                // disabled={!row?.plantId ? true : false}
                // disabled={handleDisable(props, row?.plantId)}
                disablePast={true}
                disableKey={true}
                {...props}
              />
            );
          }
          return props?.row?.original.allocateDt;
        },
      },
      // Col 8
      {
        accessorKey: "wareHouseName",
        enableHiding: true,
      },
      // Col 8
      {
        accessorKey: "whCompanyAbbrId",
        enableHiding: true,
      },
      // Col 8
      {
        accessorKey: "whCompanyAbbr",
        enableHiding: true,
      },
    ];
    let newCol = [];
    if (!rPkg) {
      console.log("R-Package Col not found");
      return column;
    }
    newCol = rPkg.map((val, index) => ({
      header: val,
      accessorKey: val,
      headerAlign: "center",
      align: "right",
      size: 50,
    }));
    column.splice(INDEX_OF_ALLOCATE_DATE, 0, ...newCol);
    return column;
  } catch (error) {
    console.error(error);
  }
}
