import React, { useEffect, useState } from "react";
//Component
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
// Hooks
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { messageDisplay, responseErrors } from "utils";
//Type
import { BUTTON_VALUE } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { useButtonModeRPkg } from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/useButtonModeRPkg";

export default function ButtonRPkg({
  mode,
  setMode,
  modeOfMainScreen,
  setOnSearch,
  rows,
  setRows,
  columns,
  form,
  setForm,
  modeOfWDN95031,
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, setPagination, pageNumber, setPageNumber },
  rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
}) {
  const confirmDialogCtx = useConfirmDialogContext();
  //Button State
  const [addBtn, setAddBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const handleAdd = () => {
    try {
      let maxNo = -1;
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      const field = {};
      columns.forEach(column => {
        if (column.field === "rowNumber") {
          maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
          field[column.field] = rows.length ? maxNo + 1 : maxNo;
        } else {
          field[column.field] = "";
        }
      });

      setRows(prev => [
        ...prev,
        {
          ...field,
          isNew: true,
        },
      ]);

      const maxNoModel = rows.length ? maxNo + 1 : maxNo;

      setRowModesModel(oldModel => ({
        ...oldModel,
        [maxNoModel]: { mode: GridRowModes.Edit },
      }));

      setRowSelectionModel([maxNoModel]);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const handleEdit = () => {
    try {
      setMsgError([]);
      setMsgAlert([]);

      setMode(ModeAction.EDIT);
      let tempRowModesModel = rowModesModel;
      const rowSelect = rowSelectionModel.map(val => {
        return (tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.Edit },
        });
      });
      if (rowSelect) {
        setRowModesModel(tempRowModesModel);
      }
      return;
    } catch (error) {
      console.error(error);
      // const errors = responseErrors(error);
      // setMsgError(errors);
      return;
    }
  };
  const handleDelete = async () => {
    try {
      const ONE_RECORD = 1;
      const confirm = await confirmDialogCtx.success({
        type: "SHIPPING_INFO_CONFIRM_DELETE",
      });
      if (!confirm) {
        return;
      }

      if (rows?.length === ONE_RECORD) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setRows([]);

        // updated r-package to container state
        let containerList;
        containerList = form?.rows?.map(rowCont => {
          let containerNo = form?.containerType === "P" ? rowCont?.planContainerNo : rowCont?.actualContainerNo;
          if (containerNo === form?.containerNo) {
            let temp = { ...rowCont, rPackage: [] };
            return temp;
          }
          return rowCont;
        });
        console.log(containerList);
        setForm(prev => ({ ...prev, rows: containerList }));
      } else {
        const selectedNo = rowSelectionModel[0];
        const newRows = rows
          .filter(row => row.rowNumber !== selectedNo)
          .map(el => (el.rowNumber > selectedNo ? { ...el, no: el.no - 1, rowNumber: el.rowNumber - 1 } : el));
        setRows(newRows);
        setRowModesModel({});
        setRowSelectionModel([]);
        setMode(ModeAction.VIEW);

        let containerList;
        containerList = form?.rows?.map(rowCont => {
          let containerNo = form?.containerType === "P" ? rowCont?.planContainerNo : rowCont?.actualContainerNo;
          if (containerNo === form?.containerNo) {
            let temp = { ...rowCont, rPackage: newRows };
            return temp;
          }
          return rowCont;
        });
        console.log(containerList);
        setForm(prev => ({ ...prev, rows: containerList }));
      }

      const msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90044AINF",
        msg: [],
      });

      setMsgAlert([msg]);
      return true;
    } catch (error) {
      console.error(error);
      // const errors = responseErrors(error);
      // setMsgError(errors);
      return;
    }
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonModeRPkg({
      mode,
      rows,
      stateButton: { setAddBtn, setEditBtn, setDeleteBtn },
      modeOfWDN95031,
      containerType: form?.containerType,
    });
    return;
  }, [mode, rows, modeOfWDN95031]);
  return (
    <React.Fragment>
      <InputButton id="btn_add" value={BUTTON_VALUE.ADD} disabled={addBtn} onClick={handleAdd} />
      <InputButton id="btn_edit" value={BUTTON_VALUE.EDIT} disabled={editBtn} onClick={handleEdit} />
      <InputButton id="btn_delete" value={BUTTON_VALUE.DELETE} disabled={deleteBtn} onClick={handleDelete} />
    </React.Fragment>
  );
}
