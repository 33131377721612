import { userProfile } from "constant";

const DEFAULT_WAREHOUSE_SUMMARY = {
  rPackageOwnerId: "",
  rPackageTypeId: "",
  wareHouseId: "",
  dataOwner: userProfile.dataOwner,
  userId: userProfile.userId,
};

export { DEFAULT_WAREHOUSE_SUMMARY };
