import React, { Fragment, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import HeaderSection from "./components/HeaderSection";
import ButtonUploadRPkg from "./components/ButtonUploadRPkg";
import HeaderSectionContainer from "./components/HeaderSectionContainer";
import ButtonUploadContainer from "./components/ButtonUploadContainer";

export default function UploadingRPackageReceiveDeliverUpdateStatusScreen() {
  const [form, setForm] = useState({ file: {}, fileName: "" });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN97052 : Uploading R-Package Receive / Deliver / Update Status Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <HeaderSection form={form} setForm={setForm} setMsg={{ setMsgError, setMsgAlert }} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ButtonUploadRPkg form={form} setForm={setForm} setMsg={{ setMsgError, setMsgAlert }} />
          </Grid>
          <Grid item xs={12} mt={1} mb={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <HeaderSectionContainer form={form} setForm={setForm} setMsg={{ setMsgError, setMsgAlert }} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ButtonUploadContainer form={form} setForm={setForm} setMsg={{ setMsgError, setMsgAlert }} />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
