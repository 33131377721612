import { useEffect } from "react";
import { ModeAction } from "state/enum";
export default function useButtonMode(props) {
  const { mode, setSearchBtn, setClearBtn, setAddBtn, setEditBtn, setDeleteBtn, setDownloadBtn } = props;

  switch (mode) {
    case ModeAction.VIEW:
      setSearchBtn(false);
      setClearBtn(false);

      setAddBtn(false);
      setEditBtn(true);
      setDeleteBtn(true);
      setDownloadBtn(false);
      break;

    case ModeAction.ADD:
      setSearchBtn(true);
      setClearBtn(true);

      setAddBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      setDownloadBtn(true);
      break;

    case ModeAction.EDIT:
      setSearchBtn(true);
      setClearBtn(true);

      setAddBtn(true);
      setEditBtn(true);
      setDeleteBtn(true);
      setDownloadBtn(true);
      break;

    case ModeAction.SELECTED:
      setSearchBtn(false);
      setClearBtn(false);

      setAddBtn(false);
      setEditBtn(false);
      setDeleteBtn(false);
      setDownloadBtn(false);
      break;

    default:
      break;
  }
}
