import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import { InputButton, InputDropDown, InputText } from "components/UI";
import { useGetPrintAs, useRePrint } from "service/barcodeprint";
import { userProfile } from "constant";
import { responseDownloadFileErrors } from "utils";
import InputCurrency from "components/UI/InputCurrency";
import { useConfirmDialogContext } from "context/confirmDialogContext";
const RePrintMode = ({ mode, setMsgAlert, setMsgError }) => {
  const [form, setForm] = React.useState({
    mode: mode,
    printAs: "",
    barcodeId: "",
    numberOfCopy: 1,
  });

  const [disabledPrintBarcodeBtn, setDisabledPrintBarcodeBtn] = useState(false);
  // api
  const { data: printAsData } = useGetPrintAs({
    dataOwner: userProfile.dataOwner,
  });
  const confirmDialogCtx = useConfirmDialogContext();
  const createDataAsync = useRePrint();

  // handle print
  const handlePrint = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const saveConfirm = await confirmDialogCtx.success({
        type: "confirmPrint",
      });
      if (!saveConfirm) {
        return;
      }
      const body = {
        dataOwner: userProfile.dataOwner,
        printType: form?.mode,
        printAs: form.printAs,
        // numOfCopy: form.numberOfCopy,
        numOfCopy: form.numberOfCopy.toString(),
        barcodeId: form.barcodeId,
        //TODO fixed printLocation: "TMATH-EM",
        printLocation: "TMATH-EM",
        printBy: userProfile.userId,
      };

      await createDataAsync(body);
      setDisabledPrintBarcodeBtn(true);
      return;
    } catch (error) {
      console.log(error);
      const errors = responseDownloadFileErrors(error);
      setMsgError(errors);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm({
      mode: mode,
      printAs: "",
      barcodeId: "",
      numberOfCopy: 1,
    });
    setDisabledPrintBarcodeBtn(false);
  };
  return (
    <>
      <Grid item xs={2}>
        <strong>*Print As : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputDropDown
          id="select_printAs"
          value={form.printAs}
          onChange={e =>
            setForm(old => ({
              ...old,
              printAs: e.target.value,
            }))
          }
          memu={printAsData?.map(val => ({
            key: val.companyCd,
            value: val.impExpCd,
          }))}
          placeholder="<Select>"
          defaultValue=""
          required
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <strong>*R-Module Barcode ID : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputText
          fullWidth
          id="input_barcodeId"
          value={form.barcodeId}
          onChange={e =>
            setForm(old => ({
              ...old,
              barcodeId: e.target.value.match(/^[a-zA-Z0-9]*$/) ? e.target.value.toUpperCase() : old.barcodeId,
            }))
          }
          required
          maxLength={14}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <strong>*Number of Copy : </strong>
      </Grid>
      <Grid item xs={3}>
        <InputCurrency
          fullWidth
          id="input_numOfCopy"
          value={form?.numberOfCopy}
          onChange={e =>
            setForm(old => ({
              ...old,
              numberOfCopy: e.value,
            }))
          }
          maxLimit={10}
          required
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop="24px">
        <InputButton value="Print Barcode" onClick={handlePrint} disabled={disabledPrintBarcodeBtn} />
        <InputButton value="Clear" onClick={handleClear} />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
      </Grid>
    </>
  );
};

export default RePrintMode;
