import React from "react";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInput-root": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: ".3rem",
  },
  "& .MuiInput-root.Mui-focused": {
    border: `1.5px solid black !important`,
    borderRadius: ".3rem",
  },
}));

export default function InputTextField({
  fullWidth = false,
  id,
  placeholder = "",
  value,
  onChange = undefined,
  required = false,
  style = {},
  type = "text",
  disabled,
  maxLength,
  autoFocus = false,
  regularExp,
  multiline = false,
  ...props
}) {
  const handleChange = event => {
    const newValue = event.target.value;

    if (regularExp && !regularExp?.test(newValue)) {
      // event.preventDefault();
      return;
    }
    onChange(event);
    // }
  };
  return (
    <StyledTextField
      {...props}
      data-testid={id}
      id={id}
      margin="dense"
      size="small"
      variant="standard"
      inputProps={{
        maxLength: maxLength,
      }}
      InputProps={{
        sx: {
          padding: "5px 5px",
          minHeight: "32px",
          height: "auto",
          fontSize: 14,
          ...style,
        },
        disableUnderline: true,
        required: required,
      }}
      multiline={multiline}
      autoFocus={autoFocus}
      type={type}
      fullWidth={fullWidth}
      placeholder={placeholder}
      // onKeyDown={event => ["e", "E", "+", "-"].includes(event.key) && event.preventDefault()}
      // error={!!error}
      // helperText={error}
      value={value}
      onChange={handleChange}
      required={required}
      disabled={disabled}
    />
  );
}
