export const COLUMN_NAME = {
  NO: "No.",
  FORECAST_MONTH: "Forecast Month",
  VANNING_MONTH: "Vanning Month",
  PLANT_CODE: "Plant Code",
  EXPORTER: "Exporter",
  IMPORTER: "Importer",
  SHIPMENT_CODE: "Shipment Code",
  SHIPMENT_ID: "Shipment ID",
  CARRIER: "Carrier",
  VANNING_PLANT: "Vanning Plant",
  VANNING_FROM: "From",
  VANNING_TO: "To",
  PLAN: "Plan",
  ACTUAL: "Actual",
  INV_NO: "Invoice No.",
  PLAN_ACTUAL: "Plan / Actual",
  ETD: "ETD",
  ETA: "ETA",
  R_PACK_QTY: "R-Package Qty",
  ACTUAL_FILE: "Actual File",
  UPLOAD_FILE: "Upload File",
  NVC_FILE: "NCV File",
  BL_FILE: "B/L File",
  SHIPMENT_SUMMARY_H_ID: "Shipment Summary Header ID",
  SHIPMENT_SUM_UPDATE_DATE: "Shipment Summary Update Date",
  SHIPMENT_CANCEL_FLAG: "Shipment Cancel Flag",
  SHIPMENT_STATUS: "Shipment Status",
};
// ETD: Estimated-Time-Departure
// ETA: Estimated-Time-Arrival
//EX

// const data = [
//   {
//     shipmentId: "SSH_2307_000187",
//     plan: [
//       {
//         key: "1HT",
//         qty: "144",
//       },
//     ],
//     actual: [
//       {
//         key: "1HT",
//         qty: "144",
//       },
//     ],
//   },
// ];
