import useMutation from "hooks/useMutation";
import _ from "lodash";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useSearchSafetyStockandOverflow = params => {
  return useMutation({
    url: "/safety-overflow-stock/search",
    method: "POST",
    data: {
      ...params,
    },
  });
};

export const useCreateSafetyStockandOverflow = ({ type }) => {
  return useMutation({
    url: `/safety-overflow-stock/${type}/create`,
    method: "POST",
  });
};

export const useUpdateSafetyStockandOverflow = ({ type, id }) => {
  return useMutation({
    url: `/safety-overflow-stock/${type}/edit/${id}`,
    method: "PATCH",
  });
};

export const useDeleteSafetyStockandOverflow = ({ type, id }) => {
  return useMutation({
    url: `/safety-overflow-stock/${type}/delete/${id}`,
    method: "DELETE",
    key: [id],
    enabled: !_.isEmpty(id),
  });
};

export const useDownloadExcel = (type, params) => {
  return useMutation({
    url: `/safety-overflow-stock/${type}/download/excel`,
    method: "POST",
    data: { ...params },
  });
};
export const useSafetyStockDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body, masterTypeCd) => {
    return await mutate(
      {
        url: `${BaseServicePath}/safety-overflow-stock/${masterTypeCd === "S" ? "safety" : "overflow"}/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
