import { userProfile } from "constant";
import { API_NAME_ON_DEMAND_EXCEL } from "shared/constants";
import { getLocalDateStr } from "utils/init-config-date";

export const REPORT_STATUS = {
  ON_QUEUE: "On Queue",
  PROCESS: "Processing",
  DOWNLOAD: "Download",
  CANCELED_USER: "Canceled (User)",
  CANCELED_SYSTEM: "Canceled (Time Limit)",
  DELETE: "Deleted",
  ERROR_OCCUR: "Error Occurred",
  NO_DATA: "No data found",
};

export const DEFAULT_FORM = {
  [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: getLocalDateStr(),
  [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: "",
  [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: "",
  [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: userProfile?.dataOwner,
  [API_NAME_ON_DEMAND_EXCEL.USER_ID]: userProfile.userId,
  [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: false,
  [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: "",
};
