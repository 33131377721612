import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_STOCK_SUMMARY } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useExcelDownloadOnlineMutation,
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";

export default function StockSummaryReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_STOCK_SUMMARY);
  const reportTypeList = [
    {
      cd: "L",
      value: "Location",
    },
    {
      cd: "R",
      value: "R-Package Type",
    },
  ];
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }

    props.setMsgServerErrorAlert(message);
  }, [rPkgOwnerFailureReason?.response?.status, props]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: "TMATH",
        rPackageOwner: form?.rPkgTypeOwner,
        reportType: form?.reportType,
      };
      const pathReport = "generate-stock-summary-by-status-report";
      // const fileName = `Stock_Summary_By_Status_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      setDisabledSubmitBtn(false);

      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("select-rpackage-owner").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "1", value: "Stock Summary Report by status" }]}
            value={form?.reportId}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            required
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val.rPkgOwnerCompAbbr,
              value: val.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={2}>
          <strong>Report Type :</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report-type"
            fullWidth
            value={form?.reportType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={reportTypeList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                reportType: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_STOCK_SUMMARY}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
      />
    </Grid>
  );
}
