import React from "react";
// Components
import { Grid } from "@mui/material";
import { DropDown } from "components/UI";
import { API_LOG_MONITORING } from "shared/constants";

export default function CriterialBar(props) {
  const {
    searchForm,
    setSearchForm,
    datalist: { moduleData, functionData, logStatusData },
  } = props;
  return (
    <React.Fragment>
      <Grid item flex="1">
        <strong>Module : </strong>
      </Grid>
      <Grid item flex="3">
        <DropDown
          id="select_moduleId"
          value={searchForm?.moduleId}
          onChange={e =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.MODULE_ID]: e.target.value,
            }))
          }
          menu={moduleData?.map(val => ({
            key: val.moduleId,
            value: val.moduleName,
          }))}
          autoFocus={true}
          defaultValue=""
          placeholder="<All>"
        />
      </Grid>
      <Grid item flex="1">
        <strong>Function : </strong>
      </Grid>
      <Grid item flex="3">
        <DropDown
          id="select_function"
          value={searchForm?.functionId}
          onChange={e =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.FUNCTION_ID]: e.target.value,
            }))
          }
          menu={functionData?.map(val => ({
            key: val.funtionId,
            value: val.funtionName,
          }))}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
      <Grid item />
      <Grid item flex="1">
        <strong>Status : </strong>
      </Grid>
      <Grid item flex="2">
        <DropDown
          id="select_status"
          value={searchForm.statusId}
          onChange={e =>
            setSearchForm(prev => ({
              ...prev,
              [API_LOG_MONITORING.STATUS_ID]: e.target.value,
            }))
          }
          menu={logStatusData?.map(val => ({
            key: val.cd,
            value: val.value,
          }))}
          placeholder="<All>"
          defaultValue=""
        />
      </Grid>
    </React.Fragment>
  );
}
