export const DEFAULT_LOCAL_STATE = {
  dataOwner: "",
  moduleId: "",
  functionId: "",
  statusId: "",
  userId: "",
  appId: "",
  dateFrom: null,
  dateTo: null,
  levelId: "",
};
export const DEFAULT_LOCAL_STATE_SUMMARY = {
  dataOwner: "",
  moduleId: "",
  functionId: "",
  statusId: "",
  userId: "",
  appId: "",
  dateFrom: null,
  dateTo: null,
};

export const DEFAULT_LOCAL_STATE_WDN95030 = {
  exporterComp: "",
  importerComp: "",
  etdDt: "",
  consignee: "",
  loadingPort: "",
  dischargingPort: "",
  broker: "",
  vesselName: "",
  //search
  exporterCd: "",
  importerCd: "",
  importerId: "",
  shippingHdrID: "",
};

const ROW_PER_PAGE = 10;
const FIRST_PAGE = 1;
export { ROW_PER_PAGE, FIRST_PAGE };
