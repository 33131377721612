import { Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";
import { InputDropDown, InputText } from "components/UI";
import React, { Fragment } from "react";
import { ModeAction } from "state/enum";

export default function CompanyDetail({ mode, request, setRequest, companyCategoryData, countryData }) {
  const handleChangeCheckboxCompanyCategory = (event, categoryCd, categoryDesc, refFileUploadId = "") => {
    const { checked } = event.target;

    setRequest(prevState => {
      let updatedCategory;

      if (checked) {
        // Add or update category
        const existingCategory = prevState.category.find(category => category.categoryCd === categoryCd);

        if (existingCategory) {
          // Update existing category
          existingCategory.categoryDesc = categoryDesc;
          existingCategory.refFileUploadId = refFileUploadId;
        } else {
          // Add new category
          prevState.category.push({
            categoryCd,
            categoryDesc,
            refFileUploadId,
          });
        }

        updatedCategory = [...prevState.category];
      } else {
        // Remove category
        updatedCategory = prevState.category.filter(category => category.categoryCd !== categoryCd);
      }

      return {
        ...prevState,
        category: updatedCategory,
      };
    });
  };
  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>*Company Abbreviation :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="input_companyAbbr_subScreen"
            required
            disabled={[ModeAction.VIEW, ModeAction.EDIT].includes(mode)}
            fullWidth
            value={request?.companyAbbr}
            onChange={e =>
              setRequest(old => ({
                ...old,
                companyAbbr: e.target.value.toUpperCase(),
              }))
            }
            regularExp={/^[a-zA-Z0-9]*$/}
            autoFocus
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Company Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputText
            id="input_companyCode_subScreen"
            required
            disabled={[ModeAction.VIEW, ModeAction.EDIT].includes(mode)}
            fullWidth
            value={request?.companyCd}
            onChange={e =>
              setRequest(old => ({
                ...old,
                companyCd: e.target.value.toUpperCase(),
              }))
            }
            regularExp={/^[a-zA-Z0-9]*$/}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>*Company Name :</strong>
        </Grid>
        <Grid item xs={8}>
          <InputText
            id="input_companyName_subScreen"
            fullWidth
            required
            value={request?.companyName}
            onChange={e =>
              setRequest(old => ({
                ...old,
                companyName: e.target.value,
              }))
            }
            regularExp=""
            autoFocus
          />
        </Grid>
      </Grid>
      <Grid container alignItems="baseline">
        <Grid item xs={2}>
          <strong>*Company Category :</strong>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            {companyCategoryData?.map((val, i) => {
              return (
                <Grid item xs={3} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={val.cd}
                        onChange={e => handleChangeCheckboxCompanyCategory(e, val.cd, val.value)}
                        checked={request?.category?.some(v => v?.categoryCd === val?.cd)}
                      />
                    }
                    label={val.value}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>*Address Line 1 :</strong>
        </Grid>
        <Grid item xs={8}>
          <InputText
            fullWidth
            required
            value={request?.address1}
            onChange={e =>
              setRequest(old => ({
                ...old,
                address1: e.target.value,
              }))
            }
            regularExp=""
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>Address Line 2 :</strong>
        </Grid>
        <Grid item xs={8}>
          <InputText
            fullWidth
            value={request?.address2}
            onChange={e =>
              setRequest(old => ({
                ...old,
                address2: e.target.value,
              }))
            }
            regularExp=""
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>Address Line 3 :</strong>
        </Grid>
        <Grid item xs={8}>
          <InputText
            fullWidth
            value={request?.address3}
            onChange={e =>
              setRequest(old => ({
                ...old,
                address3: e.target.value,
              }))
            }
            regularExp=""
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>Postal Code :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputText
            disabled={ModeAction.VIEW === mode}
            fullWidth
            value={request?.poatcode}
            onChange={e =>
              setRequest(old => ({
                ...old,
                poatcode: e.target.value,
              }))
            }
            regularExp={/^[0-9]*$/}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>*Country :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputDropDown
            required
            value={request?.countryCd}
            memu={countryData?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setRequest(old => ({
                ...old,
                countryCd: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <strong>Telephone Number :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputText
            disabled={ModeAction.VIEW === mode}
            fullWidth
            value={request?.tel}
            onChange={e =>
              setRequest(old => ({
                ...old,
                tel: e.target.value,
              }))
            }
            regularExp={/^[0-9]*$/}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Fax Number :</strong>
        </Grid>
        <Grid item xs={3}>
          <InputText
            disabled={ModeAction.VIEW === mode}
            fullWidth
            value={request?.fax}
            onChange={e =>
              setRequest(old => ({
                ...old,
                fax: e.target.value,
              }))
            }
            regularExp={/^[0-9]*$/}
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>
      </Grid>
    </Fragment>
  );
}
