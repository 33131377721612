import React, { Fragment } from "react";
import { TanStackTable } from "components/UI";
// utils
import { responseErrors } from "utils";
// Service
import { useShipmentSearchMutation } from "shared/services/inventory-forecast";
// Constant & Type
import { API_SHIPMENT, ROW_PER_PAGE } from "shared/constants";
import { formatRowsGroup } from "pages/Forecast/ShipmentManagementScreen/utils/formatData";
export default function GroupingTable(props) {
  const {
    form,
    mode,
    columns,
    rows,
    setRows,
    setRPkg,
    setOnSearch,
    setMsg: { setMsgAlert, setMsgError },
    pagination: { pagination, pageNumber, setPageNumber, setPagination },
    dataList: { exporterList, importerList, rPkgList },
  } = props;
  //API
  const searchShipment = useShipmentSearchMutation();
  const handleChangePagination = async (event, value) => {
    try {
      event.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      setPageNumber(value);
      const bodySearch = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? rPkgList.find(v => v.rPkgOwnerCompId === form?.rPkgOwnerId).rPkgOwnerCompAbbr
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        pageNumber: value,
        rowsPerPage: ROW_PER_PAGE,
      };

      // Search data
      const searchData = await searchShipment(bodySearch);

      const { rows, rPkg } = formatRowsGroup(searchData?.result?.data);
      setRows(rows);
      setRPkg(rPkg);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return true;
    } catch (error) {
      const err = responseErrors(error);
      setMsgError([err]);
      window.scrollTo(0, 0);
      return false;
    }
  };

  return (
    <Fragment>
      <TanStackTable
        rows={rows}
        columns={columns}
        processRowUpdate={null}
        tableHeight="300px"
        mode={mode}
        columnVisibility={{ [API_SHIPMENT.SHIPMENT_SUMMARY_H_ID]: false }}
        onPagination={true}
        pagination={pagination}
        pageNumber={pageNumber}
        handleChangePagination={handleChangePagination}
      />
    </Fragment>
  );
}
