import React, { Fragment } from "react";
// Component
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
import ActionTableBar from "pages/Forecast/ShipmentManagementScreen/components/Grouping/ActionTableBar";
// Service
import { useShipmentDownloadExcelMutation, useShipmentSearchMutation } from "shared/services/inventory-forecast";
// utils
import { formatRowsGroup } from "pages/Forecast/ShipmentManagementScreen/utils/formatData";
import { findObject, messageDisplay, responseDownloadFileErrors, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
// Constant
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { API_SHIPMENT, ROW_PER_PAGE, BUTTON_VALUE } from "shared/constants";
import { getLocalDate } from "utils/init-config-date";
import { isEmpty } from "lodash";

export default function GroupingButtonBar(props) {
  const {
    form,
    setForm,
    setMode,
    setRows,
    setRPkg,
    setOnSearch,
    pageNumber,
    setPagination,
    setPageNumber,
    setMsg: { setMsgAlert, setMsgError },
    dataList: { exporterList, importerList, rPkgList },
  } = props;

  //API
  const searchShipment = useShipmentSearchMutation();
  const exportShipment = useShipmentDownloadExcelMutation();

  const handleDownload = async btnName => {
    setMsgError([]);
    setMsgAlert([]);
    try {
      // try {
      //   const bodySearch = {
      //     [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
      //     [API_SHIPMENT.COMPANY]: form?.companyAbbr,
      //     [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
      //     [API_SHIPMENT.OPERATION]: form?.operationId,
      //     [API_SHIPMENT.EXPORTER]: form?.exporterId
      //       ? findObject({ data: exporterList, value: form?.exporterId, property: "companyAbbr", field: "impExpCd" })
      //       : "",
      //     [API_SHIPMENT.IMPORTER]: form?.importerId
      //       ? findObject({ data: importerList, value: form?.importerId, property: "companyId", field: "companyAbbr" })
      //       : "",
      //     [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
      //       ? findObject({
      //           data: rPkgList,
      //           value: form?.rPkgOwnerId,
      //           property: "rPkgOwnerCompId",
      //           field: "rPkgOwnerCompAbbr",
      //         })
      //       : "",
      //     [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
      //     [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
      //     [API_SHIPMENT.ETD_FORM]: form.etdFrom,
      //     [API_SHIPMENT.ETD_TO]: form.etdTo,
      //     pageNumber: pageNumber,
      //     rowsPerPage: ROW_PER_PAGE,
      //   };
      //   const searchData = await searchShipment(bodySearch);
      //   // ? CHECK DATA NOT FOUND
      //   if (!searchData?.result?.data?.length) {
      //     const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
      //     setMsgError([msg]);
      //     return false;
      //   }
      // } catch (error) {
      //   const errors = responseErrors(error);
      //   setMsgError(errors);
      //   return;
      // }
      // const fileName = `Shipment Query Record Report_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      const bodyDownload = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: btnName,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? findObject({ data: importerList, value: form?.importerId, property: "companyId", field: "companyAbbr" })
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? findObject({
              data: rPkgList,
              value: form?.rPkgOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            })
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        pageNumber: pageNumber,
        rowsPerPage: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodyDownload,
        rule: [
          {
            field: API_SHIPMENT.EXPORTER,
            type: MessageType.EMPTY,
            key: ["Exporter"],
          },
          {
            field: API_SHIPMENT.IMPORTER,
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
          {
            field: API_SHIPMENT.FORECAST_MONTH,
            type: MessageType.MONTH_INVALID_50,
            key: ["Forecast Month", "MM/YYYY"],
          },
          {
            field: API_SHIPMENT.R_RKG_OWNER,
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      setMsgError(errors);

      let stopProcess = true;
      let msg;
      if (!isEmpty(bodyDownload?.etdFrom) && !isEmpty(bodyDownload?.etdTo)) {
        const dateFrom = getLocalDate(bodyDownload?.etdFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(bodyDownload?.etdTo, "DD/MM/YYYY");
        // MSTD0019AERR: ETD From must be equal to or later than ETD To
        if (dateTo.isBefore(dateFrom, "day")) {
          msg = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETD (To)", "ETD (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msg]);
          stopProcess = false;
        }
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }

      await exportShipment(bodyDownload);
      return;
    } catch (error) {
      const err = responseDownloadFileErrors(error);
      setMsgError(err);
      window.scroll(0, 0);
      return;
    }
  };
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      const bodySearch = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.OPERATION]: form?.operationId,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? findObject({ data: importerList, value: form?.importerId, property: "companyId", field: "companyAbbr" })
          : "",
        [API_SHIPMENT.R_RKG_OWNER]: form.rPkgOwnerId
          ? findObject({
              data: rPkgList,
              value: form?.rPkgOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            })
          : "",
        [API_SHIPMENT.FORECAST_MONTH]: form.forecastMonth,
        [API_SHIPMENT.SHIPMENT_STATUS]: form.shipmentStsId,
        [API_SHIPMENT.ETD_FORM]: form.etdFrom,
        [API_SHIPMENT.ETD_TO]: form.etdTo,
        pageNumber: pageNumber,
        rowsPerPage: ROW_PER_PAGE,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: API_SHIPMENT.EXPORTER,
            type: MessageType.EMPTY,
            key: ["Exporter"],
          },
          {
            field: API_SHIPMENT.FORECAST_MONTH,
            type: MessageType.MONTH_INVALID_50,
            key: ["Forecast Month", "MM/YYYY"],
          },
          {
            field: API_SHIPMENT.IMPORTER,
            type: MessageType.EMPTY,
            key: ["Importer"],
          },
          {
            field: API_SHIPMENT.R_RKG_OWNER,
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });

      setMsgError(errors);

      let stopProcess = true;
      let msg;
      if (!isEmpty(bodySearch?.etdFrom) && !isEmpty(bodySearch?.etdTo)) {
        const dateFrom = getLocalDate(bodySearch?.etdFrom, "DD/MM/YYYY");
        const dateTo = getLocalDate(bodySearch?.etdTo, "DD/MM/YYYY");
        // MSTD0019AERR: ETD From must be equal to or later than ETD To
        if (dateTo.isBefore(dateFrom, "day")) {
          msg = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: ["ETD (To)", "ETD (From)"],
          });
          setMsgError(oldMsg => [...oldMsg, msg]);
          stopProcess = false;
        }
      }

      if (!isSuccess || !stopProcess) {
        window.scrollTo(0, 0);
        return;
      }
      // Search data
      const searchData = await searchShipment(bodySearch);

      // ? CHECK DATA NOT FOUND
      if (!searchData?.result?.data?.length) {
        const msg = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] });
        setMsgError([msg]);
        return false;
      }
      const { data, rPkg } = formatRowsGroup(searchData?.result?.data);
      setRows(data);
      setRPkg(rPkg);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setOnSearch(false);
    setPageNumber(1);
    setPagination({});
    setMode(ModeAction.VIEW);
    document.getElementById("select_exporter").focus();
    setForm(prev => ({
      ...prev,
      [API_SHIPMENT.EXPORTER_ID]: "",
      [API_SHIPMENT.IMPORTER_ID]: "CMP_1208_000001",
      [API_SHIPMENT.R_RKG_OWNER_ID]: "",
      [API_SHIPMENT.FORECAST_MONTH]: "",
      [API_SHIPMENT.SHIPMENT_STATUS_ID]: "",
      [API_SHIPMENT.ETD_FORM]: "",
      [API_SHIPMENT.ETD_TO]: "",
      [API_SHIPMENT.ETA_FORM]: "",
      [API_SHIPMENT.ETA_TO]: "",
      //FILE
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.FILE_NAME]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
        [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
      },
      [API_SHIPMENT.FILE]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
        [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
      },
    }));
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton id="btn_grouping_search" value={BUTTON_VALUE.SEARCH} disabled={false} onClick={handleSearch} />
          <InputButton id="btn_grouping_clear" value={BUTTON_VALUE.CLEAR} disabled={false} onClick={handleClear} />
          <InputButton
            id="btn_grouping_download"
            value={BUTTON_VALUE.DOWNLOAD}
            disabled={false}
            onClick={() => handleDownload(form?.operationId)}
          />
          <InputButton
            id="btn_grouping_all_exporter"
            value={BUTTON_VALUE.ALL_EXPORT}
            disabled={false}
            onClick={() => handleDownload("AG")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ActionTableBar setMsgAlert={setMsgAlert} setMsgError={setMsgError} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
