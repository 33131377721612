import useQuery from "hooks/useQuery";

export const useGetDownloadAndUploadMaster = ({ dataOwner }) => {
  return useQuery({
    url: `/category/${dataOwner}/DOWNLOAD_UPLOAD_MASTER/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadYear = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/working-calendar/year/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadCompanyCode = ({ dataOwner, year }) => {
  return useQuery({
    url: `/category/download-upload/working-calendar/company/${dataOwner}/${year}`,
    method: "GET",
    enabled: year !== "",
    select: data => data.result,
  });
};

export const useGetDownloadTransportCategory = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/transport-leadtime/category/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadTransportMode = ({ dataOwner, transportCd }) => {
  return useQuery({
    url: `/category/download-upload/transport-leadtime/mode/${dataOwner}/${transportCd}`,
    method: "GET",
    enabled: transportCd !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSender = ({ dataOwner, transportCd, transportMd }) => {
  return useQuery({
    url: `/category/download-upload/transport-leadtime/sender/${dataOwner}/${transportCd}/${transportMd}`,
    method: "GET",
    enabled: transportCd !== "" && transportMd !== "",
    select: data => data.result,
  });
};

export const useGetDownloadReceiver = ({ dataOwner, transportCd, transportMd, senderCompId }) => {
  return useQuery({
    url: `/category/download-upload/transport-leadtime/receiver/${dataOwner}/${transportCd}/${transportMd}/${senderCompId}`,
    method: "GET",
    enabled: transportCd !== "" && transportMd !== "" && senderCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadExporter = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/custom-stock-leadtime/exporter/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadImporter = ({ dataOwner, expCompId }) => {
  return useQuery({
    url: `/category/download-upload/custom-stock-leadtime/importer/${dataOwner}/${expCompId}`,
    method: "GET",
    enabled: expCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSopExporter = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/sop/exporter/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadSopImporter = ({ dataOwner, expCompId }) => {
  return useQuery({
    url: `/category/download-upload/sop/importer/${dataOwner}/${expCompId}`,
    method: "GET",
    enabled: expCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadSopRpackage = ({ dataOwner, expCompId, impCompId }) => {
  return useQuery({
    url: `/category/download-upload/sop/r-package/${dataOwner}/${expCompId}/${impCompId}`,
    method: "GET",
    enabled: expCompId !== "" && impCompId !== "",
    select: data => data.result,
  });
};

export const useGetDownloadCarFamilyType = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/katashi-subseries/car-family/type/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadCarFamilyCode = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/katashi-subseries/car-family/code/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadSubseries = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/katashi-subseries/sub-series/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadLocationType = () => {
  return useQuery({
    url: `/category/COMMON/LOCATION_TYPE/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageRpackageOwner = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/average-leadtime-group/r-package/owner/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageRpackageType = ({ dataOwner }) => {
  return useQuery({
    url: `/category/download-upload/average-leadtime-group/r-package/type/${dataOwner}`,
    // url: `/category/download-upload/average-leadtime-group/r-package/type/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetDownloadAverageLocationGroup = ({ dataOwner, locationTypeCd }) => {
  return useQuery({
    url: `/category/download-upload/average-leadtime-group/location/${dataOwner}/${locationTypeCd}`,
    method: "GET",
    enabled: locationTypeCd !== "",
    select: data => data.result,
  });
};
