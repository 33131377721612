import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { isNull } from "lodash";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    padding: "5px 0px 5px 12px",
    color: `${theme.palette.primary.dark}`,
  },
  "& :focus-within": {
    border: `1.5px solid ${theme.palette.primary.dark} !important`,
    color: `${theme.palette.primary.dark}`,
  },
}));

export default function TanStackDropDown({
  renderValue,
  getValue,
  row,
  column,
  table,
  id,
  value = "",
  menu,
  disabled = false,
  bgColor = "white",
  required,
  placeholder = "<Select>",
  defaultValue = "",
  autoFocus = false,
  onChange = null,
  ...rest
}) {
  const [item, setItem] = React.useState(value);
  const tableMeta = table.options.meta;
  const rowData = row.original;
  const handleValueChange = event => {
    const newValue = event.target.value; // The new value entered by the user
    setItem(newValue);
    tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });

    if (!isNull(onChange)) {
      onChange(event);
    }
  };
  React.useEffect(() => {
    setItem(value);
  }, [value]);
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel
        id="test-select-label"
        data-testid={`label-${id}`}
        shrink={false}
        size="small"
        variant="outlined"
        style={{
          top: "50%",
          left: "5%",
          transform: "translateY(-50%)",
        }}>
        {item === defaultValue ? placeholder : ""}
      </InputLabel>
      <Select
        id={id}
        data-testid={id}
        sx={{ minWidth: "100%", background: bgColor }}
        size="small"
        value={item}
        onChange={handleValueChange}
        disabled={disabled}
        required={required}
        style={{ height: "32px" }}
        placeholder={placeholder}
        autoFocus={autoFocus}
        input={<StyledSelectField />}>
        <MenuItem value={defaultValue}>
          <span>{placeholder}</span>
        </MenuItem>
        {menu &&
          menu.map((val, index) => (
            <MenuItem key={index} value={val.key}>
              {val.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
