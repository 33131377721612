import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
import { isEmpty } from "lodash";
const BaseServicePath = process.env.REACT_APP_API_STANDARD;

const ReprocessApiService = () => ({
  // GET BUSINESS DATA
  useReprocessBusinessDataCodeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/reprocess-error/business-data`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET PLANT
  useReprocessPlantQuery: businessName => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/reprocess-error/plant/${businessName}`,
      method: "GET",
      enabled: !isEmpty(businessName),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET BATCH ROUND
  useReprocessBatchRoundQuery: businessName => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/reprocess-error/batch-round/${businessName}`,
      method: "GET",
      enabled: !isEmpty(businessName),
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // POST UPLOAD
  useReprocessUploadMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async uploadData => {
      return await mutate({
        url: `${BaseServicePath}/reprocess-error/upload`,
        method: "POST",
        data: uploadData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    };
  },
  //POST SEARCH
  useReprocessSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/reprocess-error/search`,
        data: searchData,
        method: "POST",
      });
    };
  },
  //POST DOWNLOAD SCAN-TRANSACTION
  useReprocessDownloadScanTransMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body, businessName) => {
      return await mutate(
        {
          url: `${BaseServicePath}/reprocess-error/download/excel/${businessName}`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}.xlsx`);
          },
        }
      );
    };
  },
  //POST DOWNLOAD VANNING
  useReprocessDownloadVanningMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/reprocess-error/download/excel/vanning`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}.xlsx`);
          },
        }
      );
    };
  },
  //POST REPROCESS
  useReprocessMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/reprocess-error/reprocess`,
        data: searchData,
        method: "POST",
      });
    };
  },
  //POST IGNORE
  useReprocessIgnoreMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/reprocess-error/ignore`,
        data: searchData,
        method: "POST",
      });
    };
  },
});

export const {
  useReprocessBusinessDataCodeQuery,
  useReprocessPlantQuery,
  useReprocessBatchRoundQuery,
  useReprocessUploadMutation,
  useReprocessSearchMutation,
  useReprocessDownloadScanTransMutation,
  useReprocessDownloadVanningMutation,
  useReprocessMutation,
  useReprocessIgnoreMutation,
} = ReprocessApiService();
