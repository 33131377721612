import React from "react";
import { ModeAction } from "state/enum";
export default function useButtonMode(props) {
  const {
    mode,
    stateButton: { setSearchBtn, setClearBtn, setEditBtn, setSimulateBtn, setCancelBtn, setConfirmBtn, setViewBtn },
  } = props;

  switch (mode) {
    case "Initial":
      setSearchBtn(false);
      setClearBtn(false);
      setEditBtn(true);
      setViewBtn(true);
      setSimulateBtn(true);
      setCancelBtn(true);
      setConfirmBtn(true);
      break;

    case ModeAction.VIEW:
      setSearchBtn(false);
      setClearBtn(false);
      setEditBtn(false);
      setViewBtn(false);
      setSimulateBtn(true);
      setCancelBtn(true);
      setConfirmBtn(true);
      break;

    case ModeAction.EDIT:
      setSearchBtn(true);
      setClearBtn(true);
      setEditBtn(true);
      setViewBtn(true);
      setSimulateBtn(false);
      setCancelBtn(false);
      setConfirmBtn(false);
      break;

    default:
      break;
  }
}
