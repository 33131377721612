import React, { Fragment, useEffect } from "react";
import { Grid } from "@mui/material";
// SHARED COMPONENTS
import { DatePickerField, DropDown } from "components/UI";
// Type
import { ModeAction } from "state/enum";
import { API_NAME_IMPORT_INVOICE_DOWNLOAD } from "shared/constants";

export default function FilterBar({
  form,
  setForm,
  mode,
  setMode,
  setMsg,
  dataList: { exporterList },
}) {
  useEffect(() => {
    document.getElementById("input_etd_from").focus();
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>*ETD From :</strong>
        </Grid>
        <Grid item xs={4}>
          <DatePickerField
            required
            fullWidth={true}
            id="input_etd_from"
            value={form?.etdFrom}
            onChange={(newValue) => {
              setForm((prev) => ({
                ...prev,
                [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_FROM]: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>ETD To :</strong>
        </Grid>
        <Grid item xs={4}>
          <DatePickerField
            fullWidth={true}
            id="input_etd_to"
            value={form?.etdTo}
            onChange={(newValue) => {
              setForm((prev) => ({
                ...prev,
                [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETD_TO]: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>ETA From :</strong>
        </Grid>
        <Grid item xs={4}>
          <DatePickerField
            fullWidth={true}
            id="input_eta_from"
            value={form?.etaFrom}
            onChange={(newValue) => {
              setForm((prev) => ({
                ...prev,
                [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_FROM]: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>ETA To :</strong>
        </Grid>
        <Grid item xs={4}>
          <DatePickerField
            fullWidth={true}
            id="input_eta_to"
            value={form?.etaTo}
            onChange={(newValue) => {
              setForm((prev) => ({
                ...prev,
                [API_NAME_IMPORT_INVOICE_DOWNLOAD.ETA_TO]: newValue,
              }));
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ mt: "8px" }}>
          <strong>Exporter : </strong>
        </Grid>
        <Grid item xs={4}>
          <DropDown
            id="select-exporter"
            value={form?.exporterId}
            onChange={(e) => {
              setForm((prev) => ({
                ...prev,
                [API_NAME_IMPORT_INVOICE_DOWNLOAD.EXPORTER_ID]: e.target.value,
              }));
            }}
            menu={exporterList?.map((val) => ({
              key: val.companyId,
              value: val.companyAbbr,
            }))}
            defaultValue=""
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
