import { useMutateData } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_HANDHELD;

const UploadRPkgContApiService = () => ({
  useUploadRPkgContUploadRPgMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async uploadData => {
      return await mutate({
        url: `${BaseServicePath}/main-server-screen/upload-update-status-rpack`,
        data: uploadData,
        method: "POST",
      });
    };
  },
  useUploadRPkgContUploadContMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async uploadData => {
      return await mutate({
        url: `${BaseServicePath}/main-server-screen/upload-update-status-container`,
        data: uploadData,
        method: "POST",
      });
    };
  },
});

export const { useUploadRPkgContUploadRPgMutation, useUploadRPkgContUploadContMutation } = UploadRPkgContApiService();
