import { useCustomQuery, useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = "/web-api-export-invoice-creation";

const ImportInvoiceDownloadApiService = () => ({
  useGetBrokerCd: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/export-invoice/company/B/TMATH/CMP_1812_000001`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useGetExporterCd: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/export-invoice/company/E/TMATH/all`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useImportInvoiceDownloadSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async body => {
      return await mutate({
        url: `${BaseServicePath}/import-invoice-download/search`,
        method: "POST",
        data: body,
      });
    };
  },
  useImportInvoiceDownloadUpdateMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async updateData => {
      return await mutate({
        url: `${BaseServicePath}/import-invoice-download/update/${updateData.nonCommInvHId}`,
        method: "PATCH",
        data: updateData,
      });
    };
  },
  useImportInvoiceDownloadMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/export-invoice-report/generatereport`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/zip",
            // });
          },
        }
      );
    };
  },
});

export const {
  useGetBrokerCd,
  useGetExporterCd,
  useImportInvoiceDownloadUpdateMutation,
  useImportInvoiceDownloadSearchMutation,
  useImportInvoiceDownloadMutation,
} = ImportInvoiceDownloadApiService();
