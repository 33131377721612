import { userProfile } from "constant";
import { API_MONTHLY } from "shared/constants";

// MONTHLY SCREEN
const MONTHLY_DATA_TYPE = {
  MATERIAL_SUPPLIER: "Material Supplier",
  MATERIAL_TYPE: "Material Type",
  PACKING_PLAN: "Packing Plan",
  DELIVERY_PLAN: "Delivery Plan",
  ARMC_SOURCE_DATA: "APMC Source Data",
};
const MONTHLY_MODE = {
  FIRM: "Firm",
  KEIHEN: "Keihen",
};
export const UPLOAD_STATUS = {
  SUCCESS: "Success",
  WARNING: "Warning",
  ERROR: "Error",
  DUPLICATE: "Duplicate",
  PROCESSING: "Processing",
};
export const GENERATE_STATUS = {
  GENERATED: "Generated",
  ERROR: "Error",
  WAIT: "Waiting",
  ON_PROCESS: "Process",
};

const DEFAULT_VALUE = {
  [API_MONTHLY.FORECAST_TYPE_CD]: "",
  [API_MONTHLY.FORECAST_MONTH]: "",
  [API_MONTHLY.MODE_CD]: "",
  [API_MONTHLY.DATA_TYPE_CD]: "",
  [API_MONTHLY.UPLOAD_STATUS_CD]: "",
  [API_MONTHLY.UPLOAD_COMPANY_ABBR]: "",
  [API_MONTHLY.FILE_UPLOAD_ID]: "",
  [API_MONTHLY.DATA_OWNER]: userProfile.dataOwner,
  [API_MONTHLY.USER_ID]: userProfile.userId,
  [API_MONTHLY.ORG_FILE_NAME]: "",
  [API_MONTHLY.FILE]: {},
};

export { MONTHLY_DATA_TYPE, MONTHLY_MODE, DEFAULT_VALUE };
