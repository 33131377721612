import useQuery from "hooks/useQuery";
import _ from "lodash";

export const useGetType = ({ dataOwner }) => {
  return useQuery({
    url: `/category/gentan-i/type/COMMON/GENTAN_I_TYPE/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetImpExpCdDropDown = ({ dataOwner, type }) => {
  return useQuery({
    url: `/category/gentan-i/imp-exp/${type}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetImpExpCdTableDropDown = ({ dataOwner, type }) => {
  return useQuery({
    url: `/category/gentan-i/imp-exp/plant/${type}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/gentan-i/r-package/company/${dataOwner}/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetCarFamilyCodeTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/car/CAR_FAMILY_CD/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetSubSeriesTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/car/SUB_SERIES/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};
