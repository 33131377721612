export const API_SYSTEM_MASTER = {
  ROW_NUMBER: "rowNumber",
  DATA_OWNER: "dataOwner",
  CATEGORY: "category",
  CATEGORY_CD: "categoryCd",
  SUB_CATEGORY: "subCategory",
  SUB_CATEGORY_CD: "subCategoryCd",
  CODE: "code",
  VALUE: "value",
  REMARK: "remark",
  STATUS_ID: "statusId",
  STATUS: "status",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  UPDATE_KEY: "updateKey",
  CREATE_DATE: "createDt",
  CREATE_BY: "createBy",
};
