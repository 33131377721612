// LoadingContext.js
import React, { createContext, useState, useContext } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Typography } from "components/UI";
export const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
      <Backdrop open={loading} sx={{ zIndex: 9999, color: "#fff", display: "flex", flexFlow: "column" }}>
        <CircularProgress color="primary" size={35} thickness={4} sx={{ mb: 1, ml: 25 }} />
        <Typography content="Loading..." sx={{ fontSize: "20px", fontWeight: "bold", ml: 25 }} />
      </Backdrop>
    </LoadingContext.Provider>
  );
};

export { LoadingProvider };
export const useLoadingContext = () => useContext(LoadingContext);
