import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import _ from "lodash";
import { useMutateData, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_PREFIX;
export const useGetCountry = ({ dataOwner }) => {
  return useQuery({
    url: `/category/country/route-point/COMMON/COUNTRY_CD/${dataOwner}`,
    method: "GET",
    enabled: dataOwner !== "",
    select: data => data.result,
  });
};

export const useRoutePointCategory = ({ category = "COMMON", subCategory = "ROUTE_POINT_CATEGORY", dataOwner }) => {
  return useQuery({
    url: `/category/route-point/${category}/${subCategory}/${dataOwner}`,
    method: "GET",
    enabled: dataOwner !== "",
    select: data => data.result,
  });
};

export const useGetSubPlant = ({ dataOwner, subCategory = "COMPANY_PlANT_EXT_CD" }) => {
  return useQuery({
    url: `/category/${dataOwner}/${subCategory}/ASC`,
    method: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useInOutStatus = () => {
  return useQuery({
    url: `/category/COMMON/IN_OUT_STATUS/ASC`,
    mrthod: "GET",
    enabled: true,
    select: data => data.result,
  });
};

export const useGetCountryInTable = ({
  // cd = "TMATH",
  // dataOwner = "AR",
  dataOwner,
  cd,
}) => {
  return useQuery({
    // url: `/route-point/country/${dataOwner}/${cd}`,
    url: `/category/company/${dataOwner}`,
    method: "GET",
    enabled: cd !== "",
    select: data => data.result,
    keepPreviousData: true,
  });
};

export const useGetSubPlantInTable = ({ category, dataOwner }) => {
  return useQuery({
    url: `/category/sub-plant/${category}/${dataOwner}`,
    method: "GET",
    select: data => data.result,
    enabled: true,
  });
};

export const useSearchRoutePoint = ({
  dataOwner,
  countryCd,
  routePointCd,
  routePointName,
  extensionCd,
  pageNumber,
  rowsPerPage,
}) => {
  return useMutation({
    url: "/route-point/search",
    method: "POST",
    data: {
      dataOwner,
      countryCd,
      routePointCd,
      routePointName,
      extensionCd,
      pageNumber,
      rowsPerPage,
    },
    enabled: true,
  });
};

export const usePossibleStatus = ({
  // category="COMMON"
  // subCategory="RPKG_STATUS"
  category = "COMMON",
  subCategory = "RPKG_STATUS",
}) => {
  return useQuery({
    url: `/category/${category}/${subCategory}/ASC`,
    method: "GET",
    select: data => data.result,
    enabled: true,
  });
};

export const useCreateRoutePoint = () => {
  return useMutation({
    url: "/route-point",
    method: "POST",
  });
};

export const useUpdateRoutePoint = ({ routePointId }) => {
  return useMutation({
    url: `/route-point/${routePointId}`,
    method: "PATCH",
  });
};

export const useDeleteRoutePoint = ({ routePointId }) => {
  return useMutation({
    url: `/route-point/${routePointId}`,
    method: "DELETE",
  });
};

export const useCompanyPlantInTable = ({ dataOwner, cd }) => {
  return useQuery({
    url: `/route-point/country/${dataOwner}/${cd}`,
    mrthod: "GET",
    enabled: !_.isEmpty(cd),
    key: [cd],
    select: data => data.result,
  });
};
export const useRoutePathDownloadExcelMutation = () => {
  const { mutate } = useMutateDownload({
    invalidateKeys: [],
  });
  return async (body) => {
    return await mutate(
      {
        url: `${BaseServicePath}/route-point/download/excel`,
        method: "POST",
        data: body,
        responseType: "arraybuffer",
      },

      {
        onSuccess: response => {
          saveAs(response);
          // const blob = new Blob([data], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // });
          // (blob, `${fileName}`);
        },
      }
    );
  };
};
