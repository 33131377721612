import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { userProfile } from "constant";
import React from "react";
import { BUTTON_VALUE } from "shared/constants";
import { useUploadRPkgContUploadContMutation } from "shared/services/main-server";
import { MessageType } from "state/enum";
import { responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";

export default function ButtonUploadContainer({ form, setForm, setMsg: { setMsgError, setMsgAlert } }) {
  const upload = useUploadRPkgContUploadContMutation();

  const handleUpload = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const body = {
        userCompanyCd: "TH",
        userId: userProfile.userId,
        file: form.file,
        fileName: form.fileName,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "fileName",
            type: MessageType.EMPTY,
            key: ["Excel File"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        setForm({ file: "", fileName: "" });
        window.scrollTo(0, 0);
        return;
      }
      const uploadData = new FormData();
      uploadData.append("file", body.file);
      uploadData.append("userId", body.userId);
      uploadData.append("userCompanyCd", body.userLocation);
      const response = await upload(uploadData);
      setForm({ file: "", fileName: "" });
      setMsgAlert([response?.message]);
      // setMsgAlert(["MDN90034AINF: Posting on demand batch is completed successfully."]);
      return true;
    } catch (error) {
      const msgError = responseErrors(error);
      setMsgError(msgError);
      return false;
    }
  };
  return (
    <Box>
      <InputButton
        id="button-upload-container"
        value={BUTTON_VALUE.UPLOAD}
        onClick={handleUpload}
        onKeyDown={e => {
          if (e.key === "Tab") {
            document.getElementById("input-upload-excel-r-pkg").focus();
          }
        }}
      />
    </Box>
  );
}
