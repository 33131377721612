import useQuery from "hooks/useQuery";
import _ from "lodash";

export const useGetMasterType = ({ dataOwner }) => {
  return useQuery({
    url: `/category/COMMON/STK_INDICATOR_TYPE/ASC`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetMasterCountry = () => {
  return useQuery({
    url: `/category/COMMON/COUNTRY_CD/ASC`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetRoutePointCountryDropDown = ({ cd, dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/country/${cd}/COMMON/COUNTRY_CD/${dataOwner}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: (data) => data.result,
  });
};

export const useGetRoutePointCategoryDropDown = ({ cd, dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/route-point/${cd}/COMMON/ROUTE_POINT_CATEGORY/${dataOwner}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: (data) => data.result,
  });
};

export const useGetRoutePointNameDropDown = ({ cd, dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/route-point/name/${cd}/${dataOwner}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: (data) => data.result,
  });
};

export const useGetRpkgOwnerDropDown = ({ cd, dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/r-package/owner/${cd}/${dataOwner}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: (data) => data.result,
  });
};

export const useGetRpkgTypeDropDown = ({
  cd,
  dataOwner,
  rPkgOwnerCompAbbr,
}) => {
  return useQuery({
    url: `/category/safety-overflow-stock/r-package/name/${cd}/${dataOwner}/${rPkgOwnerCompAbbr}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd) && !_.isEmpty(rPkgOwnerCompAbbr),
    select: (data) => data.result,
  });
};

export const useGetRpkgOwnerTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/r-package/company/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetRpkgTypeTableDropDown = ({ dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/r-package/detail/${dataOwner}`,
    method: "GET",
    enabled: true,
    select: (data) => data.result,
  });
};

export const useGetRoutePointNameTableDropDown = ({ cd, dataOwner }) => {
  return useQuery({
    url: `/category/safety-overflow-stock/route-point/detail/${dataOwner}/${cd}`,
    method: "GET",
    key: [cd],
    enabled: !_.isEmpty(cd),
    select: (data) => data.result,
  });
};
