import React, { Fragment, useState } from "react";
import { Box, Grid } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import HeaderSection from "./components/HeaderSection";
import ButtonSendingData from "./components/ButtonSendingData";
import {
  useUploadingScanningDataGetDataTypeQuery,
  useUploadingScanningDataGetPlantServerQuery,
} from "shared/services/main-server";
export default function SendingDataToPOLYGONPlantServerScreen() {
  const [form, setForm] = useState({ locationId: "", dataTypeId: "" });
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const { data: locationData } = useUploadingScanningDataGetPlantServerQuery();
  const { data: dataTypeList } = useUploadingScanningDataGetDataTypeQuery();
  const locationList = React.useMemo(() => {
    if (locationData) {
      locationData?.sort((a, b) => {
        return a?.value.toLowerCase().localeCompare(b?.value.toLowerCase());
      });
      return locationData;
    }
    return locationData;
  }, [locationData]);
  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WDN97050 : Sending Data to POLYGON Plant Server Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderSection form={form} setForm={setForm} locationList={locationList} dataTypeList={dataTypeList} />
          </Grid>
          <Grid item xs={12}>
            <ButtonSendingData form={form} setForm={setForm} setMsg={{ setMsgError, setMsgAlert }} />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
}
