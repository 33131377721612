import React from "react";
// Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
// Utils
import { messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
import { transformData } from "../hooks/transformData";
//Service
import { useShippingInfoSearchMutation } from "shared/services/invoice";
//Type
import { DEFAULT_SHIPPING_INFO } from "../constants/constant";
import { BUTTON_VALUE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { getLocalDate } from "utils/init-config-date";
export default function ButtonCriterial(props) {
  const {
    // mode,
    setMode,
    form,
    setForm,
    // onSearch,
    // rows,
    setRows,
    setOnSearch,
    // setShippingInfo,
    setMsg: { setMsgAlert, setMsgError },
    stateButton: { searchBtn, clearBtn },
    rowSelection: { setRowModesModel, setRowSelectionModel },
    pagination: { setPagination, setPageNumber },
  } = props;
  const searchShippingInfo = useShippingInfoSearchMutation();
  const handleSearch = async event => {
    try {
      event.preventDefault();
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});

      const body = {
        expCd: form?.exporterCd,
        impCompanyId: form?.importerId,
        loadingPort: form?.loadingPort,
        dischargingPort: form?.dischargingPort,
        etd: form?.etd,
        orderType: form?.orderTypeId,
        vessel: form?.vesselName,
        carfamilyCode: form?.carFamilyCode,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "expCd",
            type: MessageType.EMPTY,
            key: ["Exporter Code"],
          },
          {
            field: "etd",
            type: MessageType.DATE_FORMAT_50,
            key: ["ETD", "DD/MM/YYYY"],
          },
          {
            field: "vessel",
            type: MessageType.INPUT_INVALID_STRING,
            key: ["Vessel Name"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      body.etd = !body?.etd ? "" : getLocalDate(body?.etd, "DD/MM/YYYY").format("YYYY-MM-DD");
      const searchData = await searchShippingInfo(body);
      if (!searchData?.result?.data?.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      const data = transformData(searchData?.result?.data);
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);
    setForm(DEFAULT_SHIPPING_INFO);
    //clear local storage
    // setShippingInfo(DEFAULT_LOCAL_STATE_WDN95030);
    // Reset Data of Table
    setPageNumber(FIRST_PAGE);
    setRows([]);
    setPagination({});
    setRowSelectionModel([]);
    setRowModesModel({});
    //Reset state
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    document.getElementById("select-exporter-code").focus();
  };
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <InputButton id="btn_search" value={BUTTON_VALUE.SEARCH} disabled={searchBtn} onClick={handleSearch} />
        <InputButton id="btn_clear" value={BUTTON_VALUE.CLEAR} disabled={clearBtn} onClick={handleClear} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
