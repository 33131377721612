import React, { useEffect } from "react";
import { Box, IconButton, Popover, TextField, colors, styled } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { getLocalDate } from "utils/init-config-date";
const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "5px 10px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
}));
const StyledDateCalendar = styled(DateCalendar)(({ theme, error }) => ({
  "& .MuiButtonBase-root": {
    "&.MuiPickersDay-root:hover": {
      backgroundColor: `${theme.palette.secondary.medium}`,
    },
  },
  "& .Mui-selected": { backgroundColor: `${theme.palette.tertiary.chip}`, color: "#000000" },
}));
const TanStackDatePickerField = ({
  renderValue,
  getValue,
  row,
  column,
  table,
  id,
  // onChange,
  value,
  required,
  fullWidth = false,
  disabled = false,
  autoFocus = false,
  defaultValue = "",
  format = "DD/MM/YYYY",
  mask = "DD/MM/YYYY",
  disablePast = false,
  disableKey = false,
  views,
  ...props
}) => {
  const tableMeta = table.options.meta;
  const rowData = row.original;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [select, setSelect] = React.useState(getLocalDate());
  const [date, setDate] = React.useState(value);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (disabled) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = date => {
    try {
      setSelect(date);
      setDate(getLocalDate(date).format(format));
      tableMeta?.updateData({
        rowIndex: rowData?.rowNumber,
        columnId: column.id,
        value: getLocalDate(date).format(format),
      });

      handleClose();
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = event => {
    const newValue = event.target.value;
    if (!newValue) {
      setDate("");
      setSelect(getLocalDate());
      tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });
      return;
    }
    setDate(newValue);
    tableMeta?.updateData({ rowIndex: rowData?.rowNumber, columnId: column.id, value: newValue });

    if (!getLocalDate(newValue, format, true).isValid()) {
      setSelect(getLocalDate());
      return;
    }
    setSelect(getLocalDate(newValue, format));
  };

  const formattedValue = date => {
    try {
      // date equal null
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return getLocalDate(date, format);
      }

      return date;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  useEffect(() => {
    setDate(value);
  }, [value]);
  return (
    <Box width={"100%"} sx={{ display: "flex", justifyContent: "center", flexWrap: "row wrap", alignItems: "center" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputMask
          id={id}
          data-testid={id}
          mask={mask}
          formatChars={{
            Y: "[0-9]",
            M: "[0-9]",
            D: "[0-9]",
          }}
          value={date}
          required={required}
          disabled={disabled || disableKey}
          fullWidth={fullWidth}
          onChange={handleChange}
          // onBlur={onBlur}
          placeholder={format}
          maskChar={null}
          alwaysShowMask={false}
          autoFocus={true}>
          {inputProps => <StyledTextField disabled={disabled || disableKey} {...inputProps} />}
        </InputMask>
        <IconButton onClick={handleClick}>
          <InsertInvitationIcon sx={{ color: "#58595B", ml: 1, mb: "2px" }} />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <StyledDateCalendar
            views={views}
            value={formattedValue(select)}
            onChange={handleSelect}
            disablePast={disablePast}
          />
        </Popover>
      </LocalizationProvider>
    </Box>
  );
};

export default TanStackDatePickerField;
