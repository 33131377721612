export const API_NAME_ON_DEMAND_EXCEL = {
  ROW_NUMBER: "rowNumber",
  DOC_ID: "docId",
  REPORT_NAME: "reportName",
  REPORT_STATUS: "reportStatus",

  FILE_NO: "fileNo",
  FILE_SIZE: "fileSize",
  FILE_COUNT: "fileCnt",
  NO_OF_ZIP_FILE: "noOfZipFile",
  FILE_NAME: "fileName",
  FILE_STATUS: "fileStatus",
  OVERRIDE_PATH: "overridePath",

  STATUS: "status",
  STATUS_ID: "statusId",
  DATA_OWNER: "dataOwner",
  // EXEC_START_DT: "execStartDt",
  // EXEC_END_DT: "execEndDt",
  REQUEST_DATE: "requestDate",
  REQUEST_DT: "requestDt",
  REQUEST_BY: "requestBy",
  // PIC_EMAIL: "picEmail",
  USER_ID: "userId",
  ORDER_BY_DATE: "orderByDate",
  GENERATED_BY: "generatedBy",
  UPDATE_BY: "updateBy",
};
