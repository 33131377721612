const INDEX_OF_FIG_COL = 1;
export default function useColumnNewPrint({ deliveryDate }) {
  try {
    const column = [
      // Col 1
      {
        field: "warehouseSupplier",
        headerName: "",
        headerAlign: "center",
        align: "left",
        minWidth: 200,
        flex: 0.1,
        sortable: false,
        editable: false,
      },
      { field: "rowNumber", editable: false },
    ];
    let newCol = [];
    newCol = deliveryDate.map((val, index) => ({
      field: val,
      headerName: val,
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 0.1,
      sortable: false,
      editable: false,
    }));
    column.splice(INDEX_OF_FIG_COL, 0, ...newCol);
    return column;
  } catch (error) {
    console.error(error);
  }
}
