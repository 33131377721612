import {
  API_EXPORT_INVOICE_CREATION_INFO,
  API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO,
  API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO,
} from "shared/constants/api-name/invoice/exportInvoiceCreation";

export const transformData = (dataList) => {
  const data = dataList.map((item, index) => ({
    no: index + 1,
    [API_EXPORT_INVOICE_CREATION_INFO.ROW_NUMBER]: item?.rowNumber,
    [API_EXPORT_INVOICE_CREATION_INFO.INVOICE_NO]: item?.invNo,
    [API_EXPORT_INVOICE_CREATION_INFO.ETD_DATE]: item?.etdDt,
    [API_EXPORT_INVOICE_CREATION_INFO.ETA_DATE]: item?.etaDt,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_COMPANY_ABBR]: item?.expCompanyAbbr,
    [API_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY]: item?.impCompanyAbbr,
    [API_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY_ID]: item?.impCompanyId,
    [API_EXPORT_INVOICE_CREATION_INFO.VESSEL_NAME]: item?.vesselName,
    [API_EXPORT_INVOICE_CREATION_INFO.INVOICE_DATE]: item?.invDt,
    [API_EXPORT_INVOICE_CREATION_INFO.INVOICE_NET_WEIGHT]: item?.invNetWeight,
    [API_EXPORT_INVOICE_CREATION_INFO.INVOICE_FOB_PRICE]: item?.invFobPrice,
    [API_EXPORT_INVOICE_CREATION_INFO.CURRENCY_CD]: item?.currencyCd,
    [API_EXPORT_INVOICE_CREATION_INFO.ORG_BL_FILE_NAME]: item?.orgBlFileName,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_CUSTOMS_STK_DATE]:
      item?.expCustomsStkDt,
    [API_EXPORT_INVOICE_CREATION_INFO.UPDATE_DATE]: item?.updateDt,
    [API_EXPORT_INVOICE_CREATION_INFO.UPDATE_BY]: item?.updateBy,
    [API_EXPORT_INVOICE_CREATION_INFO.NON_COMM_INVOICE_HID]:
      item?.nonCommInvHId,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_CD]: item?.expCd,
    [API_EXPORT_INVOICE_CREATION_INFO.IMP_COMPANY_CD]: item?.impCompanyCd,
    [API_EXPORT_INVOICE_CREATION_INFO.INVOICE_TYPE]: item?.invType,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_BROKER_COMPANY_ID]:
      item?.expBrokerCompanyId,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_BROKER_COMPANY]:
      item?.expBrokerCompanyAbbr,
    [API_EXPORT_INVOICE_CREATION_INFO.EXP_ENTRY_NO]: item?.expEntryNo,
    [API_EXPORT_INVOICE_CREATION_INFO.SHIPMENT_CODE]: item?.shipmentCd,
  }));
  return data;
};

export const transformSubScreenData = (dataList) => {
  const data = dataList.map((item, index) => ({
    no: index + 1,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ROW_NUMBER]: item?.rowNumber,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_CODE]: item?.expCd,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_COPANY_ID]:
      item?.expCompanyId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_COMPANY]:
      item?.expCompanyAbbr,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_PLANT_ID]:
      item?.expPlantId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.EXP_COUNTRY_NAME]:
      item?.expCountryName,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_CODE]: item?.impCd,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_COMPANY_ID]:
      item?.impCompanyId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_COMPANY_ABBR]:
      item?.impCompanyAbbr,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_PLANT_ID]:
      item?.impPlantId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.IMP_COUNTRY_NAME]:
      item?.impCountryName,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.CONSIGNEE]: item?.consignee,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VESSEL_NAME]: item?.vesselName,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ETD_DATE]: item?.etdDt,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.RENBAN_NO]: item?.renbanNo,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ORDER_TYPE_CD]:
      item?.orderTypeCd,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.ORDER_TYPE]: item?.orderType,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.CONTAINER_NO]:
      item?.containerNo,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.R_PKG_TYPE_ID]:
      item?.rPkgTypeId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.R_PKG_TYPE]: item?.rPkgType,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.R_PKG_OWNER_COMP_ABBR]:
      item?.rPkgOwnerCompAbbr,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACK_STATUS]: item?.packStatus,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.QTY]: item?.qty,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACK_STS]: item?.packSts,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.WEIGHT]: item?.weight,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.CATEGORY_CD]: item?.categoryCd,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.LOAD_PORT_DESC]:
      item?.loadPortDesc,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.DISC_PORT_DESC]:
      item?.discPortDesc,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VANNING_INFO_H_ID]:
      item?.vanningInfoHId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.SAVED_FLAG]: item?.savedFlag,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.INV_TYPE]: item?.invType,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.PACKING_MONTH]:
      item?.packingMonth,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.VANNING_PLANT]:
      item?.vanningPlant,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.BROKER_COMPANY_ID]:
      item?.brokerCompanyId,
    [API_EXPORT_INVOICE_CREATION_SUB_SCREEN_INFO.BROKER_COMPANY_ABBR]:
      item?.brokerCompanyAbbr,
  }));
  return data;
};

export const transformCancelSubScreenData = (dataList) => {
  const data = dataList.map((item, index) => ({
    no: index + 1,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.ROW_NUMBER]:
      item?.rowNumber,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.EXP_CD]:
      item?.expCd,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.IMPORTER_COMPANY]:
      item?.impCompanyAbbr,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.RENBAN_NO]:
      item?.renbanNo,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.ETD_DATE]:
      item?.etdDt,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.CONTAINER_NO]:
      item?.containerNo,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.QTY]: item?.qty,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.INVOICE_NO]:
      item?.invNo,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.NON_COMM_INVOICE_HID]:
      item?.nonCommInvHId,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.VANNING_INFO_H_ID]:
      item?.vanningInfoHId,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.SHIPPING_INFO_H_ID]:
      item?.shippingInfoHId,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.VAN_UPDATE_DATE]:
      item?.vanUpdateDt,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.VAN_UPDATE_BY]:
      item?.vanUpdateBy,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.SHIP_UPDATE_DATE]:
      item?.shipUpdateDt,
    [API_EXPORT_INVOICE_CANCEL_VANNING_RESULT_SUB_SCREEN_INFO.SHIP_UPDATE_BY]:
      item?.shipUpdateBy,
  }));
  return data;
};
