import React from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { BUTTON_VALUE } from "shared/constants";
import { findObject, messageDisplay, responseErrors } from "utils";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { validationSearchForm } from "utils/validation";
import { transformDataWDN97053 } from "../hooks/transformData";
import { usePrintDlvTypeShtSearchMutation } from "shared/services/main-server";
import { getLocalDate } from "utils/init-config-date";

export default function ButtonPrint({
  form,
  setForm,
  setRows,
  setMode,
  setOnSearch,
  onSearch,
  setRowSelectionModel,
  setMsg: { setMsgAlert, setMsgError },
  loading: { startLoading, stopLoading },
  dataList: { deliveryTypeList, packStatusList, senderList, receiverList, shippingInfoList },
}) {
  const search = usePrintDlvTypeShtSearchMutation();
  const handleSearch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setOnSearch(false);
      setRows([]);
      const body = {
        actualDeliveryDateFrom: form?.actualDtFrom, //YYYY-MM-DD required
        actualDeliveryDateTo: form?.actualDtTo, //YYYY-MM-DD option
        deliveryType: form?.deliveryType, // D,O required
        senderId: form?.sender,
        senderImpExpCd: !form?.sender
          ? ""
          : findObject({ data: senderList, value: form?.sender, property: "senderId", field: "impExpCd" }),
        senderCompanyAbbr: !form?.sender
          ? ""
          : findObject({ data: senderList, value: form?.sender, property: "senderId", field: "senderCompany" }),
        receiverId: form?.receiver,
        receiverImpExpCd: !form?.receiver
          ? ""
          : findObject({ data: receiverList, value: form?.receiver, property: "receiverId", field: "impExpCd" }),
        receiverCompanyAbbr: !form?.receiver
          ? ""
          : findObject({ data: receiverList, value: form?.receiver, property: "receiverId", field: "receiverCompany" }),
        shippingCompany: !form?.shippingInfo
          ? ""
          : shippingInfoList?.find((val, index) => index === form?.shippingInfo)["shippingCompany"],
        shippingDate: !form?.shippingInfo
          ? ""
          : shippingInfoList?.find((val, index) => index === form?.shippingInfo)["shippingDate"].replace("(ETD)", ""),
        containerNo: form?.containerNo,
        deliveryInstructionSheetNo: form?.deliveryInstrSheetNo,
        packStatus: form?.packStatus,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: "actualDeliveryDateFrom",
            type: MessageType.DATE_INVALID_45,
            key: ["Actual Delivery Date (From)", "DD/MM/YYYY"],
          },
          {
            field: "actualDeliveryDateTo",
            type: MessageType.DATE_FORMAT_45,
            key: ["Actual Delivery Date (To)", "DD/MM/YYYY"],
          },
          {
            field: "actualDeliveryDateFrom",
            type: MessageType.DATE_EQUAL_LATER,
            key: ["Actual Delivery Date (To)", "Actual Delivery Date (From)", body?.actualDeliveryDateTo],
          },
          {
            field: "deliveryType",
            type: MessageType.EMPTY,
            key: ["Delivery Type"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      body.actualDeliveryDateFrom = !body.actualDeliveryDateFrom
        ? ""
        : getLocalDate(body.actualDeliveryDateFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
      body.actualDeliveryDateTo = !body.actualDeliveryDateTo
        ? ""
        : getLocalDate(body.actualDeliveryDateTo, "DD/MM/YYYY").format("YYYY-MM-DD");
      body.shippingDate = !body.shippingDate ? "" : getLocalDate(body.shippingDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      startLoading();
      const searchData = await search(body);
      stopLoading();
      if (!searchData?.result?.records?.length) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] })]);
        return false;
      }
      // else if (searchData?.data?.length > 500) {
      //   setMode(ModeAction.VIEW);
      //   setOnSearch(false);
      //   messageDisplay({
      //     type: MSG_TYPE.ERROR,
      //     code: "MSTD0024AERR",
      //     msg: ["Search result", "500 records. Please change search criteria."],
      //   });
      //   return false;
      // }
      const data = transformDataWDN97053(searchData?.result?.records);
      setRows(data);
      setOnSearch(true);
      setMode(ModeAction.VIEW);
      return true;
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      stopLoading();
    }
  };
  const handleClear = () => {
    setMsgAlert([]);
    setMsgError([]);

    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setOnSearch(false);
    setRows([]);
    setForm({
      actualDtFrom: "",
      actualDtTo: "",
      deliveryType: "",
      sender: "",
      receiver: "",
      shippingInfo: "",
      containerNo: "",
      deliveryInstrSheetNo: "",
      packStatus: "",
    });
  };
  return (
    <Box>
      <InputButton id="button-search" value={BUTTON_VALUE.SEARCH} onClick={handleSearch} />
      <InputButton
        id="button-clear"
        value={BUTTON_VALUE.CLEAR}
        onClick={handleClear}
        onKeyDown={e => {
          if (!onSearch && e.key === "Tab") {
            document.getElementById("date-picker-actual-from").focus();
          }
        }}
      />
    </Box>
  );
}
