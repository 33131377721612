import { useQuery as useReactQuery } from "@tanstack/react-query";
import axios from "configs/axiosConfig";
import apiClient from "utils/api-client";

const httpMethods = {
  GET: apiClient.get,
  POST: apiClient.post,
  PUT: apiClient.put,
  PATCH: apiClient.patch,
  DELETE: apiClient.delete,
};

const useQuery = ({
  url,
  method = "GET",
  data = {},
  params = {},
  // key = {} ?? undefined,
  enabled = false,
  select = undefined,
  keepPreviousData = false,
  overRideUrl = false,
}) => {
  if (!httpMethods[method]) {
    throw new Error(`Unsupported method: ${method}`);
  }

  const queryKey = [url, method, params, enabled];

  const query = useReactQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const queryUrl = new URL(
        overRideUrl
          ? url
          : process.env.REACT_APP_API_URL +
            process.env.REACT_APP_API_PREFIX +
            url
      );
      Object.entries(params).forEach(([key, value]) => {
        queryUrl.searchParams.append(key, value);
      });

      let axiosClient = await httpMethods[method](queryUrl, data || {});

      if (overRideUrl) {
        axiosClient = await axios.get(url, data || {});
      }

      const response = axiosClient;
      if (select) {
        return select(response.data);
      }
      return response.data;
    },
    enabled: enabled,
    // TODO: timeout
    // staleTime: 1000 * 60, // 60000 มิลลิวินาที (หรือ 1 นาที)
    keepPreviousData: keepPreviousData,
    // options: {
    //   enabled: enabled,
    //   staleTime: 1000 * 60, // 60000 มิลลิวินาที (หรือ 1 นาที)
    //   keepPreviousData: keepPreviousData,
    // },
  });

  return { ...query, refetch: query.refetch };
};

export default useQuery;
