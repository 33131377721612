import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  SubTitle,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, SubTitle, Title, Tooltip, Legend);

export default function LineChart({ id, data, options, height = 300, width = 300 }) {
  return <Line id={id} data-testid={id} height={height} width={width} options={options} data={data} />;
}
