import React, { Fragment, useState } from "react";
import { Box, Divider } from "@mui/material";
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { userProfile } from "constant";
import { useGetDownloadAndUploadMaster } from "service/downloadAndUpload";
import DownloadMaster from "./components/DownloadMaster";
import UploadMaster from "./components/UploadMaster";

const DownloadAndUploadMasterScreen = () => {
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    // upload
    upload: "",
    dataTableSearch: "",
    // download
    download: "",
    year: "",
    companyId: "",
    transportCd: "",
    transportMd: "",
    senderCompId: "",
    receiverCompId: "",
    effMonthFrom: "",
    effMonthTo: "",
    expCompId: "",
    impCompId: "",
    effDtFrom: "",
    effDtTo: "",
    rPkgTypeId: "",
    carFamilyTypeCd: "",
    carFamilyCd: "",
    subSeries: "",
    locationTypeCd: "",
    locationTypeDesc: "",
    rPkgOwnerCompAbbr: "",
    locationGroupId: "",
  });

  // api
  const { data: downloadAndUploadMasterData } = useGetDownloadAndUploadMaster({
    dataOwner: userProfile.dataOwner,
  });

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        {/* Header */}
        <HeaderContentBar title="WDN910B0 : Download and Upload Master Maintenance Screen" />
        {/* Alert Message */}
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Box>
          <UploadMaster
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            downloadAndUploadMasterData={downloadAndUploadMasterData}
            setMsgAlert={setMsgAlert}
            setMsgError={setMsgError}
          />
        </Box>

        <Divider sx={{ my: 4 }} />
        <Box>
          <DownloadMaster
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            downloadAndUploadMasterData={downloadAndUploadMasterData}
            setMsgAlert={setMsgAlert}
            setMsgError={setMsgError}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default DownloadAndUploadMasterScreen;
