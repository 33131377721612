import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import { FORM_DEFAULT_STOCK_VISUALIZATION } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetLocationNameListQuery,
  useReportCommonRPackageCategoryListQuery,
  useExcelDownloadOnlineMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/hooks/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
export default function StockVisualizationReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_STOCK_VISUALIZATION);
  //API
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });
  const { data: locationFromToList, failureReason: locationFailureReason } = useReportCommonGetLocationNameListQuery({
    dataOwner: userProfile.dataOwner,
  });
  const { data: rPackageCategoryList } = useReportCommonRPackageCategoryListQuery();

  useEffect(() => {
    let message = []      
    if(rPkgOwnerFailureReason?.response?.status >= 404){
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`)      
    }
    if(rPkgTypeFailureReason?.response?.status >= 404){
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`)      
    }
    if(locationFailureReason?.response?.status >= 404){
      message.push(`Location From-To List: ${ResponseText[locationFailureReason?.response?.status]}`)      
    }
    props.setMsgServerErrorAlert(message)
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    locationFailureReason?.response?.status,
    props
  ])

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([])
      props.setMsgAlert([])
      const body = {
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        rPackageOwner: form?.rPkgTypeOwner,
        userId: userProfile?.userId,
        locationName: form?.location,
        rPackageCategory: form?.rPkgTypeCategory,
        transactionDateFrom: form?.dateFrom,
        transactionDateTo: form?.dateTo,
        rPackageType: form?.rPkgType,
      };
      const pathReport = "generate-stock-visualization-report";
      // const fileName = `Stock-Visualization_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();    
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      if(error?.response?.status >= 500){
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("input-date-from").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>* Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "LDN960G0", value: "Stock Visualization Report" }]}
            // menu={reportTypeList?.map(val => ({
            //   key: val?.cd,
            //   value: val?.value,
            // }))}
            // value={form?.reportId}
            value={"LDN960G0"}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>* Date(From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-date-from"
            className="input-date-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                dateFrom: e,
              }))
            }
            value={form?.dateFrom}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Date(To) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-date-to"
            className="input-date-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                dateTo: e,
              }))
            }
            value={form?.dateTo}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Location : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location"
            fullWidth
            value={form?.location}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                location: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R - Package Owner: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>R - Package Category: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-category"
            fullWidth
            value={form?.rPkgTypeCategory}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPackageCategoryList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeCategory: e.target.value,
              }))
            }
            // disabled={rPackageCategoryList?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R - Package Type: </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgType,
              value: val?.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }            
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_STOCK_VISUALIZATION}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
      />
    </Grid>
  );
}
