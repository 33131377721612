import React, { useEffect } from "react";
import { Checkbox } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

const InputEditTableCheckbox = ({
  disabled = false,
  color = "default",
  value,
  checked,
  id,
  field,
}) => {
  const apiRef = useGridApiContext();
  const [checkedVal, setCheckedVal] = React.useState(false);
  // const [val, setVal] = React.useState(false);

  // const handleChange = (event) => {
  //   setCheckedVal(event.target.checked);
  // };
  const handleChange = (event) => {
    // const newValue = event.value;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.checked ? "Y" : "N",
    });
    setCheckedVal(event.target.checked);
  };

  useEffect(() => {
    setCheckedVal(value === "Y" ? true : false);
    // apiRef.current.setEditCellValue({ id, field, value: "Y" });
  }, [value]);

  useEffect(() => {
    setCheckedVal(checked);
    // apiRef.current.setEditCellValue({ id, field, value: "Y" });
  }, [checked]);

  return (
    <>
      <Checkbox
        inputProps={{ "aria-label": "controlled" }}
        color={color}
        value={value || "Y"}
        checked={checkedVal}
        onChange={handleChange}
        disabled={disabled}
      />
    </>
  );
};

export default InputEditTableCheckbox;
