import { TableCell, styled, Table } from "@mui/material";

const StyledTable = styled(Table, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== "dark" && prop !== "border",
})(({ theme, dark, border }) => ({
  border: "1.5px solid #7F7F7F",
  borderRadius: ".3rem",
  width: "calc(100% - 15px) !important",
  minWidth: " 0px !important",
  // height: 100%;
  "& .MuiTable-root": {
    minHeight: "400px",
    height: "400px",
  },
  // "& .MuiTableRow-root MuiTableRow-head": {},
  "& .MuiTableCell-head": {
    backgroundColor: "#7F7F7F",
    color: `#FFFF`,
  },
  "& .MuiTableCell-root": { height: "10px", padding: "4px" },
}));

const StyledTableHead = styled("thead")(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  borderLeft: ".5px solid #ffff",
  borderTop: "none",
  overflow: "hidden",
}));
const StyledTableCellHeader = styled("th")(({ theme }) => ({
  backgroundColor: "#7F7F7F",
  color: `#FFFF`,
  lineHeight: "20px",
  textAlign: "center",
}));

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  height: "12px",
  overflow: "hidden",
  whiteSpace: "wrap",
  textOverflow: "ellipsis",
  padding: "5px 6px",
}));

export { StyledTableCellHeader, StyledTable, StyledTableCellBody, StyledTableHead };
