import { Box } from "@mui/material";
import { EditableDropDown, InputEditTableCurrency } from "components/UI";
import { isEmpty } from "lodash";
import { COLUMN_SHIPPING_INFO } from "pages/Invoice/ShippingInformationMaintenanceScreen/constants/table";
import React from "react";
import { ModeAction } from "state/enum";
import { findObject } from "utils";

export default function useColRPackage({ rows, mode, rPackageOwnerList, rPackageTypeList, setFieldTable, setRPkgQty }) {
  return [
    // Col 1
    {
      field: "rowNumber",
      headerName: COLUMN_SHIPPING_INFO.NO,
      headerAlign: "center",
      align: "right",
      width: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params.value;
      },
    },
    // Col 2
    {
      field: "rPkgOwnerId",
      headerName: COLUMN_SHIPPING_INFO.R_PACKAGE_OWNER,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => params?.row?.rPkgOwner,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              required
              defaultValue=""
              placeholder="<Select>"
              autoFocus={true}
              onChange={e => {
                if (isEmpty(e.target.value)) {
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "rPkgOwner",
                    value: "",
                  });
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "rPkgType",
                    value: "",
                  });
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "rPkgTypeId",
                    value: "",
                  });
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "planQty",
                    value: "",
                  });
                  setFieldTable({ rPkgOwnerId: "" });
                  return;
                }
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: "rPkgOwner",
                  value: findObject({
                    data: rPackageOwnerList,
                    value: e.target.value,
                    property: "rPkgOwnerCompId",
                    field: "rPkgOwnerCompAbbr",
                  }),
                });
                setFieldTable({ rPkgOwnerId: e.target.value });
                return;
              }}
              menu={rPackageOwnerList?.map(val => ({ key: val.rPkgOwnerCompId, value: val.rPkgOwnerCompAbbr }))}
            />
          );
        }
        return params?.row?.rPkgOwner;
      },
    },
    { field: "rPkgOwner", sortable: false, editable: true },
    // Col 3
    {
      field: "rPkgTypeId",
      headerName: COLUMN_SHIPPING_INFO.R_PACKAGE_TYPE,
      headerAlign: "center",
      align: "left",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => params?.row?.rPkgType,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              required
              defaultValue=""
              placeholder="<Select>"
              onChange={e => {
                if (isEmpty(e.target.value)) {
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "rPkgType",
                    value: "",
                  });
                  params.api.setEditCellValue({
                    id: params.row.rowNumber,
                    field: "planQty",
                    value: "",
                  });
                  setFieldTable({ rPkgOwnerId: "" });
                  return;
                }
                params.api.setEditCellValue({
                  id: params.row.rowNumber,
                  field: "rPkgType",
                  value: findObject({
                    data: rPackageTypeList,
                    value: e.target.value,
                    property: "rPkgTypeId",
                    field: "rPkgType",
                  }),
                });
              }}
              menu={rPackageTypeList?.map(val => ({ key: val.rPkgTypeId, value: val.rPkgType }))}
            />
          );
        }
        return params?.row?.rPkgType;
      },
    },
    { field: "rPkgType", sortable: false, editable: true },
    // Col 4
    {
      field: "planQty",
      headerName: COLUMN_SHIPPING_INFO.PLAN_QTY,
      headerAlign: "center",
      align: "right",
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      editable: true,
      renderCell: params => {
        return parseInt(params.value).toLocaleString();
      },
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <Box width={"100%"} sx={{ mr: 1 }}>
              <InputEditTableCurrency
                {...params}
                required
                style={{ width: "100%" }}
                maxLimit={100000}
                onChange={e => setRPkgQty(prev => ({ planQty: e.value }))}
              />
            </Box>
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <Box sx={{ justifyContent: "center", alignItems: "center" }}>
              <InputEditTableCurrency
                {...params}
                value={params.value}
                required
                onChange={e => setRPkgQty(prev => ({ planQty: e.value }))}
                style={{ width: "250px" }}
                maxLimit={100000}
              />
            </Box>
          );
        }
      },
    },
    { field: "shippingInfoDContId", sortable: false, editable: false },
    { field: "shippingInfoDPkgId", sortable: false, editable: false },
  ];
}
