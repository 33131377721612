import React, { useEffect, useState } from "react";
import { List, ListItemButton, ListItemText, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DEFAULT_LOCAL_STATE, DEFAULT_LOCAL_STATE_SUMMARY } from "shared/constants";
const StyledListItemButton = styled(ListItemButton)({
  borderRadius: "8px",
  border: "1px solid #000",
  margin: "8px",
  padding: "0px 16px",
  "&:hover ,&.Mui-selected": {
    backgroundColor: "#424242",
    "& .MuiTypography-root": { color: "#000" },
  },
  "&.Mui-selected:hover": { backgroundColor: "#757575", "&.MuiTypography-root": { color: "#000" } },
});
export default function SingleLevel(props) {
  const currentPath = window.location.pathname;

  const { item, depthStep = 2, depth = 0 } = props;
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const handleClick = value => {
    localStorage.setItem("logDetail", JSON.stringify(DEFAULT_LOCAL_STATE));
    localStorage.setItem("logSummary", JSON.stringify(DEFAULT_LOCAL_STATE_SUMMARY));
    navigate(value?.path);
  };
  useEffect(() => {
    if (currentPath !== item?.path) {
      setSelected("");
    } else setSelected(item?.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  return (
    <List key={item.title} disablePadding dense sx={{ display: "block", mt: "4px", mb: "4px" }}>
      <StyledListItemButton key={item.title} selected={selected === item?.title} onClick={() => handleClick(item)}>
        <ListItemText
          primary={item?.title}
          sx={{
            ml: depth * depthStep,
            "& .MuiTypography-root": {
              // textWrap: "balance",
              whiteSpace: "normal",
              fontSize: "14px",
            },
          }}
        />
      </StyledListItemButton>
    </List>
  );
}
