import _, { isEmpty } from "lodash";
import { MessageType } from "state/enum";
import { MessageList } from "state/types/message";
import { isValidDate } from "./date";
import { messageDisplay } from "./message";
import { MSG_TYPE } from "state/enum";
import { getLocalDate, getLocalDateForValidate } from "./init-config-date";
export const validationRequestErrors = ({ columns = [], data = [] }) => {
  if (!columns.length) return;

  const response = {
    isSuccess: true,
    errors: [],
  };

  columns
    .filter(v => !_.isEmpty(v.rule))
    .map(col => {
      const rules = col.rule;
      rules.map(rule => {
        const { type, key } = rule;

        switch (type) {
          case MessageType.EMPTY:
            const field = data[col.field];
            const isArrayField = _.isArray(field);

            if (
              (isArrayField && !_.size(field)) ||
              (!isArrayField && !_.isNumber(field) && _.isEmpty(field)) ||
              field === "" ||
              JSON.stringify(field) === "null"
            ) {
              response.isSuccess = false;
              const output = setTypeMsg(rule);
              response.errors.push(output);
            }

            break;

          case MessageType.LENGTH:
            if (!data[col.field]?.length) {
              const rule = {
                type: MessageType.EMPTY,
                key: key,
              };

              response.isSuccess = false;
              const output = setTypeMsg(rule);
              response.errors.push(output);
            }
            break;

          case MessageType.MORETHAN_ZERO:
            if (parseFloat(data[col.field]) === 0) {
              response.isSuccess = false;
              const output = setTypeMsg(rule);
              response.errors.push(output);
            }
            break;

          case MessageType.MUST_BE_SELECTED:
            if (_.isEmpty(data[col.field])) {
              response.isSuccess = false;
              const output = setTypeMsg(rule);
              response.errors.push(output);
            }
            break;

          case MessageType.INVALID_DATE:
            console.log("data[col.field] val : ", data[col.field]);
            console.log("data[col.field] : ", data[col.field] === "");
            if (!isValidDate(getLocalDate(data[col.field], "DD/MM/YYYY").format("DD/MM/YYYY"), "DD/MM/YYYY")) {
              response.isSuccess = false;
              const output = setTypeMsg(rule);
              response.errors.push(output);
            }
            break;

          default:
            break;
        }
        return null;
      });
      return null;
    });
  return response;
};

export const validationSearchForm = ({ data = [], rule = [] }) => {
  if (!rule.length) return;
  const response = {
    isSuccess: true,
    errors: [],
  };

  rule.map(v => {
    const value = !_.isEmpty(v.field) ? data[v.field] : v.data;

    switch (v.type) {
      case MessageType.EMPTY:
        if (!_.isArray(value) && isEmpty(value)) {
          response.isSuccess = false;

          const output = setTypeMsg(v);
          response.errors.push(output);
        }
        break;

      case MessageType.NOT_FOUND:
        if (_.isArray(value) && !_.size(value)) {
          response.isSuccess = false;

          const output = setTypeMsg(v);
          response.errors.push(output);
        }
        break;
      // check MM/YYYY empty and invalid format 45
      case MessageType.MONTH_INVALID_45:
        if (!_.isArray(value) && isEmpty(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        } else if (!getLocalDateForValidate(value, "MM/YYYY", true)) {
          // } else if (!getLocalDateForValidate(value, "MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0045AERR",
            msg: [v.key[0], "MM/YYYY"],
          });
          response.errors.push(output);
        }
        break;
      // check MM/YYYY empty and invalid format 50
      case MessageType.MONTH_INVALID_50:
        if (!_.isArray(value) && isEmpty(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        } else if (!getLocalDateForValidate(value, "MM/YYYY", true)) {
          // } else if (!getLocalDateForValidate(value, "MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0050AERR",
            msg: [v.key[0], "MM/YYYY"],
          });
          response.errors.push(output);
        }
        break;
      // check DD/MM/YYYY empty and invalid format 50
      case MessageType.DATE_INVALID_50:
        if (!_.isArray(value) && isEmpty(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        } else if (!getLocalDateForValidate(value, "DD/MM/YYYY", true)) {
          // } else if (!getLocalDateForValidate(value, "DD/MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0050AERR",
            msg: [v.key[0], v.key[1]],
          });
          response.errors.push(output);
        }
        break;
      // check DD/MM/YYYY empty and invalid format 45
      case MessageType.DATE_INVALID_45:
        if (!_.isArray(value) && isEmpty(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        } else if (!getLocalDateForValidate(value, "DD/MM/YYYY", true)) {
          // } else if (!getLocalDateForValidate(value, "DD/MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0045AERR",
            msg: [v.key[0], v?.key[1]],
          });
          response.errors.push(output);
        }
        break;
      // check only invalid format 50
      case MessageType.DATE_FORMAT_50:
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        if (!getLocalDateForValidate(value, "DD/MM/YYYY", true)) {
          // if (!getLocalDateForValidate(value, "DD/MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0050AERR",
            msg: [v?.key[0], v?.key[1]],
          });
          response.errors.push(output);
        }
        break;
      // check only invalid format 45
      case MessageType.DATE_FORMAT_45:
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        if (!getLocalDateForValidate(value, "DD/MM/YYYY", true)) {
          // if (!getLocalDateForValidate(value, "DD/MM/YYYY", true).isValid()) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0045AERR",
            msg: [v?.key[0], v?.key[1]],
          });
          response.errors.push(output);
        }
        break;
      // check date is equal or later current
      // MSTD0019AERR 1.5.3
      case MessageType.DATE_EQUAL_LATER_CURRENT:
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        const currentDate = getLocalDate();
        const dateValue = getLocalDate(value, "DD/MM/YYYY");
        if (dateValue.isBefore(currentDate, "day")) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: [v?.key[0], v?.key[1]],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.DATE_EQUAL_LATER:
        const dateTo = isEmpty(v?.key[2]) ? "" : v?.key[2];
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        if (!_.isArray(dateTo) && isEmpty(dateTo)) {
          break;
        }
        const dateFrom = getLocalDate(value, "DD/MM/YYYY");
        if (getLocalDate(dateTo, "DD/MM/YYYY").isBefore(dateFrom, "day")) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0019AERR",
            msg: [v?.key[0], v?.key[1]],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.ARRAY_EMPTY:
        if (!value?.length) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        }
        break;

      case MessageType.NUMERIC_EMPTY:
        if (isNaN(parseInt(value))) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.INPUT_INVALID_STRING:
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        if (!isValidWildCardPattern(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0043AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.EQUAL_GREATER_ZERO:
        const VALUE_LESS_THAN_ONE = 1;
        if (!_.isArray(value) && isEmpty(value)) {
          break;
        }
        const parseValue = typeof value === "string" ? parseInt(value) : value;
        if (parseValue < VALUE_LESS_THAN_ONE) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0018AERR",
            msg: [v.key[0], v.key[1]],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.GREATER_ZERO:
        const pValue = typeof value === "string" ? parseInt(value) : value;
        if (isNaN(value)) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0031AERR",
            msg: [v.key[0]],
          });
          response.errors.push(output);
        } else if (pValue < 1) {
          response.isSuccess = false;
          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0021AERR",
            msg: [v.key[0], v.key[1]],
          });
          response.errors.push(output);
        }

        break;
      default:
        break;
    }
    return null;
  });
  return response;
};

export const validationUploadFile = ({ file = undefined, rule = [] }) => {
  if (!file) return;
  if (!rule.length) return;

  const response = {
    isSuccess: true,
    errors: [],
  };
  rule.map(v => {
    switch (v.type) {
      case MessageType.TYPE_IMAGE:
        const mimeType = ["image/jpeg", "image/png", "image/jpg"];
        if (!mimeType.includes(file.type)) {
          response.isSuccess = false;

          const output = setTypeMsg(v);
          response.errors.push(output);
        }
        break;

      case MessageType.SIZE_IMAGE:
        const maxSizeInBytes = v.maxSize * (1024 * 1024); // MB

        if (file.size > maxSizeInBytes) {
          response.isSuccess = false;

          const output = setTypeMsg(v);
          response.errors.push(output);
        }
        break;
      case MessageType.TYPE_EXCEL:
        const mimeTypeExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // MB
        if (!mimeTypeExcel.includes(file.type)) {
          response.isSuccess = false;

          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD7013BERR",
            msg: [`${file?.name}`, "Excel"],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.TYPE_PDF:
        const mimeTypePDF = "application/pdf";
        if (![mimeTypePDF, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)) {
          response.isSuccess = false;

          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD7013BERR",
            msg: [`${file?.name}`, "Excel, PDF"],
          });
          response.errors.push(output);
        }
        break;
      case MessageType.FILE_NAME:
        const fileName = file.name.replace(".xlsx", "");
        if (fileName.length > v.key[1]) {
          response.isSuccess = false;
          const output = validateFileName(v);
          response.errors.push(output);
        }
        break;
      case "TYPE_EXCEL_MSTD1048AERR":
        if (!["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)) {
          response.isSuccess = false;

          const output = messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD1048AERR",
            msg: [""],
          });
          // const output = setTypeMsg(v);
          response.errors.push(output);
        }
        break;
      default:
        break;
    }

    return null;
  });

  return response;
};

const setTypeMsg = rule => {
  const obj = MessageList.find(v => v.key === rule.type);
  if (!_.isEmpty(rule.key)) {
    let modifiedMessage = obj.message;

    rule?.key?.forEach((value, index) => {
      const placeholder = `[${index}]`;
      modifiedMessage = modifiedMessage.replace(placeholder, value);
    });

    return `${obj.code}: ${modifiedMessage}`;
  }
  return `${obj.code}: ${obj.message}`;
};
const validateFileName = rule => {
  const obj = MessageList.find(v => v.key === rule.type);
  if (!_.isEmpty(rule.key)) {
    let modifiedMessage = obj.message;
    rule?.key?.forEach((value, index) => {
      const placeholder = `[${index}]`;
      modifiedMessage = modifiedMessage.replace(placeholder, value);
    });
    return `${obj.code}: ${modifiedMessage}`;
  }
  return `${obj.code}: ${obj.message}`;
};

export const isValidWildCardPattern = strWildCard => {
  if (!strWildCard || strWildCard.trim() === "") {
    return true;
  }
  if (strWildCard.trim() === "*") {
    return false;
  }
  if (strWildCard.trim().startsWith("*")) {
    return false;
  }
  const trimmedStr = strWildCard.trim();
  if (trimmedStr.indexOf("*") > 0 && trimmedStr.indexOf("*") < trimmedStr.length - 1) {
    return false;
  }
  return true;
};
