import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryOnMount: false,
      refetchOnWindowFocus: false,
      // TODO: timeout
      staleTime: 2 * 60 * 1000,
      retry: 3,
      retryDelay: 3000,
    },
  },
});

export default queryClient;
