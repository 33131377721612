export const API_NAME_APMC = {
  ROW_NUMBER: "rowNumber",
  FORECAST_MONTH: "forecastMonth",
  DATA_TYPE: "dataType",
  DATA_TYPE_CD: "dataTypeCd",

  MANUAL_UPLOAD_INVOICE_FLAG: "manualUploadInvFlag",
  UPLOAD_IMP_EXP_CD: "uploadImpExpCd",
  UPLOAD_STATUS_CD: "uploadStatusCd",
  UPLOAD_STATUS: "uploadSts",
  UPLOAD_DATE: "uploadDt",
  UPLOAD_BY: "uploadBy",
  USE_STATUS: "useSts",
  USE_DATE: "useDt",
  USE_BY: "useBy",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  API_ID: "aplId",
  MODE_CD: "modeCd",
  MODE: "mode",
  DATA_OWNER: "dataOwner",
  USER_ID: "userId",
  COMPANY_ABBR: "companyAbbr",
  CREATE_BY: "createBy",
  PLAN_TEMPLATE_NAME: "planTemplateName",
  IMP_EXP_CD: "impExpCd",
  ORG_FILE_NAME: "orgFileName",
  FILE_UPLOAD_ID: "fileUploadId",
  FILE: "file",
  FILE_NAME: "fileName",

  MODULE_ID: "moduleId",
  FUNCTION_ID: "functionId",
  D_HODTCRE: "dHODTCRE",
};
