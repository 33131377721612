import React from "react";
import { COLUMN_NAME } from "../constants";
import { API_SYSTEM_MASTER } from "shared/constants";
import { ModeAction } from "state/enum";
import { EditableTextField, EditableDropDown } from "components/UI";
const useColumns = props => {
  const { rows, mode, statusList, form } = props;
  return [
    // Col 1
    {
      field: API_SYSTEM_MASTER.ROW_NUMBER,
      headerName: COLUMN_NAME.NO,
      headerAlign: "center",
      align: "center",
      width: 50,
      flex: 0.1,
      minWidth: 50,
      sortable: false,
      editable: false,
      renderCell: params => {
        if (params?.row?.rowNumber === rows.slice(-1)?.[0]?.rowNumber && ModeAction.ADD === mode) {
          return <div></div>;
        }
        return params?.value;
      },
    },
    // Col 2
    {
      field: API_SYSTEM_MASTER.CATEGORY,
      headerName: COLUMN_NAME.CATEGORY,
      headerAlign: "center",
      align: "left",
      width: 100,
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (ModeAction.ADD.includes(mode)) {
          return (
            <EditableTextField
              required
              {...params}
              // regularExp={/^[\w]{0,40}$/}
              maxLength={40}
              onUpperCase={true}
              multiline={true}
              autoFocus={true}
              // onKeyDown={event => ["_"].includes(event.key) && event.preventDefault()}
            />
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 3
    {
      field: API_SYSTEM_MASTER.SUB_CATEGORY,
      headerName: COLUMN_NAME.SUB_CATEGORY,
      headerAlign: "center",
      align: "left",
      width: 200,
      flex: 0.1,
      minWidth: 200,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableTextField
              required
              {...params}
              maxLength={40}
              onUpperCase={true}
              multiline={true}
              // regularExp={/^[\w]{0,40}$/}
              // onKeyDown={event => ["_"].includes(event.key) && event.preventDefault()}
            />
          );
        } else {
          return params?.value;
        }
      },
    },

    // Col 4
    {
      field: API_SYSTEM_MASTER.CODE,
      headerName: COLUMN_NAME.CODE,
      headerAlign: "center",
      align: "left",
      width: 100,
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableTextField
              required
              {...params}
              maxLength={40}
              onUpperCase={true}
              multiline={true}
              // regularExp={/^[\w]{0,40}$/}
              // onKeyDown={event => ["_"].includes(event.key) && event.preventDefault()}
            />
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 5
    {
      field: API_SYSTEM_MASTER.VALUE,
      headerName: COLUMN_NAME.VALUE,
      headerAlign: "center",
      align: "left",
      width: 150,
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableTextField
              required
              {...params}
              maxLength={200}
              multiline={true}
              // regularExp={/^[\w]{0,200}$/}
              // onKeyDown={event => ["_"].includes(event.key) && event.preventDefault()}
            />
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <EditableTextField
              {...params}
              required
              value={params?.row?.value}
              maxLength={200}
              multiline={true}
              autoFocus={true}
              // regularExp={/^[\w]{0,200}$/}
              // onKeyDown={event => ["_"].includes(event.key) && event.preventDefault()}
            />
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 6
    {
      field: API_SYSTEM_MASTER.REMARK,
      headerName: COLUMN_NAME.REMARK,
      headerAlign: "center",
      align: "left",
      width: 200,
      flex: 0.1,
      minWidth: 200,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return <EditableTextField {...params} maxLength={200} multiline={true} />;
        } else if (mode === ModeAction.EDIT) {
          return <EditableTextField {...params} value={params?.row?.remark} maxLength={200} multiline={true} />;
        } else {
          return params?.value;
        }
      },
    },
    // Col 7
    {
      field: API_SYSTEM_MASTER.STATUS,
      headerName: COLUMN_NAME.STATUS,
      headerAlign: "center",
      align: "left",
      width: 150,
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if (mode === ModeAction.ADD) {
          return (
            <EditableDropDown
              {...params}
              defaultValue=""
              menu={statusList?.map(val => ({
                key: val.status,
                value: val.status,
              }))}
              placeholder=""
            />
          );
        } else if (mode === ModeAction.EDIT) {
          return (
            <EditableDropDown
              {...params}
              defaultValue=""
              value={params?.row?.status}
              menu={statusList?.map(val => ({
                key: val?.status,
                value: val?.status,
              }))}
              placeholder=""
            />
          );
        } else {
          return params?.value;
        }
      },
    },
    // Col 8
    {
      field: API_SYSTEM_MASTER.CREATE_BY,
      headerName: COLUMN_NAME.CREATE_BY,
      headerAlign: "center",
      align: "left",
      width: 150,
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if ([ModeAction.ADD].includes(mode)) {
          return <div>{form?.userId}</div>;
        } else {
          return params?.value;
        }
      },
    },
    // Col 9
    {
      field: API_SYSTEM_MASTER.CREATE_DATE,
      headerName: COLUMN_NAME.CREATE_DATE,
      headerAlign: "center",
      align: "left",
      width: 200,
      flex: 0.1,
      minWidth: 200,
      sortable: false,
      editable: false,
    },
    // Col 10
    {
      field: API_SYSTEM_MASTER.UPDATE_BY,
      headerName: COLUMN_NAME.UPDATE_BY,
      headerAlign: "center",
      align: "left",
      width: 150,
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      editable: true,
      renderEditCell: params => {
        if ([ModeAction.ADD].includes(mode)) {
          return <div>{form?.userId}</div>;
        } else {
          return params?.value;
        }
      },
    },
    {
      field: API_SYSTEM_MASTER.UPDATE_DATE,
      headerName: COLUMN_NAME.UPDATE_DATE,
      headerAlign: "center",
      align: "left",
      width: 200,
      flex: 0.1,
      minWidth: 200,
      sortable: false,
      editable: false,
    },
  ];
};
export default useColumns;
