import React, { Fragment, useEffect, useState } from "react";
//Component
import { HeaderContentBar } from "components/Layout";
import { Box, Stack, Link, Divider } from "@mui/material";
import { AlertMessage, PopupDialog } from "components/UI";
import RPackageSubScreen from "pages/Invoice/ShippingInformationMaintenanceScreen/components/R-PackageSubScreen/RPackageSubScreen";
import DetailSection from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/DetailSection";
import ButtonContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/ButtonContainer";
import TableContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/components/ContainerSubScreen/components/TableContainer";
//util
import { transformDataContainer } from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
import { isEmpty } from "lodash";
import { messageDisplay, responseErrors } from "utils";
// Hook
import { useLoadingContext } from "shared/hooks/LoadingContext";
import useColContainer from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/useColContainer";
import { useConfirmDialogContext } from "context/confirmDialogContext";

// Service
import {
  useContainerGetActualRPkgMutation,
  useRPackageSearchMutation,
  useRPackageGroupCodeGetMutation,
  useShippingInfoOrderTypeQuery,
} from "shared/services/invoice";
// Type
import { COMMON_ENUM, FIRST_PAGE, PAGINATION } from "shared/constants";
import { MSG_TYPE, ModeAction } from "state/enum";
import { userProfile } from "constant";
import ConfirmMatching from "./components/ConfirmMatching";

export default function ContainerSubScreen({
  modeOfWDN95030,
  onOpen,
  setOnOpen,
  form,
  setForm,
  searchCont,
  setSearchCont,
}) {
  const { startLoading, stopLoading } = useLoadingContext();
  const ROW_PER_PAGE_TWENTY = 20; // default
  const confirmDialogCtx = useConfirmDialogContext();

  const [dataRPkg, setDataRPkg] = useState({});
  const [rPkgQty, setRPkgQty] = useState({});
  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [mode, setMode] = useState(ModeAction.VIEW);
  // Table
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);
  // Api
  // const getGroupCdRPkg = useRPackageGroupCodeGetMutation();
  const actualRPkg = useContainerGetActualRPkgMutation();
  const searchRPackage = useRPackageSearchMutation();
  const { data: orderTypeList } = useShippingInfoOrderTypeQuery();
  // State r-package sub screen
  const [openPopupRPackageSub, setOpenPopupRPackageSub] = useState(false);

  const handleOpenRPackageSubScreen = async (row, containerType) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      // If add mode : User need to fill container no.
      const containerNo = containerType === "P" ? row?.planContainerNo : row?.actualContainerNo;
      if (isEmpty(containerNo)) {
        const message = messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0031AERR", msg: ["Container No."] });
        setMsgError([message]);
        return;
      }
      setForm(prev => ({
        ...prev,
        containerNo: containerNo,
        containerType: containerType,
        dataOwner: userProfile.dataOwner,
        vanInfoHdrID: row?.vanningInfoHId,
        shippingInfoDContId: row?.shippingInfoDContId,
        sesionnId: "",
      }));

      const body = {
        shippingInfoDContId: row?.shippingInfoDContId,
        vanningInfoHId: row?.vanningInfoHId, // optional
        containerNo: containerNo,
        containerType: containerType, // P = Plan, A = Actual
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
        sesionnId: "",
      };
      startLoading();
      try {
        const searchData = await searchRPackage(body);
        setDataRPkg(searchData);
        // set state for pass data to popup r-package sub
        setOpenPopupRPackageSub(true);
      } catch (error) {
        const errors = responseErrors(error);
        setMsgError(errors);
      }
      stopLoading();
      // set state for pass data to popup r-package sub
      setOpenPopupRPackageSub(true);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const columns = React.useMemo(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useColContainer({
      rows,
      mode,
      handleOpenRPackageSubScreen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, mode]);

  const handleClose = async () => {
    const confirm = await confirmDialogCtx.success({ type: "closeScreen" });
    if (!confirm) {
      return;
    }
    setOnOpen(false);
  };
  const handleSearch = async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      // Reset Data of Table
      setPageNumber(FIRST_PAGE);
      setPagination({});

      setMode(ModeAction.VIEW);
      setOnSearch(false);

      setRows([]);
      setRowSelectionModel([]);
      setRowModesModel({});
      //TODO - Update temp table contd
      //! check have form?
      const searchData = searchCont;
      if (!searchData?.result?.data?.length) {
        setForm(prev => ({ ...prev, rows: [] }));
        return;
      }
      const data = transformDataContainer(searchData?.result?.data, orderTypeList);
      setForm(prev => ({ ...prev, rows: data }));
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);

      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const getSumQty = async row => {
    try {
      let sumRMQty = 0;
      let sumOTQty = 0;

      const actualRPkgList = await actualRPkg({
        vanningInfoHId: row?.vanningInfoHId,
        planContainerNo: row?.actualContainerNo,
      });
      if (!actualRPkgList?.result?.length) {
        sumRMQty += 0;
        sumOTQty += 0;
      } else {
        console.log("has data actualRPkgList");
        actualRPkgList?.result?.forEach(async rPkg => {
          if (rPkg?.groupCd === COMMON_ENUM.C_R_MODULE) {
            sumRMQty += parseInt(rPkg?.qty);
          } else {
            sumOTQty += parseInt(rPkg?.qty);
          }
        });
      }
      // let isUpdated = false;
      // const updatedRPkg = rPkgQty?.map(item => {
      //   if (item?.rowNumber === row.rowNumber) {
      //     isUpdated = transformDataContainer();
      //     sumRMQty += item?.actualQty.sumRMQty;
      //     sumOTQty += item?.actualQty.sumOTQty;
      //     return { ...row, actualQty: { sumRMQty, sumOTQty } };
      //   }
      //   return item;
      // });
      // console.log(sumRMQty, sumOTQty);
      // setRPkgQty(isUpdated ? updatedRPkg : prev => [...prev, { ...row, actualQty: { sumRMQty, sumOTQty } }]);

      return { sumRMQty, sumOTQty };
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
    }
  };

  useEffect(() => {
    try {
      handleSearch();
    } catch (error) {
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
    return;
  }, [1]);
  return (
    <Fragment>
      <Stack>
        <Stack>
          <Link href="#" color="#000" onClick={handleClose} size="small" sx={{ fontSize: "12px" }}>
            Close
          </Link>
        </Stack>
        <Stack mb={2}>
          {/* <Stack sx={{ padding: "1em" }}> */}
          <HeaderContentBar title="WDN95031 : Shipping Information Maintenance - Container Sub Screen" />
          <AlertMessage type={"warning"} message={msgError} />
          <AlertMessage type={"success"} message={msgAlert} />
        </Stack>
        <Stack sx={{ pl: "1rem", pr: "1rem" }}>
          <Box>
            <DetailSection onRPkgScreen={false} form={form} />
          </Box>
          <Box width="100%" sx={{ mb: 2 }}>
            <Divider />
          </Box>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ButtonContainer
              mode={mode}
              setMode={setMode}
              modeOfWDN95030={modeOfWDN95030}
              setOnSearch={setOnSearch}
              rows={rows}
              setRows={setRows}
              columns={columns}
              form={form}
              setForm={setForm}
              getSumQty={getSumQty}
              setMsg={{ setMsgAlert, setMsgError }}
              pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
              rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
            />
          </Box>
          <Box sx={{ height: `${onSearch ? 0 : "300px"}` }}>
            {onSearch && (
              <>
                <Box>
                  <TableContainer
                    mode={mode}
                    setMode={setMode}
                    rows={rows}
                    setRows={setRows}
                    columns={columns}
                    form={form}
                    setForm={setForm}
                    getSumQty={getSumQty}
                    rPkgQty={rPkgQty}
                    setRPkgQty={setRPkgQty}
                    setOnSearch={setOnSearch}
                    setMsg={{ setMsgAlert, setMsgError }}
                    rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
                    pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
                  />
                </Box>
                <ConfirmMatching
                  form={form}
                  setForm={setForm}
                  rows={rows}
                  setRows={setRows}
                  rPkgQty={rPkgQty}
                  setRPkgQty={setRPkgQty}
                  getSumQty={getSumQty}
                  mode={mode}
                  modeOfWDN95030={modeOfWDN95030}
                  setOnOpen={setOnOpen}
                  setMsg={{ setMsgAlert, setMsgError }}
                  rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
                />
              </>
            )}
          </Box>
        </Stack>

        <PopupDialog
          id="rPkg"
          maxWidth="lg"
          onFooter={true}
          onOpen={openPopupRPackageSub}
          content={
            <RPackageSubScreen
              onOpen={openPopupRPackageSub}
              setOnOpen={setOpenPopupRPackageSub}
              form={form}
              setForm={setForm}
              modeOfWDN95031={mode}
              dataRPkg={dataRPkg}
              setDataRPkg={setDataRPkg}
              rPkgQty={rPkgQty}
              setRPkgQty={setRPkgQty}
            />
          }
        />
      </Stack>
    </Fragment>
  );
}
