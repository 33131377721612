import React from "react";
import { TextField, styled } from "@mui/material";
import InputMask from "react-input-mask";

const StyledTextField = styled(TextField)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    borderRadius: ".3rem",
    backgroundColor: theme.palette.primary.light,
    textAlign: "left",
    padding: "5px 10px",
  },
  "& .MuiInput-root:focus": {
    border: `2px solid black !important`,
    borderRadius: ".3rem",
    color: "#000",
  },
}));

// maskChar :this character can be used to fill the missing part of the input string; the default value is _ (underscore)
// formatChars: you can change the default characters and regular expressions used for masking (in the mask property) by changing this property
// alwaysShowMask : the library usually shows the mask when the user focuses on the masked input; set this property to true if you always want to show the mask
export default function InputMasks({
  id,
  mask,
  value,
  maskChar = null,
  alwaysShowMask = false,
  formatChars,
  disabled = false,
  onChange,
  autoFocus = false,
  ...rest
}) {
  return (
    <InputMask
      {...rest}
      data-testid={id}
      id={id}
      mask={mask}
      formatChars={formatChars}
      value={value}
      onChange={onChange}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      disabled={disabled}
      autoFocus={autoFocus}>
      {inputProps => <StyledTextField disabled={disabled} {...inputProps} />}
    </InputMask>
  );
}
