export const COLUMN_NAME = {
  ROW_NUMBER: "Row Number",
  NO: "No",
  FIELD_NAME: "Field",
  DISPLAY_NAME: "Display Name",
  ORDER_DISP: "Ord.Disp.",
  PK_OPTION: "PK",
  DATE_TYPE: "Data Type",
  CRITERIA: "Criteria",
  LOGICAL_OPR: "Log.Opr.",
  DISP_OPT: "Disp.Opt.",
  SORT: "Sort",
};
