import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, InputTextField } from "components/UI";
import { API_ON_DEMAND_BATCH, FIRST_PAGE } from "shared/constants";
import { ModeAction } from "state/enum";

export default function FilterBar(props) {
  const {
    form,
    setForm,
    setRows,
    setMode,
    setOnSearch,
    setRowModesModel,
    setRowSelectionModel,
    setPagination,
    setPageNumber,
    dataList: { projectList, batchList },
  } = props;
  const handleProjectCode = event => {
    setForm(prev => ({
      ...prev,
      [API_ON_DEMAND_BATCH.PROJECT_CODE]: event.target.value,
      [API_ON_DEMAND_BATCH.BATCH_NAME]: "",
      [API_ON_DEMAND_BATCH.BATCH_ID]: "",
    }));
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRows([]);
    setRowModesModel({});
    setRowSelectionModel([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    return;
  };

  const handleBatchName = event => {
    setForm(prev => ({
      ...prev,
      [API_ON_DEMAND_BATCH.BATCH_NAME]: event.target.value,
      [API_ON_DEMAND_BATCH.BATCH_ID]:
        event.target.value !== "" ? batchList.find(v => v.batchName === event.target.value).batchId : "",
    }));
    setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRows([]);
    setRowModesModel({});
    setRowSelectionModel([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    return;
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Project Code:</strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="input-project-code"
            value={form?.projectCode}
            onChange={handleProjectCode}
            menu={projectList?.map(val => ({
              key: val.projectCode,
              value: val.projectCode,
            }))}
            disabled={false}
            required
            autoFocus={true}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ alignItems: "center", mt: 1 }}>
        <Grid item xs={2}>
          <strong>Batch Name:</strong>
        </Grid>
        <Grid item xs={4}>
          <DropDown
            id="input-batch-name"
            value={form?.batchName}
            onChange={handleBatchName}
            menu={batchList?.map(val => ({
              key: val?.batchName,
              value: val?.batchName,
            }))}
            disabled={false}
            defaultValue=""
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={1}>
          <strong>Batch ID:</strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-batch-id"
            fullWidth
            value={form?.batchId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_ON_DEMAND_BATCH.BATCH_ID]: e.target.value,
              }));
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
