import { Box } from "@mui/material";
import * as React from "react";

const Footer = ({ width = "none" }) => {
  return (
    <>
      <Box className="area-footer" width={width} position="fixed" sx={{ borderTop: "1px solid #C4C4C4" }}>
        Polygon &copy; 2024 version 1.0
      </Box>
    </>
  );
};
// {/* &copy; 2024 -TOYOTA MOTOR ASIA (THAILAND) CO., LTD. All Rights Reserved */}

export default Footer;
