import { useCustomQuery, useMutateData } from "shared/services/base.service";
const BaseServicePath = process.env.REACT_APP_API_REPORT;

const ReportTransactionApiService = () => ({
  useReportGetTransactionModeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-transaction-mode`,
      method: "POST",
      enabled: true,
      select: (data) => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  useReportGetTransactionCategoryQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/report-common/get-transaction-category`,
      method: "POST",
      enabled: true,
      select: (data) => data.result,
      keepPreviousData: true,
    });
    return data;
  },
});

export const {
  useReportGetTransactionModeQuery,
  useReportGetTransactionCategoryQuery,
} = ReportTransactionApiService();
