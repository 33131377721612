export const API_ON_DEMAND_BATCH = {
  ROW_NUMBER: "rowNumber",
  APP_ID: "appId",
  PROJECT_CODE: "projectCode",
  BATCH_ID: "batchId",
  BATCH_NAME: "batchName",
  PRIORITY_LEVEL: "priorityLevel",
  CONCURRENCY_FLAG: "concurrencyFlag",
  RUNNING_COUNT: "runningCount",
  RUN_AS: "runAs",
  END_POINT: "endPoint",
  SUPPORT_ID: "supportId",
  SCHEMA: "schema",
  CREATE_BY: "createBy",
  CREATE_DATE: "createDt",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  USER_ID: "userId",
  DATA_OWNER: "dataOwner",
};
