import React from "react";
import { Box, Divider, Grid } from "@mui/material";
export default function LabelGraph() {
  return (
    <Box sx={{}}>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={2}>
          <strong style={{ color: "#d32f2f" }}>-------- </strong>
          Max
        </Grid>
        <Grid item xs={7}>
          <strong style={{ color: "#000000" }}>-------- </strong>
          Ending Balance + (Confirmed + Allocated Status)
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <strong style={{ color: "#43a047" }}>-------- </strong>
          Min
        </Grid>
        <Grid item xs={5}>
          <strong style={{ color: "#2196f3" }}>-------- </strong>
          Updated Ending
        </Grid>
      </Grid>
    </Box>
  );
}
