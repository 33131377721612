import dayjs from "dayjs";
import { isEmpty } from "lodash";

// const changeConfigDate = diffConfig => {
//   const diffDt = diffConfig ?? process.env.REACT_APP_CONFIG_DATE;

//   const diffDate = diffDt ? parseInt(diffDt) : 0;

//   const configDateStr = dayjs().subtract(diffDate, "day").format("YYYY-MM-DD");

//   const configYear = new Date(configDateStr).getFullYear();
//   const configMonth = new Date(configDateStr).getMonth();
//   const configDate = new Date(configDateStr).getDate();
//   const isValidConfigDate = diffConfig && configDateStr ? dayjs(configDateStr).isValid() : false;
//   const originalDate = global.Date;

//   if (isValidConfigDate) {
//     console.log("[DEVELOPMENT ONLY] changed config date to", configDateStr);
//   } else {
//     console.log("[PRODUCTION] not change config date today is ", dayjs().format(`YYYY-MM-DD`));
//   }
//   class CustomDate extends Date {
//     constructor(...args) {
//       const currentDate = new originalDate();

//       if (isValidConfigDate) {
//         currentDate.setDate(configDate);
//         currentDate.setMonth(configMonth);
//         currentDate.setFullYear(configYear);
//       }

//       const utcDate = new originalDate(
//         Date.UTC(
//           currentDate.getFullYear(),
//           currentDate.getMonth(),
//           currentDate.getDate(),
//           currentDate.getHours(),
//           currentDate.getMinutes(),
//           currentDate.getSeconds()
//         )
//       );

//       if (!args.length) {
//         return super(utcDate);
//       }

//       /* @ts-ignore */
//       super(...args);
//     }
//   }

//   global.Date = CustomDate;
// };

// export const initConfigDate = diffConfig => {
//   console.log("========= start initConfigDate =========");

//   console.log(new Date(), " <--- new Date() before");
//   console.log(dayjs().format(), " <--- dayjs().format() before");

//   changeConfigDate(diffConfig);

//   console.log(new Date(), "<--- new Date() after");
//   console.log(dayjs().format(), "<--- dayjs().format() after");
// };

export const getLocalDateStr = () => {
  return dayjs().format("DD/MM/YYYY");
};

export const getLocalDate = (date, format = "DD/MM/YYYY") => {
  if (!isEmpty(date)) {
    // console.log(dayjs(date, format));
    return dayjs(date, format);
  }
  return dayjs();
};
export const getLocalDateForValidate = (date, format = "DD/MM/YYYY", strickCheck) => {
  if (!isEmpty(date)) {
    return dayjs(date, format, strickCheck).isValid();
  }
  return false;
};
