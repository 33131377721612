import React from "react";
import { Box, Grid, Link } from "@mui/material";
import WareHouseGraph from "./WareHouseGraph";
export default function GraphSection({ graphData, setOpenGraph }) {
  return (
    <Box>
      <Link href="#" color="#000" onClick={() => setOpenGraph(false)} size="small" sx={{ fontSize: "12px" }}>
        Close
      </Link>
      <Grid
        container
        spacing={2}
        columns={{ xs: 6 }}
        justifyContent="space-around"
        alignItems="center"
        sx={{
          height: 400,
          width: "auto",
          overflowX: "inherit",
          overflowY: "scroll",
        }}>
        {graphData?.map((wh, index) => (
          <Grid container key={index} rowGap={2} item xs={3} id="graph-section" data-testid="graph-section">
            {wh.rPkgTypeList.map((rType, index) => (
              <WareHouseGraph
                key={index}
                dataSet={rType.dateQtyList}
                subtitle={rType.rPkgType}
                title={`${wh?.whCompanyAbbr}:${wh?.whImpExpCd}`}
              />
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
