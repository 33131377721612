import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { DropDown, InputMask } from "components/UI";
// Constant and Type
import EtDatePickerBar from "pages/Forecast/ShipmentManagementScreen/components/EtDatePickerBar";
import { ModeAction } from "state/enum";
import { API_SHIPMENT } from "shared/constants";
export default function GroupingFilterBar(props) {
  const {
    mode,
    form,
    setForm,
    dataList: { exporterList, importerList, rPkgList, statusList },
  } = props;

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ mb: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Exporter :</strong>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="select_exporter"
            required
            value={form.exporterId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.EXPORTER_ID]: e.target.value,
              }));
            }}
            menu={exporterList?.map(val => ({
              key: val.impExpCd,
              value: val.impExpCd,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={1}>
          <strong>*Importer :</strong>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="select_importer"
            required
            value={form.importerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.IMPORTER_ID]: e.target.value,
              }));
            }}
            menu={importerList?.map(val => ({
              key: val.companyId,
              value: val.companyAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            defaultValue=""
            placeholder="<Select>"
          />
        </Grid>
        <Grid item xs={2}>
          <strong>*R-Package Owner :</strong>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="select_r_pkg_owner"
            required
            value={form.rPkgOwnerId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.R_RKG_OWNER_ID]: e.target.value,
              }));
            }}
            menu={rPkgList?.map(val => ({
              key: val.rPkgOwnerCompId,
              value: val.rPkgOwnerCompAbbr,
            }))}
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<Select>"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2, alignItems: "center" }}>
        <Grid item xs={2}>
          <strong>*Forecast Month :</strong>
        </Grid>
        <Grid item xs={1}>
          <InputMask
            id="input-monthly-month"
            mask="mM/YYYY"
            fullWidth={true}
            formatChars={{
              Y: "[0-9]",
              m: "[0-1]",
              M: "[0-9]",
            }}
            maskChar={null}
            alwaysShowMask={false}
            value={form?.forecastMonth}
            onChange={e =>
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.FORECAST_MONTH]: e.target.value,
              }))
            }
            required
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
          />
        </Grid>
        <Grid item xs={1}>
          <strong>MM/YYYY</strong>
        </Grid>
        <Grid item xs={1}>
          <strong>Status :</strong>
        </Grid>
        <Grid item xs={2}>
          <DropDown
            id="select_shipment_status"
            value={form?.shipmentStsId}
            onChange={e => {
              setForm(prev => ({
                ...prev,
                [API_SHIPMENT.SHIPMENT_STATUS_ID]: e.target.value,
              }));
            }}
            menu={statusList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            defaultValue=""
            disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            placeholder="<All>"
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <EtDatePickerBar form={form} setForm={setForm} />
    </Fragment>
  );
}
