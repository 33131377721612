export const API_LOG_MONITORING = {
  //BODY
  DATA_OWNER: "dataOwner",
  MODULE_ID: "moduleId",
  STATUS_ID: "statusId",
  STATUS: "status",
  LEVEL_ID: "levelId",
  USER_ID: "userId",
  APP_ID: "appId",
  DATE_FORM: "dateFrom",
  DATE_TO: "dateTo",
  DATE: "date",
  START_DATE: "startDate",
  END_DATE: "endDate",
  START_TIME: "startTime",
  END_TIME: "endTime",
  // RES
  ROW_NUMBER: "rowNumber",
  FUNCTION_ID: "functionId",
  MESSAGE: "message",
  MESSAGE_TYPE: "messageType",

  MODULE_NAME: "moduleName",
  FUNCTION_NAME: "functionName",
};
