import { createColumnHelper } from "@tanstack/react-table";
import { COLUMN_NAME } from "../constants/table.constant";
import { API_SHIPMENT } from "shared/constants";

// const INDEX_OF_ALLOCATE_DATE = 7;
export default function useColGrouping(props) {
  const { rPkg } = props;
  const columnHelper = createColumnHelper();
  try {
    const column = [
      {
        header: COLUMN_NAME.NO,
        accessorKey: API_SHIPMENT.ROW_NUMBER,
        size: 100,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.FORECAST_MONTH,
        accessorKey: API_SHIPMENT.FORECAST_MONTH,
        size: 50,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.VANNING_MONTH,
        accessorKey: API_SHIPMENT.VANNING_MONTH,
        size: 50,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.PLANT_CODE,
        accessorKey: API_SHIPMENT.EXPORTER,
        size: 50,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.IMPORTER,
        accessorKey: API_SHIPMENT.IMPORTER,
        size: 30,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.SHIPMENT_CODE,
        accessorKey: API_SHIPMENT.SHIPMENT_CODE,
        size: 100,
        align: "center",
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.CARRIER,
        accessorKey: API_SHIPMENT.CARRIER,
        size: 100,
        align: "center",
        enableRowSpan: true,
      },
      columnHelper.group({
        header: COLUMN_NAME.VANNING_PLANT,
        footer: props => props.column.id,
        columns: [
          columnHelper.accessor(API_SHIPMENT.PLAN_VAN_FROM, {
            header: COLUMN_NAME.VANNING_FROM,
            align: "center",
            size: 50,
            enableRowSpan: true,
          }),
          {
            header: COLUMN_NAME.VANNING_TO,
            accessorKey: API_SHIPMENT.PLAN_VAN_TO,
            size: 50,
            align: "center",
            enableRowSpan: true,
          },
        ],
      }),
      {
        header: COLUMN_NAME.INV_NO,
        accessorKey: API_SHIPMENT.INVOICE_NO,
        size: 100,
        align: "center",
        ellipsis: true,
        enableRowSpan: true,
      },
      {
        header: COLUMN_NAME.PLAN_ACTUAL,
        accessorKey: API_SHIPMENT.PLAN_ACTL,
        size: 50,
        align: "center",
      },
      {
        header: COLUMN_NAME.ETD,
        accessorKey: API_SHIPMENT.ETD_DATE,
        size: 50,
        align: "center",
      },
      {
        header: COLUMN_NAME.ETA,
        accessorKey: API_SHIPMENT.ETA_DATE,
        size: 50,
        align: "center",
      },
      {
        header: COLUMN_NAME.SHIPMENT_SUMMARY_H_ID,
        accessorKey: API_SHIPMENT.SHIPMENT_SUMMARY_H_ID,
        hidden: true,
        key: API_SHIPMENT.SHIPMENT_SUMMARY_H_ID,
      },
    ];
    let newCol = [];
    newCol = rPkg.map(val => ({
      header: val,
      accessorKey: val,
      size: 100,
      align: "center",
    }));
    column.push(...newCol);
    return column;
  } catch (error) {
    console.error(error);
  }
}
