import React from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
//UTIL
import { responseErrors } from "utils";
import { BUTTON_VALUE } from "shared/constants";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { ModeAction } from "state/enum";
import { uniq } from "lodash";
import { simulateGraph } from "../hooks/format";
export default function ButtonGraph(props) {
  const {
    mode,
    rows,
    defaultGraphData,
    setGraph,
    setMode,
    onSearch,
    setOnSearch,
    setOpenGraph,
    graphData,
    rPkg,
    setOnSimulate,
    stateBtn: { simulateBtn, cancelBtn },
    setMsg: { setMsgAlert, setMsgError },
    // dataList: { rPackageOwnerList, rPackageTypeList, wareHouseList },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const handleSimulate = () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      const simulateData = simulateGraph(rowSelectionModel, graphData, rPkg);
      setGraph(simulateData);
      // setOnSimulate(true);
      // console.log("handleSimulate", rowSelectionModel, uniq(rowSelectionModel));
      // //tiger table mode edit
      // let tempRowModesModel = rowModesModel;

      // const rowSelect = rows?.map(val => {
      //   if (rowSelectionModel.includes(val?.rowNumber))
      //     tempRowModesModel = {
      //       ...tempRowModesModel,
      //       [val?.rowNumber]: { mode: GridRowModes.View },
      //     };
      // });

      // rowSelect && setRowModesModel(tempRowModesModel);
      setOpenGraph(true);
      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleCancel = async () => {
    try {
      //reset
      setMsgAlert([]);
      setMsgError([]);
      const allocateConfirm = await confirmDialogCtx.success({ type: "INVENTORY_CONFIRM_CANCEL" });
      if (!allocateConfirm) {
        return;
      }
      // let tempRowModesModel = rowModesModel;

      // rowSelectionModel.forEach(val => {
      //   tempRowModesModel = {
      //     ...tempRowModesModel,
      //     [val]: { mode: GridRowModes.View, ignoreModifications: true },
      //   };
      // });

      // setRowModesModel(tempRowModesModel);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      setGraph(defaultGraphData);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Box>
      <InputButton id="button-simulate" value={"Simulate"} disabled={simulateBtn} onClick={handleSimulate} />
      <InputButton id="button-cancel" value={BUTTON_VALUE.CANCEL} disabled={cancelBtn} onClick={handleCancel} />
    </Box>
  );
}
