import { useMutateData, useCustomQuery, useMutateDownload } from "shared/services/base.service";
import { saveAs } from "utils/download-file";
const BaseServicePath = process.env.REACT_APP_API_STANDARD;

const OnDemandBatchApiService = () => ({
  // GET Project code
  useOnDemandBatchProjectCodeQuery: () => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/on-demand-batch/project-code`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // GET Batch Id & Batch Name
  useOnDemandBatchBatchIdQuery: projectCode => {
    const data = useCustomQuery({
      url: `${BaseServicePath}/category/on-demand-batch/batch/${projectCode}`,
      method: "GET",
      enabled: true,
      select: data => data.result,
      keepPreviousData: true,
    });
    return data;
  },
  // POST SEARCH
  useOnDemandBatchSearchMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async searchData => {
      return await mutate({
        url: `${BaseServicePath}/on-demand-batch/search`,
        method: "POST",
        data: searchData,
      });
    };
  },
  //POST EXECUTE
  useOnDemandBatchExecuteMutation: () => {
    const { mutate } = useMutateData({
      invalidateKeys: [],
    });
    return async executeData => {
      return await mutate({
        url: `${BaseServicePath}/on-demand-batch/execute`,
        data: executeData,
        method: "POST",
      });
    };
  },

  //POST DOWNLOAD
  useOnDemandBatchDownloadMutation: () => {
    const { mutate } = useMutateDownload({
      invalidateKeys: [],
    });
    return async (body) => {
      return await mutate(
        {
          url: `${BaseServicePath}/on-demand-batch/download/excel`,
          method: "POST",
          data: body,
          responseType: "arraybuffer",
        },
        {
          onSuccess: response => {
            saveAs(response);
            // const blob = new Blob([data], {
            //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // });
            // (blob, `${fileName}.xlsx`);
          },
        }
      );
    };
  },
});

export const {
  useOnDemandBatchProjectCodeQuery,
  useOnDemandBatchBatchIdQuery,
  useOnDemandBatchSearchMutation,
  useOnDemandBatchExecuteMutation,
  useOnDemandBatchDownloadMutation,
} = OnDemandBatchApiService();
